import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import BasicTextField from '../../../components/BasicTextField';
import validationSchemas from '../../../common/validationSchemas';
import { Timestamp, auth, db } from '../../../firebase';
import { partnerAvatar } from '../../../mockups/avatars';
import Alert from '../../../components/BasicAlert';
import BasicButtonWithSpinner from '../../../components/BasicButtonWithSpinner';
import BasicModal from '../../../components/BasicModal';
import ChipInput from 'material-ui-chip-input';

const updatePartnerWithPWEndpoint =
  'https://us-central1-papillon-trade-app.cloudfunctions.net/stats/updatePartnerPw';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  form: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  title: {
    marginTop: theme.spacing(3),
    textTransform: 'uppercase'
  },
  signInButton: {
    margin: theme.spacing(1, 0)
  }
}));

const AddPartner = ({ handleClose, modalOpen, refresh, onSuccess, isEdit }) => {
  const classes = useStyles();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [isValidFields, setIsValidFields] = useState(false);
  const [emailArr, setOtherEmailArr] = useState([]);
  const [errorEmailchip, setErrorEmailChip] = useState(null);
  const {
    register,
    getValues,
    handleSubmit,
    errors,
    control,
    formState,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      partnerName: '',
      partnerUsername: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    mode: 'onChange',
    validationSchema: isEdit
      ? validationSchemas.partnerUpdateSchema
      : validationSchemas.partnerSchema
  });

  useEffect(() => {
    if (isEdit) {
      setTimeout(async () => {
        updateFields(isEdit);
        hasValidate();
      });
    } else {
      // reset();
      hasValidate();
    }
    return () => { };
  }, [isEdit]);
  useEffect(() => {
    hasValidate();
  }, [formState]);

  const updateFields = data => {
    setValue('partnerName', data.name, true);
    setValue('partnerUsername', data.username, true);
    setValue('email', data.email, true);
    setOtherEmailArr(data.otherEmails || []);
  };
  const onCreatePartner = (data, e) => {
    const partnerData = {
      name: data.partnerName.toUpperCase(),
      username: data.partnerUsername,
      email: data.email,
      avatar: partnerAvatar,
      roles: ['partner'],
      stores: [],
      workers: [],
      otherEmails: emailArr,
      created: Timestamp.fromDate(new Date())
    };
    setLoading(true);
    auth
      .createUserWithEmailAndPassword(data.email, data.password)
      .then(response => {
        db.collection('partners')
          .doc(response.user.uid)
          .set(partnerData)
          .then(() => {
            setLoading(false);
            setErrorMessage(null);
            e.target.reset();
            closeModalHandler();
            refresh();
            onSuccess();
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(error => {
        setLoading(false);
        setErrorMessage(error.message);
      });
  };

  const onUpdatePartner = async (data, e) => {
    const { partnerName, partnerUsername, password } = data;
    const { id } = isEdit;

    if (password.length === 0) {
      setLoading(true);
      const response = await db
        .collection('partners')
        .doc(id)
        .update({
          name: partnerName,
          username: partnerUsername,
          otherEmails: emailArr
        });
      setLoading(false);
      setErrorMessage(null);
      e.target.reset();
      closeModalHandler();
      refresh();
      onSuccess();
    } else {
      try {
        setLoading(true);
        const res = await (
          await axios.post(updatePartnerWithPWEndpoint, {
            id,
            name: partnerName,
            username: partnerUsername,
            password: password,
            otherEmails: emailArr
          })
        ).data;
        const { success } = res;
        if (success.includes('yes')) {
          console.log('pw updated');
        }
        setLoading(false);
        setErrorMessage(null);
        e.target.reset();
        closeModalHandler();
        refresh();

      } catch (e) {
        console.log('error', e);
      }
    }
  };
  const hasValidate = async () => {
    if (isEdit) {
      const values = getValues();
      const keys = ['partnerName', 'partnerUsername', 'email'];
      const ret = keys.every(key => values[key].length > 0);
      const check = true && !ret;
      if (values.password.length > 0) {
        const secondCheck =
          !check &&
          values.password === values.confirmPassword &&
          formState.isValids;

        setIsValidFields(!secondCheck);
        return;
      } else {
        setIsValidFields(check);
        return;
      }
    } else {
      const values = getValues();
      const keys = ['partnerName', 'partnerUsername', 'email'];
      const ret = Object.keys(values).every(key => values[key].length > 0);
      const check = ret && formState.isValid;
      setIsValidFields(!check);
      return;
    }
  };

  const handleAddEmailChip = email => {
    const emails = [...emailArr];
    emails.push(email);
    setOtherEmailArr(emails);
  };
  const handleDeleteEmailChip = (email, index) => {
    const emails = [...emailArr];
    const updated = emails.filter((e, i) => {
      const check = email === e && i == index;
      return !check;
    });
    setOtherEmailArr(updated);
  };

  const isValid = email => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setErrorEmailChip(error);
      return false;
    }

    return true;
  };

  const isInList = email => {
    return emailArr.includes(email);
  };

  const isEmail = email => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  const handlePaste = evt => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData('text');
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter(email => !isInList(email));

      setOtherEmailArr([...emailArr, ...toBeAdded]);
    }
  };

  const closeModalHandler = () => {
    handleClose();
    setOtherEmailArr([]);
  };

  return (
    <BasicModal
      title={t('addPartnerForm.title')}
      subtitle={t('addPartnerForm.subtitle')}
      onCloseModal={closeModalHandler}
      modalOpen={modalOpen}
      loading={loading}
      actions={[]}>
      <form
        className={classes.form}
        onSubmit={handleSubmit(isEdit ? onUpdatePartner : onCreatePartner)}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Controller
          control={control}
          name="partnerName"
          as={
            <BasicTextField
              label={t('addPartnerForm.name')}
              type="text"
              name="partnerName"
              error={errors.partnerName}
              // register={register}
              // control={control}
              required
            />
          }
        />
        {/* <BasicTextField
          label={t('addPartnerForm.name')}
          type="text"
          name="partnerName"
          error={errors.partnerName}
          register={register}
          control={control}
          required
        /> */}
        <Controller
          control={control}
          name="partnerUsername"
          as={
            <BasicTextField
              label={t('addPartnerForm.username')}
              type="text"
              name="partnerUsername"
              error={errors.partnerUsername}
              // register={register}
              required
            />
          }
        />
        {/* <BasicTextField
          label={t('addPartnerForm.username')}
          type="text"
          name="partnerUsername"
          error={errors.partnerUsername}
          register={register}
          required
        /> */}
        <Controller
          control={control}
          name="email"
          as={
            <BasicTextField
              label={t('addPartnerForm.email')}
              type="email"
              name="email"
              autoComplete="email"
              error={errors.email}
              // register={register}
              required
              disabled={isEdit ? true : false}
            />
          }
        />
        {/* <BasicTextField
          label={t('addPartnerForm.email')}
          type="email"
          name="email"
          autoComplete="email"
          error={errors.email}
          register={register}
          required
        /> */}
        <Controller
          control={control}
          name="password"
          as={
            <BasicTextField
              label={t('addPartnerForm.password')}
              type="password"
              name="password"
              error={errors.password}
              // register={register}
              required={!isEdit ? true : false}
            />
          }
        />
        {/* <BasicTextField
          label={t('addPartnerForm.password')}
          type="password"
          name="password"
          error={errors.password}
          register={register}
          required={false}
        /> */}
        <Controller
          control={control}
          name="confirmPassword"
          as={
            <BasicTextField
              label={t('addPartnerForm.cPassword')}
              type="password"
              name="confirmPassword"
              error={errors.confirmPassword}
              // register={register}
              required={!isEdit ? true : false}
            />
          }
        />
        <ChipInput
          value={emailArr}
          onBeforeAdd={e => e && isValid(e)}
          onAdd={handleAddEmailChip}
          onDelete={handleDeleteEmailChip}
          onPaste={handlePaste}
          onInput={() => setErrorEmailChip(null)}
          fullWidth
          fullWidthInput
          label="Other Emails"
          variant="outlined"
          autoComplete="off"
          margin="dense"
          style={{ marginTop: '14px', marginBottom: '12px' }}
          error={!!errorEmailchip}
          helperText={!!errorEmailchip && errorEmailchip}
        />
        {/* <BasicTextField
          label={t('addPartnerForm.cPassword')}
          type="password"
          name="confirmPassword"
          error={errors.confirmPassword}
          register={register}
          required={false}
        /> */}
        <BasicButtonWithSpinner
          label={isEdit ? t('button.update') : t('button.addPartner')}
          disabled={isValidFields}
          isLoading={loading}
        />
      </form>
    </BasicModal>
  );
};

AddPartner.propTypes = {
  history: PropTypes.object
};

export default withRouter(AddPartner);
