import React from 'react';
import {Card, CardContent, Checkbox, Badge, CircularProgress} from '@material-ui/core';
import styled from 'styled-components';
import CommentIcon from '@material-ui/icons/Comment';

const CardsWrapper = styled.div`
    display:  flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    font-family: Roboto, Helvetica, Arial, sans-serif;
`;
const SubmissionCard = styled(Card)`
    display:  flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 8px;
    font-family: inherit;
    `;

const Sections = styled(CardContent)`
    display:  flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    font-family: inherit;
    &.MuiCardContent-root:last-child {
        padding: 8px;
    };
`;

const Section = styled.div`
    display:  flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 11px;
`;

const SectionCheck = styled(Section)`

`;

const Section1 = styled(Section)`
align-items: flex-start;
`;

const Section2 = styled(Section)`
align-items: flex-start;
`;

const SectionComments = styled(Section)`

`;

const Reference = styled.div`
cursor: pointer;
color: #039be5;
text-decoration: underline;
font-weight: bold;
padding-bottom: 3px;
`;

const Worker = styled.div`
margin-top: 5px;
padding-bottom: 3px;
`;

const StoreNumber = styled.div`
padding-bottom: 3px;
font-weight: bold;
`;

const City = styled.div`
margin-top: 5px;
font-weight: bold;
padding-bottom: 3px;
`;

const Cards = ({submissions, loading, handleSelectOne, selectedItems, onCardClick}) => {
    return (
        <CardsWrapper>
            { loading &&
                <CircularProgress />
            }
            {! loading && submissions.map(
                ({reference, date, storeNumber, storeName,
                worker, partner, city, street, id, comments}) => {
                return (
                    <SubmissionCard key={id}>
                        <Sections>
                            <SectionCheck>
                            <Checkbox
                                checked={selectedItems.indexOf(id) !== -1}
                                color="primary"
                                onChange={event => handleSelectOne(event, id)}
                                value="true"
                            />
                            </SectionCheck>
                            <Section1 onClick={() => onCardClick({reference, date, storeNumber, storeName,
                worker, partner, city, street, id, comments})}>
                                <Reference>{reference}</Reference>
                                <div>{date}</div>
                                <Worker>{worker}</Worker>
                                <b>{partner}</b>
                            </Section1>
                            <Section2 onClick={() => onCardClick({reference, date, storeNumber, storeName,
                worker, partner, city, street, id, comments})}>
                            <StoreNumber>{storeNumber}</StoreNumber>
                                <div>{storeName}</div>
                                <City>{city}</City>
                                <div>{street}</div>
                            </Section2>
                            <SectionComments>

                            <Badge
                             badgeContent={comments}
                             anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              showZero
                            color="primary">
                                <CommentIcon />
                            </Badge>
                            </SectionComments>
                        </Sections>
                    </SubmissionCard>
                )
            })}
        </CardsWrapper>
    );
};

export default Cards;