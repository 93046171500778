import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PerfectScrollbar from 'react-perfect-scrollbar';

import BasicButtonWithSpinner from '../BasicButtonWithSpinner';
import randomNumber from '../../helpers/randomNumber';

const useStyles = makeStyles(theme => ({
  modal: {},
  dialogContent: {
    width: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  title: {
    textTransform: 'uppercase',
    padding: theme.spacing(2, 2, 0, 2),
    position: 'relative'
  },
  subtitle: {
    textAlign: 'left',
    padding: theme.spacing(1, 2, 2, 0),
    width: '100%'
  },
  confirm: {
    width: 'fit-content',
    backgroundColor: theme.palette.primary.dark
  },
  cancel: {
    width: 'fit-content',
    backgroundColor: theme.palette.error.light
  },
  close: {
    position: 'absolute',
    right: 8,
    top: 8,
    '&:hover': {
      color: theme.palette.error.main,
      cursor: 'pointer'
    }
  }
}));

const BasicModal = ({
  title,
  subtitle,
  onCloseModal,
  modalOpen,
  actions,
  children
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={modalOpen}
      onClose={onCloseModal}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.title}>
        <Box component="span">{title}</Box>
        <Box component="span" className={classes.close} onClick={onCloseModal}>
          <CloseIcon size={26} />
        </Box>
      </DialogTitle>
      <PerfectScrollbar>
        <DialogContent className={classes.dialogContent}>
          {subtitle && (
            <DialogContentText className={classes.subtitle}>
              {subtitle}
            </DialogContentText>
          )}
          {children}
        </DialogContent>
      </PerfectScrollbar>
      <DialogActions>
        {actions.map(action => (
          <BasicButtonWithSpinner
            key={randomNumber()}
            onClick={action.handler}
            disabled={action.disabled}
            label={action.label}
            isLoading={action.loading}
            styles={
              action.label === 'Cancel' ? classes.cancel : classes.confirm
            }
          />
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default BasicModal;
