import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    formElement: {
        marginTop: theme.spacing(2)
    },
    item: {
        textTransform: 'capitalize'
    }
}));

const BasicSelect = ({
    label,
    name,
    inputRef,
    value,
    setValue,
    options
}) => {

    const classes = useStyles();
    const items = options.map(option => <MenuItem value={option} key={option} className={classes.item}>{option}</MenuItem>)

    return <FormControl
        className={classes.formElement}
        variant="outlined"
        margin="dense"
        fullWidth>
        <InputLabel ref={inputRef} id="label">
            {label}
        </InputLabel>
        <Select
            labelId="label"
            name={name}
            value={value}
            onChange={e => setValue(e.target.value)}
            labelWidth={80}>
            <MenuItem value="">
                <em>Choose {label}</em>
            </MenuItem>
            {items}
        </Select>
    </FormControl>

};

export default BasicSelect;
