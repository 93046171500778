import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PartnersToolbar from './components/PartnersToolbar';
import { db } from '../../../firebase';
import BasicTable from '../../../components/BasicTable';
import { deleteDocuments } from '../../../common/firebaseActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const PartnersList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const tableHeaders = [
    t('tableHeaders.name'),
    t('tableHeaders.username'),
    t('tableHeaders.email'),
    t('tableHeaders.otherEmails')
  ];

  const [partners, setPartners] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const userRole = useSelector(state => state.auth.user.roles[0]);

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = async () => {
    try {
      setLoading(true);
      const fetchedPartners = await (
        await db.collection('partners').get()
      ).docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        username: doc.data().username,
        avatar: doc.data().avatar,
        email: doc.data().email || '',
        otherEmails: doc.data().otherEmails?.join(', ') || ''
      }));
      setPartners(fetchedPartners);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const deletePartnersHandler = () => {
    return deleteDocuments('partners', selectedPartners);
  };

  const updatePartners = () => {
    setSelectedPartners([]);
    fetchPartners();
  };
  const handleRowClick = async data => {
    if (userRole === 'admin') {

      const { otherEmails } = data;
      setEdit({
        ...data,
        otherEmails: otherEmails.length === 0 ? [] : otherEmails.split(', ')
      });
    }
  };
  return (
    <div className={classes.root}>
      <PartnersToolbar
        refresh={updatePartners}
        selectedPartners={selectedPartners}
        deletePartners={deletePartnersHandler}
        editPartner={isEdit}
        setEdit={setEdit}
      />
      <div className={classes.content}>
        <BasicTable
          headers={tableHeaders}
          data={partners}
          loading={loading}
          selectedItems={selectedPartners}
          setSelectedItems={setSelectedPartners}
          withPagination
          withAvatar
          small
          onRowClick={handleRowClick}
        />
      </div>
    </div>
  );
};

export default PartnersList;
