/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

const days = [
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 }
];

const PlanRows = ({ plan, removeItem }) => {
  return (
    <>
      {plan.map(item => (
        <div className="plan-item" key={Math.random()}>
          <span className="day">{days[item.day - 1].label}</span>
          <span className="store">{item.store.name}</span>
          <span className="repeat">Week{item.repeat.join(',Week')}</span>
          <IconButton onClick={() => removeItem(item)}>
            <DeleteForeverIcon />
          </IconButton>
        </div>
      ))}
    </>
  );
};

export default function CustomizedDialogs({
  stores,
  createPlan,
  open,
  handleClose
}) {
  const [plan, setPlan] = React.useState([]);

  const [day, setDay] = React.useState('');
  const [store, setStore] = React.useState(null);
  const [repeat, setRepeat] = React.useState([]);

  const addPlanRow = () => {
    if (!day || !store || !repeat.length) return;
    const newRow = { day, store: store, repeat, id: uuidv4() };

    setPlan(plan.concat([newRow]));

    // reset form
    setDay('');
    setStore(null);
    setRepeat([]);
  };

  const removeItem = item => {
    const updatedPlan = plan.filter(p => p.id !== item.id);
    setPlan(updatedPlan);
  };

  const createPlanHandler = () => {
    if (plan.length !== 0) {
      createPlan(plan);
      handleClose();
    }
  };

  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        onClose={handleClose}
        open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Create route plan
        </DialogTitle>
        <DialogContent dividers style={{ minHeight: '500px' }}>
          <form>
            <div className="plan-row">
              <FormControl className="day-input" variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Day
                </InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  label="Day"
                  labelId="demo-simple-select-outlined-label"
                  onChange={ev => setDay(ev.target.value)}
                  value={day}>
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {days.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Autocomplete
                getOptionLabel={option =>
                  `${option.number}-${option.name}-${option.city}-${option.street}`
                }
                id="combo-box-demo"
                onChange={(ev, value) => setStore(value)}
                options={stores}
                renderInput={params => (
                  <TextField {...params} label="Store" variant="outlined" />
                )}
                style={{ width: 300 }}
                value={store}
              />
              <ToggleButtonGroup
                aria-label="text formatting"
                color="secondary"
                onChange={(ev, value) => setRepeat(value)}
                value={repeat}>
                <ToggleButton value={1}>Week 1</ToggleButton>
                <ToggleButton value={2}>Week 2</ToggleButton>
                <ToggleButton value={3}>Week 3</ToggleButton>
                <ToggleButton value={4}>Week 4</ToggleButton>
              </ToggleButtonGroup>
              <Button color="primary" onClick={addPlanRow} variant="outlined">
                Add
              </Button>
            </div>
          </form>

          <PlanRows plan={plan} removeItem={removeItem} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={createPlanHandler}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
