import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  grid: {
    height: '100%',
    padding: theme.spacing(2)
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const GridLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={8} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>{children}</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default GridLayout;
