export const saveRoutes = async (
  rawData,
  db,
  setLoading,
  showSuccessMessage
) => {
  let batch = db.batch();
  const schedules = {};

  rawData.forEach(item => {
    const [storeId, workerId, day, order, ...repeat] = item;
    const createdAt = new Date().toISOString();

    const dayPlan = { storeId, day, order, repeat };

    if (!schedules[workerId]) {
      schedules[workerId] = { workerId, plan: [dayPlan], createdAt };
    } else {
      schedules[workerId].plan = [...schedules[workerId].plan, dayPlan];
    }
  });

  const formattedData = Object.values(schedules);

  formattedData.forEach(data => {
    const docRef = db.collection('schedules').doc();
    batch.set(docRef, data);
  });

  await batch.commit();
  setLoading(false);
  showSuccessMessage();
};
