/* eslint-disable react/prop-types */
import React from 'react'
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';

export default function Carousel({images, title, handleClose}){
  const lightBoxImages = images.map(({src}) =>({url: src, title }))
  return <Lightbox
    doubleClickZoom={2}
    images={lightBoxImages}
    onClose={handleClose}
  />
}