/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import BasicModal from '../../../components/BasicModal';
import BasicTextField from '../../../components/BasicTextField';
import BasicButtonWithSpinner from '../../../components/BasicButtonWithSpinner';
import Alert from '../../../components/BasicAlert';
import validationSchemas from '../../../common/validationSchemas';
import AutoCompleteInput from '../../../components/AutoCompleteInput';
import BasicSwitch from '../../../components/BasicSwitch';
import Table from './components/Table';

import { workerAvatar } from '../../../mockups/avatars';
import { db, auth, Timestamp } from '../../../firebase';

const updateWorkerWithEndpoint =
  'https://us-central1-papillon-trade-app.cloudfunctions.net/stats/updateWorkerPw';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  form: {
    padding: theme.spacing(0, 2),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  title: {
    marginTop: theme.spacing(3),
    textTransform: 'uppercase'
  },
  table: {
    width: 550
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  }
}));

const AddWorker = ({ onSuccess, handleClose, modalOpen, isEdit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isPapillon, setIsPapillon] = useState(false);
  const [partner, setPartner] = useState(null);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [partnersList, setPartnersList] = useState([]);
  const [isValidFields, setIsValidFields] = useState(false);

  const [isPikrijeka, setIsPikrijeka] = useState(false);
  const [pikrijekaDepartmments, setPikrijekaDepartmments] = useState([]);

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    errors,
    formState
  } = useForm({
    defaultValues: {
      workerName: '',
      workerUsername: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    mode: 'onChange',
    validationSchema: isEdit
      ? validationSchemas.workerUpdateSchema
      : validationSchemas.workerSchema
  });

  useEffect(() => {
    db.collection('partners')
      .get()
      .then(function (querySnapshot) {
        const partners = querySnapshot.docs.map(doc => ({
          id: doc.id,
          label: doc.data().name,
          email: doc.data().email,
          isPikrijeka: doc.data().isPikrijeka || false
        }));

        // filter pikrijeka partners/departments
        const pikrijekaDeps = partners.filter(partner => {
          return partner.label.toLowerCase().includes('pikrijeka-')
        })

        setPikrijekaDepartmments(pikrijekaDeps)
        setPartnersList(partners);
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
  }, []);

  useEffect(() => {
    if (isEdit) {
      setTimeout(async () => {
        const getData = await (
          await db.collection('workers').doc(isEdit.id).get()
        ).data();
        updateFields(isEdit, getData);
        hasValidate();
      });
    } else {
      hasValidate();
    }
  }, [isEdit]);

  useEffect(() => {
    hasValidate();
  }, [formState]);

  const updateFields = (data, getData) => {
    if (getData.isPapillon) {
      setIsPapillon(getData.isPapillon);
      setSelectedPartners(getData.partnerInfo);
    } else {
      setPartner(getData.partnerInfo);
    }
    setValue('workerName', data.name, true);
    setValue('workerUsername', data.username, true);
    setValue('email', data.email, true);
  };

  const onPartnerSelected = async (event, partner) => {
    if (partner) setPartner(partner);
  };

  const partnerSelectedHandler = (e, selectedItems) => {
    if (selectedItems) setSelectedPartners(selectedItems);
  };

  const switchModeHandler = () => {
    setIsPapillon(!isPapillon);
    setSelectedPartners([]);
  };

  const isPikrijekaHandler = () => {
    setIsPikrijeka(!isPikrijeka);
    setSelectedPartners([]);
  };

  const onCreateWorker = (data, e) => {
    const partnerInfo = (isPapillon || isPikrijeka)
      ? selectedPartners
      : { id: partner.id, name: partner.label, email: partner.email };
    const workerData = {
      created: Timestamp.fromDate(new Date()),
      name: data.workerName.toUpperCase(),
      username: data.workerUsername,
      avatar: workerAvatar,
      email: data.email,
      roles: ['worker'],
      partnerInfo,
      isPapillon,
      isPikrijeka
    };
    setLoading(true);
    auth
      .createUserWithEmailAndPassword(data.email, data.password)
      .then(response => {
        setErrorMessage(null);
        db.collection('workers')
          .doc(response.user.uid)
          .set(workerData)
          .then(() => {
            setLoading(false);
            e.target.reset();
            onSuccess();
            setIsPapillon(false);
            setSelectedPartners([]);
            setPartner(null);
          })
          .catch(err => {
            console.log(err);
            setLoading(false);
            setErrorMessage(t('notifications.servererror'));
          });
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
        setErrorMessage(error.message);
      });
  };
  const onUpdateWorker = async (data, e) => {
    const { password } = data;
    const { id } = isEdit;
    const partnerInfo = isPapillon
      ? selectedPartners
      : {
        id: partner.id,
        name: partner.name || partner.label,
        email: partner.email
      };
    const workerData = {
      name: data.workerName.toUpperCase(),
      username: data.workerUsername,
      partnerInfo,
      isPapillon
    };
    try {
      if (password.length === 0) {
        setLoading(true);
        await db
          .collection('workers')
          .doc(id)
          .update(workerData);
        setLoading(false);
        setErrorMessage(null);
        e.target.reset();
        handleClose();
        setIsPapillon(false);
        setSelectedPartners([]);
        setPartner(null);
        onSuccess();
      } else {
        setLoading(true);
        await (
          await axios.post(updateWorkerWithEndpoint, {
            id,
            password,
            workerData
          })
        ).data;
        setLoading(false);
        setErrorMessage(null);
        e.target.reset();
        setIsPapillon(false);
        setSelectedPartners([]);
        setPartner(null);
        handleClose();
        onSuccess();
      }
    } catch (e) {
      console.log('error', e);
    }
  };
  const reducePartnerSuggestions = () => {
    if (isPikrijeka) {
      const selectedPartnersId = selectedPartners.map(obj => obj.id);
      return pikrijekaDepartmments.filter(obj => !selectedPartnersId.includes(obj.id));
    }
    const selectedPartnersId = selectedPartners.map(obj => obj.id);
    return partnersList.filter(obj => !selectedPartnersId.includes(obj.id));
  };

  const hasValidate = async () => {
    const values = getValues();
    if (isEdit) {
      const keys = ['workerName', 'workerUsername', 'email'];
      const ret = keys.every(key => values[key].length > 0);
      const checkSelectedPartner = (isPapillon || isPikrijeka)
        ? selectedPartners.length > 0
        : partner && true;
      const check = true && ret && checkSelectedPartner;
      if (values.password.length > 0) {
        const secondCheck =
          check &&
          values.password === values.confirmPassword &&
          formState.isValids;
        setIsValidFields(!secondCheck);
        return;
      } else {
        setIsValidFields(!check);
        return;
      }
    } else {
      const values = getValues();
      // const keys = ['workerName', 'workerUsername', 'email'];
      const ret = Object.keys(values).every(key => values[key].length > 0);
      const check =
        ret &&
        formState.isValid &&
        ((isPapillon || isPikrijeka) ? selectedPartners.length > 0 : partner && true);
      setIsValidFields(!check);
      return;
    }
  };

  return (
    <BasicModal
      actions={[]}
      modalOpen={modalOpen}
      onCloseModal={() => {
        handleClose();
        setIsPapillon(false);
        setSelectedPartners([]);
        setPartner(null);
      }}
      subtitle={t('addWorkerForm.subtitle')}
      title={t('addWorkerForm.title')}
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit(isEdit ? onUpdateWorker : onCreateWorker)}
      >
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {!isPikrijeka && < BasicSwitch
          handleCheck={switchModeHandler}
          isChecked={isPapillon}
          label={t('addWorkerForm.isPapillon')}
        />}
        {!isPapillon && < BasicSwitch
          handleCheck={isPikrijekaHandler}
          isChecked={isPikrijeka}
          label={t('addWorkerForm.isPikrijeka')}
        />}
        <Controller
          as={
            <BasicTextField
              error={errors.workerName}
              label={t('addWorkerForm.name')}
              name="workerName"
              type="text"
            />
          }
          control={control}
          name="workerName"
        />
        <Controller
          as={
            <BasicTextField
              error={errors.workerUsername}
              label={t('addWorkerForm.username')}
              name="workerUsername"
              type="text"
            />
          }
          control={control}
          name="workerUsername"
        />
        {!isPapillon && !isPikrijeka && (
          <AutoCompleteInput
            label={t('addWorkerForm.partner')}
            margin="dense"
            name="partner"
            onOptionSelected={onPartnerSelected}
            suggestions={partnersList}
            value={partner}
          />
        )}
        {(isPapillon || isPikrijeka) && (
          <AutoCompleteInput
            isMultiple
            label={t('addWorkerForm.partners')}
            margin="dense"
            name="partner"
            onOptionSelected={partnerSelectedHandler}
            selectedSuggestions={selectedPartners}
            suggestions={reducePartnerSuggestions()}
            value={selectedPartners}
          />
        )}
        <PerfectScrollbar className={classes.table}>
          <Table data={selectedPartners} />
        </PerfectScrollbar>
        <Controller
          as={
            <BasicTextField
              autoComplete="email"
              disabled={isEdit ? true : false}
              error={errors.email}
              label={t('addWorkerForm.email')}
              name="email"
              // register={register}
              required
              type="email"
            />
          }
          control={control}
          name="email"
        />
        <Controller
          as={
            <BasicTextField
              error={errors.password}
              label={t('addWorkerForm.password')}
              name="password"
              required={!isEdit ? true : false}
              // register={register}
              type="password"
            />
          }
          control={control}
          name="password"
        />
        <Controller
          as={
            <BasicTextField
              error={errors.confirmPassword}
              label={t('addWorkerForm.cPassword')}
              name="confirmPassword"
              required={!isEdit ? true : false}
              // register={register}
              type="password"
            />
          }
          control={control}
          name="confirmPassword"
        />
        <div className={classes.buttonWrapper}>
          <BasicButtonWithSpinner
            disabled={isValidFields || loading}
            isLoading={loading}
            label={isEdit ? t('button.update') : t('button.addWorker')}
          />
        </div>
      </form>
    </BasicModal>
  );
};

AddWorker.propTypes = {
  history: PropTypes.object
};

export default withRouter(AddWorker);
