/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import BasicTextField from '../../../components/BasicTextField';
import validationSchemas from '../../../common/validationSchemas';
import BasicSelect from 'components/BasicSelect';
import Alert from 'components/BasicAlert';
import BasicButtonWithSpinner from 'components/BasicButtonWithSpinner';
import BasicModal from '../../../components/BasicModal';
import AutoCompleteInput from '../../../components/AutoCompleteInput';
import { db, Timestamp } from '../../../firebase';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  form: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  submitButton: {
    margin: theme.spacing(2, 0)
  }
}));

const AddProduct = ({ handleClose, modalOpen, onSuccess, refresh, isEdit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const inputLabel = useRef(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [unit, setUnit] = useState('');
  const [units] = useState(['PAK', 'KOM', 'LIT', 'KG', 'M', 'Other']);
  const userInfo = useSelector(state => ({
    ...state.auth.user,
    userRole: state.auth.user.roles[0]
  }));
  const userId = useSelector(state => state.auth.userId);
  const [partner, setPartner] = useState(null);
  const [partnersList, setPartnersList] = useState([]);

  const [isValidFields, setIsValidFields] = useState(false);
  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    errors,
    formState
  } = useForm({
    defaultValues: {
      productName: '',
      productId: '',
      productBarcode: '',
      package: ''
    },
    mode: 'onChange',
    validationSchema: validationSchemas.productSchema
  });

  useEffect(() => {
    if (userInfo.userRole === 'partner') {
      setPartner({ id: userId, name: userInfo.name });
    } else if (userInfo.userRole === 'admin') {
      db.collection('partners')
        .get()
        .then(function (querySnapshot) {
          const partners = querySnapshot.docs.map(doc => ({
            id: doc.id,
            label: doc.data().name
          }));
          setPartnersList(partners);
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
        });
    }
  }, []);
  useEffect(() => {
    if (isEdit) {
      setTimeout(async () => {
        const { id } = isEdit;
        const getData = await (
          await db
            .collection('products')
            .doc(isEdit.id)
            .get()
        ).data();

        const customData = {
          id,
          productName: getData.name,
          productId: getData.productId,
          productBarcode: getData.barcode,
          package: getData.package,
          unit: getData.unit,
          partnerInfo: getData.partnerInfo
        };
        updateFields(customData);
        hasValidate();
      });
    } else {
      // reset();
      hasValidate();
    }
    // return () => {};
  }, [isEdit]);
  useEffect(() => {
    hasValidate();
  }, [formState]);
  const onPartnerSelected = async (event, partner) => {
    if (partner) setPartner({ id: partner.id, name: partner.label });
  };

  const updateFields = getData => {
    if (userInfo.userRole === 'admin') {
      setPartner(getData.partnerInfo);
    }
    setUnit(getData.unit);
    setValue('productName', getData.productName, true);
    setValue('productId', getData.productId, true);
    setValue('productBarcode', getData.productBarcode, true);
    setValue('package', getData.package, true);
  };
  const onCreateProduct = (data, e) => {
    const productData = {
      name: data.productName,
      productId: data.productId,
      barcode: data.productBarcode,
      package: data.package,
      unit: unit,
      partnerInfo: { id: partner.id, name: partner.name },
      created: Timestamp.fromDate(new Date())
    };
    setLoading(true);
    db.collection('products')
      .add(productData)
      .then(() => {
        setLoading(false);
        setErrorMessage(null);
        e.target.reset();
        handleClose();
        refresh();
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onUpdateProduct = (data, e) => {
    const { id } = isEdit;
    const productData = {
      name: data.productName,
      productId: data.productId,
      barcode: data.productBarcode,
      package: data.package,
      unit: unit,
      partnerInfo: { id: partner.id, name: partner.name }
    };
    setLoading(true);
    db.collection('products')
      .doc(id)
      .update(productData)
      .then(() => {
        setLoading(false);
        setErrorMessage(null);
        e.target.reset();
        handleClose();
        refresh();
        onSuccess();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const hasValidate = async () => {
    const values = getValues();
    const keys = ['productName', 'productId', 'productBarcode', 'package'];
    const fieldsCheck =
      keys.every(key => values[key].length > 0) && unit.length > 0;
    let checkSelectedFields = true;
    if (userInfo.userRole === 'admin') {
      checkSelectedFields = partner;
    }
    const check = true && fieldsCheck && checkSelectedFields;
    setIsValidFields(!check);
    return;
  };
  return (
    <BasicModal
      actions={[]}
      modalOpen={modalOpen}
      onCloseModal={() => {
        handleClose();
        setUnit('');
        setPartner(null);
      }}
      subtitle={t('addProductForm.subtitle')}
      title={t('addProductForm.title')}
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit(isEdit ? onUpdateProduct : onCreateProduct)}
      >
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {userInfo.userRole === 'admin' && (
          <AutoCompleteInput
            label={t('addProductForm.partner')}
            margin="dense"
            name="partner"
            onOptionSelected={onPartnerSelected}
            suggestions={partnersList}
            value={partner}
          />
        )}
        <Controller
          as={
            <BasicTextField
              error={errors.productName}
              label={t('addProductForm.name')}
              name="productName"
              register={register}
              required
              type="text"
            />
          }
          control={control}
          name="productName"
        />
        <Controller
          as={
            <BasicTextField
              error={errors.productId}
              label={t('addProductForm.id')}
              name="productId"
              register={register}
              required
              type="text"
            />
          }
          control={control}
          name="productId"
        />
        <Controller
          as={
            <BasicTextField
              error={errors.productBarcode}
              label={t('addProductForm.barcode')}
              name="productBarcode"
              register={register}
              required
              type="text"
            />
          }
          control={control}
          name="productBarcode"
        />
        <Controller
          as={
            <BasicTextField
              error={errors.package}
              label={t('addProductForm.package')}
              name="package"
              placeholder="24x..."
              register={register}
              required
              type="text"
            />
          }
          control={control}
          name="package"
        />
        <BasicSelect
          inputRef={inputLabel}
          label={t('addProductForm.unit')}
          name="unit"
          options={units}
          setValue={setUnit}
          value={unit}
        />
        <BasicButtonWithSpinner
          disabled={!formState.isValid}
          disabled={isValidFields || loading}
          isLoading={loading}
          label={isEdit ? t('button.update') : t('button.addProduct')}
        />
      </form>
    </BasicModal>
  );
};

AddProduct.propTypes = {
  history: PropTypes.object
};

export default withRouter(AddProduct);
