import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import clsx from 'clsx'
import {Controller} from "react-hook-form"
const useStyles = makeStyles(theme => ({
    formElement: {
        marginTop: theme.spacing(2)
    }
}));

const BasicTextField = ({
    label,
    type,
    name,
    error,
    register,
    className,
    ...props
}) => {

    const classes = useStyles();

    return <TextField
        {...props}
        className={clsx(classes.formElement, className)}
        label={label}
        type={type}
        name={name}
        variant="outlined"
        margin="dense"
        fullWidth
        error={!!error}
        inputRef={register}
        helperText={error && error.message}
        autoComplete="off"
    />

};

export default BasicTextField;
