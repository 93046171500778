/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack'

import AddPartner from '../../AddPartner';
import BasicFab from '../../../../components/BasicFab';
import BasicDialog from '../../../../components/BasicDialog';

const useStyles = makeStyles(theme => ({
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const PartnersToolbar = props => {
  const {
    className,
    refresh,
    selectedPartners,
    deletePartners,
    setEdit,
    editPartner,
    ...rest
  } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const hasSelectedItems = selectedPartners.length !== 0;

  useEffect(() => {
    if (editPartner) {
      setOpen(true);
    }
  }, [editPartner]);


  const deletePartnersHandler = () => {
    deletePartners().then(() => {
      refresh();
      setDeleteDialogOpen(false);
      enqueueSnackbar('Partners deleted successfully', {variant: 'success'})
    });
  };
  const closeAddPartnerModal = () => {
    setOpen(false);
    setEdit(false);
  };

  return (
    <div
      {...rest}
      className={clsx(className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        {hasSelectedItems && (
          <BasicFab
            handleClick={() => setDeleteDialogOpen(true)}
            label="Delete Items"
            remove
          />
        )}
        <BasicFab
          handleClick={() => setOpen(true)}
          label="Add Partner"
        />
        <AddPartner
          handleClose={closeAddPartnerModal}
          isEdit={editPartner}
          modalOpen={open}
          onSuccess={() =>
            enqueueSnackbar('Partner added successfully', {variant: 'success'})
          }
          refresh={refresh}
        />
        <BasicDialog
          count={selectedPartners.length}
          handleClose={() => setDeleteDialogOpen(false)}
          handleConfirm={() => deletePartnersHandler()}
          open={deleteDialogOpen}
          title="Delete partners"
        />
      </div>
    </div>
  );
};

PartnersToolbar.propTypes = {
  className: PropTypes.string
};

export default PartnersToolbar;
