/* eslint-disable react/prop-types */
import React, { Component, Suspense } from 'react';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';

import './i18n';
import { chartjs } from './helpers';
import theme from './theme';
import Routes from './Routes';
import * as actions from './store/actions';
import ErrorCatcher from './hoc/ErrorCatcher';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import './App.css';
export const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

class App extends Component {
  componentDidMount() {
    this.props.onAutoSignin();
  }

  render() {
    return (
      <ErrorCatcher>
        <ThemeProvider theme={theme}>
          <SCThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider maxSnack={5} preventDuplicate>
                <Suspense fallback={null}>
                  <Router history={browserHistory}>
                    <Routes history={browserHistory} />
                  </Router>
                </Suspense>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </SCThemeProvider>
        </ThemeProvider>
      </ErrorCatcher>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return { onAutoSignin: () => dispatch(actions.authCheckState()) };
};

export default connect(null, mapDispatchToProps)(App);
