import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Divider, Drawer, makeStyles } from '@material-ui/core';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ReorderIcon from '@material-ui/icons/Reorder';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import InfoIcon from '@material-ui/icons/Info';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import { Profile, SidebarNav } from './components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const isAuth = useSelector(state => state.auth.token !== null);
  const userRole = useSelector(state =>
    isAuth ? state.auth.user.roles[0] : null
  );
  const username = useSelector(state =>
    isAuth ? state.auth.user.username : null
  );

  const pages = [
    /*     {
      id: 1,
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    }, */
    {
      id: 2,
      title: t('menu.addSubmissions'),
      href: '/add-submission',
      icon: <PostAddIcon />
    },
    {
      id: 3,
      title: t('menu.submissions'),
      href: '/submissions',
      icon: <ReorderIcon />
    },
    {
      id: 11,
      title: t('menu.addReturn'),
      href: '/add-return',
      icon: <RestorePageIcon />
    },
    {
      id: 10,
      title: t('menu.returns'),
      href: '/returns',
      icon: <AssignmentReturnIcon />
    },
    {
      id: 4,
      title: t('menu.partners'),
      href: '/partners',
      icon: <GroupIcon />
    },
    {
      id: 5,
      title: t('menu.workers'),
      href: '/workers',
      icon: <PersonIcon />
    },
    {
      id: 6,
      title: t('menu.stores'),
      href: '/stores',
      icon: <StorefrontIcon />
    },
    {
      id: 7,
      title: t('menu.products'),
      href: '/products',
      icon: <ShoppingBasketIcon />
    },
    {
      id: 8,
      title: t('menu.questions'),
      href: '/questions',
      icon: <HelpIcon />
    },
    {
      id: 15,
      title: t('menu.assortments'),
      href: '/assortments',
      icon: <InfoIcon />
    },
    {
      id: 14,
      title: t('menu.emails'),
      href: '/emails',
      icon: <AllInboxIcon />
    },
    {
      id: 16,
      title: t('menu.routePlanner'),
      href: '/route-planner',
      icon: <EventAvailableIcon />
    },
    {
      id: 9,
      title: t('menu.logout'),
      href: '/logout',
      icon: <ExitToAppIcon />
    }
  ];

  let filteredPages = null;

  if (userRole === 'admin') {
    filteredPages = pages.filter(page => {
      return page.id !== 2 && page.id !== 11;
    });
  } else if (userRole === 'partner' && username === 'pikrijeka') {
    filteredPages = pages.filter(page => {
      return page.id === 3 || page.id === 5 || page.id === 9;
    });
  } else if (userRole === 'partner') {
    filteredPages = pages.filter(page => {
      return page.id !== 2 && page.id !== 11 && page.id !== 4 && page.id !== 14;
    });
  } else if (userRole === 'worker') {
    filteredPages = pages.filter(page => {
      return (
        page.id !== 4 &&
        page.id !== 5 &&
        page.id !== 8 &&
        page.id !== 7 &&
        page.id !== 14 &&
        page.id !== 15
      );
    });
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          handleClose={onClose}
          pages={filteredPages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
