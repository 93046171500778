/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Grid, Card, Button, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack'

import BasicTable from '../../../components/BasicTable';
import FilterSection from '../../../components/FilterSection';
import BasicDialog from '../../../components/BasicDialog';
import BasicFab from '../../../components/BasicFab';

import ExportCSVButton from '../../../helpers/ExportTableExcel';
import { formattedDateWithMoment } from '../../../helpers/date';
import { db } from '../../../firebase';
import * as actionTypes from '../../../store/actions/actionTypes';
import { setTablePage } from '../../../store/reducers/submissions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  grid: {
    height: '100%',
    padding: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  filters: {
    display: 'block',
    marginBottom: theme.spacing(3)
  },
  tableContainer: {
    maxWidth: '100vw',
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
    overflowX: 'auto',
  },
  exportContainer: {
    padding: '12px',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
}));

const ReturnsList = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const userRole = useSelector(state => state.auth.user.roles[0]);
  const userId = useSelector(state => state.auth.userId);
  const { username } = useSelector(state => state.auth.user);
  const filters = useSelector(state => state.filters.returnFilters);
  const filteredSubmissions = useSelector( state => state.filters.filteredReturns);

  const tableHeaders = [
    { label: t('tableHeaders.date'), key: 'date' },
    { label: t('tableHeaders.reference'), key: 'reference' },
    { label: t('tableHeaders.storen'), key: 'storen' },
    { label: t('tableHeaders.store'), key: 'store' },
    { label: t('tableHeaders.partner'), key: 'partner' },
    { label: `${t('tableHeaders.worker')}/${t('tableHeaders.partner')}`, key: 'worker' },
    { label: t('tableHeaders.city'), key: 'city' },
    { label: t('tableHeaders.street'), key: 'street' },
    { label: t('tableHeaders.comments'), key: 'comments' }
  ];

  const [submissions, setSubmissions] = useState([]);
  const [exportSubmissions, setExportSubmissions] = useState([]);
  const [selectedSubmissions, setSelectedSubmissions] = useState([]);
  const [references, setReferences] = useState([]);
  const [partners, setPartners] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [storeNumbers, setStoreNumbers] = useState([]);
  const [storeNames, setStoreNames] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [types, setTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [streets, setStreets] = useState([]);
  const [areFiltersEstablished, setAreFiltersEstablished] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [refreshSubs, setRefreshSubs] = useState(0);
  const [refreshFilterSubs, setRefreshFilterSubs] = useState(0);
  const [isShortQuery, setIsShortQuery] = useState(true);

  const hasSelectedItems = selectedSubmissions.length !== 0;
  const exportSubsUtil = submissions => {
    const subArray = [];
    submissions &&
      submissions.forEach(element => {
        if (element.products.length > 0) {
          subArray.push(
            ...element.products.map(prod => {
              return {
                id: element.id,
                Datum: element.date,
                Nalog: element.reference,
                Partner: element.partner,
                Radnik: element.worker,
                PM: element.storeNumber + ' - ' + element.storeName,
                Grad: element.city,
                Ulica: element.street,
                Šifra_artikla: prod.productId,
                Ime_artikla: prod.name,
                Barkod: prod.barcode,
                Pakiranje: prod.package,
                JM: prod.unit,
                Količina: prod.quantity,
                Broj_narudžbe: element.orderNotice,
                Država: element.country,
                Lot: prod.lot||"n/a",
              };
            })
          );
        } else {
          subArray.push({
            id: element.id,
            Datum: element.date,
            Nalog: element.reference,
            Partner: element.partner,
            Radnik: element.worker,
            PM: element.storeNumber + ' - ' + element.storeName,
            Grad: element.city,
            Ulica: element.street,
            Šifra_artikla: '',
            Ime_artikla: '',
            Barkod: '',
            Pakiranje: '',
            JM: '',
            Količina: '',
            Broj_narudžbe: element.orderNotice,
            Država: element.country
          });
        }
      });
    return subArray;
  };

  // reset table submission page
  useEffect(() => { dispatch(setTablePage({ submissions: 0 })) },[])

  // fetch return list
  const RETURN_COLLECTION = 'returns'
  const fetchReturns = async isDateQuery => {

    const startDate = moment().subtract(30, 'd').set({ hour : 0, minute : 0, second : 0, millisecond : 0 }).toDate()
    const endDate = moment().set({ hour : 23, minute: 59, second : 59 , millisecond : 0 }).toDate()

    let subs;
    if (userRole === 'admin') {
      subs = db.collection(RETURN_COLLECTION).orderBy('created', 'desc');

      if (isDateQuery) {
        subs = subs
          .where('created', '>=', startDate)
          .where('created', '<=', endDate);
      }

      subs = await (await subs.get()).docs.map(sub => ({
        id: sub.id,
        ...sub.data()
      }));
    }

    if (userRole === 'partner') {
      subs = db
        .collection(RETURN_COLLECTION)
        .where('partnerInfo.id', '==', userId)
        .orderBy('created', 'desc');
      if (isDateQuery) {
        subs = subs
          .where('created', '>=', startDate)
          .where('created', '<=', endDate);
      }
      subs = await (await subs.get()).docs.map(sub => ({
        id: sub.id,
        ...sub.data()
      }));
    }

    const isSpecialPartner = Boolean(['papillon', 'pikrijeka'].find(usr => usr === username))

    if (userRole === 'partner' && isSpecialPartner) {
      // case of Papillon partner
      if (username === 'papillon') {
        subs = db
          .collection(RETURN_COLLECTION)
          .where('workerInfo.isPapillon', '==', true)
          .orderBy('created', 'desc');
      }

      // case Pikrijeka partner
      if (username === 'pikrijeka') {
        subs = db
          .collection(RETURN_COLLECTION)
          .where('partnerInfo.isPikrijeka', '==', true)
          .orderBy('created', 'desc');
      }

      if (isDateQuery) {
        subs = subs
          .where('created', '>=', startDate)
          .where('created', '<=', endDate);
      }

      subs = await (await subs.get()).docs.map(sub => ({
        id: sub.id,
        ...sub.data()
      }));
    }

    if (userRole === 'worker') {
      subs = db
        .collection(RETURN_COLLECTION)
        .where('workerInfo.id', '==', userId)
        .orderBy('created', 'desc');

      if (isDateQuery) {
        subs = subs
          .where('created', '>=', startDate)
          .where('created', '<=', endDate);
      }
      subs = await (await subs.get()).docs.map(sub => ({
        id: sub.id,
        ...sub.data()
      }));
    }

    return subs;
  };

  const fetchSubmissionsWithFilters = async () => {
    const keys = Object.keys(filters);
    const values = Object.values(filters);
    let query;
    if (userRole === 'admin') {
      query = db.collection(RETURN_COLLECTION);
    }

    // case: Papillon partner
    else if (userRole === 'partner' && username === 'papillon') {
      query = db
        .collection(RETURN_COLLECTION)
        .where('workerInfo.isPapillon', '==', true)
    }

    // case: Pikrijeka partner
    else if (userRole === 'partner' && username === 'pikrijeka') {
      query = db
        .collection(RETURN_COLLECTION)
        .where('partnerInfo.isPikrijeka', '==', true)
    }

    else if (userRole === 'partner') {
      query = db
        .collection()
        .where('partnerInfo.id', '==', userId);
    }

    else if (userRole === 'worker') {
      query = db.collection(RETURN_COLLECTION).where('workerInfo.id', '==', userId);
    }

    keys.forEach((key, index) => {
      if (values[index] !== null) {
        switch (key) {
          case 'startDate':
            const start = new Date(values[index].setHours(0, 0, 0, 0));
            query = query.where('created', '>=', start);
            break;
          case 'endDate':
            const end = new Date(values[index].setHours(23, 59, 59, 0));
            query = query.where('created', '<=', end);
            break;
          case 'reference':
            query = query.where('reference', '==', values[index]);
            break;
          case 'partner':
            query = query.where('partnerInfo.name', '==', values[index]);
            break;
          case 'worker':
            query = query.where('workerInfo.username', '==', values[index]);
            break;
          case 'storeNumber':
            query = query.where('storeInfo.number', '==', values[index]);
            break;
          case 'storeName':
            query = query.where('storeInfo.name', '==', values[index]);
            break;
          case 'classification':
            query = query.where('storeInfo.classification', '==', values[index]);
            break;
          case 'type':
            query = query.where('storeInfo.type', '==', values[index]);
            break;
          case 'region':
            query = query.where('storeInfo.address.region', '==', values[index]);
            break;
          case 'city':
            query = query.where('storeInfo.address.city', '==', values[index]);
            break;
          case 'street':
            query = query.where('storeInfo.address.street', '==', values[index]);
            break;
          default:
            break;
        }
      }
    });
    
    return await (
      await query.orderBy('created', 'desc').get()
    ).docs.map(sub => ({ id: sub.id, ...sub.data() }));
  };

  const createSetFromArray = array =>
    [...new Set(array.map(item => item.label))].map(item => ({ label: item }));

  const handleRowClick = async subData => {
    history.push(`/returns/${subData.reference}`, {
      reference: subData.reference
    });
  };

  const handleApplyFilter = _filters => {
    setSelectedSubmissions([]);
    dispatch({
      type: actionTypes.APPLY_RETURNS_FILTER,
      filters: _filters
    });
  };

  const handleClearFilter = async () => {
    dispatch({
      type: actionTypes.CLEAR_RETURNS_FILTER
    });

    setLoading(true);
    const fetchedSubs = await fetchReturns();
    //Set submissions list if we don't have filtered subs

    const newExportData = fetchedSubs.map(sub => {
      // const sub = _sub.data()
      return {
        id: sub.id,
        date: formattedDateWithMoment(sub.created),
        reference: sub.reference,
        partner: sub.partnerInfo.name,
        worker: sub.workerInfo.username,
        storeNumber: sub.storeInfo.number,
        storeName: sub.storeInfo.name,
        city: sub.storeInfo.address.city,
        street: sub.storeInfo.address.street,
        country: sub.storeInfo.country || '',
        comments: sub.comments.length,
        products: sub.products,
        orderNotice: sub.orderNotice
      };
    });

    setExportSubmissions(exportSubsUtil(newExportData));

    setSubmissions(
      fetchedSubs.map(sub => {
        return {
          id: sub.id,
          date: formattedDateWithMoment(sub.created),
          reference: sub.reference,
          partner: sub.partnerInfo.name,
          worker: sub.workerInfo.username,
          storeNumber: sub.storeInfo.number,
          storeName: sub.storeInfo.name,
          city: sub.storeInfo.address.city,
          street: sub.storeInfo.address.street,
          comments: sub.comments.length,
          classification: sub.storeInfo.classification,
          type: sub.storeInfo.type
        };
      })
    );
    setLoading(false);
  };

  const notAllFiltersNull = () =>
    Object.values(filters || {}).find(value => value !== null);

  useEffect(() => {
    (async () => {
      if (notAllFiltersNull()) {
        setLoading(true);
        const fetchedSubs = await fetchSubmissionsWithFilters();

        const newFilteredData = fetchedSubs.map(sub => {
          // const sub = _sub.data()
          return {
            id: sub.id,
            date: formattedDateWithMoment(sub.created),
            reference: sub.reference,
            partner: sub.partnerInfo.name,
            worker: sub.workerInfo.username,
            storeNumber: sub.storeInfo.number,
            storeName: sub.storeInfo.name,
            city: sub.storeInfo.address.city,
            street: sub.storeInfo.address.street,
            comments: sub.comments.length,
            classification: sub.storeInfo.classification,
            type: sub.storeInfo.type
          };
        });

        const newExportData = fetchedSubs.map(sub => {
          // const sub = _sub.data()
          return {
            id: sub.id,
            date: formattedDateWithMoment(sub.created),
            reference: sub.reference,
            partner: sub.partnerInfo.name,
            worker: sub.workerInfo.username,
            storeNumber: sub.storeInfo.number,
            storeName: sub.storeInfo.name,
            city: sub.storeInfo.address.city,
            street: sub.storeInfo.address.street,
            country: sub.storeInfo.country || '',
            comments: sub.comments.length,
            products: sub.products,
            orderNotice: sub.orderNotice
          };
        });

        dispatch({
          type: actionTypes.SET_RETURNS_FILTERED_DATA,
          filteredData: newFilteredData
        });
        
        setExportSubmissions(exportSubsUtil(newExportData));
        setSubmissions(newFilteredData);
        setLoading(false);
      }
    })();
  }, [filters, refreshFilterSubs]);

  useEffect(() => {

    (async () => {
      setLoading(true);
      const fetchedSubs = await fetchReturns(isShortQuery);
      //Set submissions list if we don't have filtered subs
      if (filteredSubmissions.length === 0) {
        setSubmissions(
          fetchedSubs.map(sub => {
            return {
              id: sub.id,
              date: formattedDateWithMoment(sub.created),
              reference: sub.reference,
              partner: sub.partnerInfo.name,
              worker: sub.workerInfo.username,
              storeNumber: sub.storeInfo.number,
              storeName: sub.storeInfo.name,
              city: sub.storeInfo.address.city,
              street: sub.storeInfo.address.street,
              comments: sub.comments.length,
              classification: sub.storeInfo.classification,
              type: sub.storeInfo.type
            };
          })
        );

        setLoading(false);
        setExportSubmissions(
          exportSubsUtil(
            fetchedSubs.map(sub => {
              return {
                id: sub.id,
                date: formattedDateWithMoment(sub.created),
                reference: sub.reference,
                partner: sub.partnerInfo.name,
                worker: sub.workerInfo.username,
                storeNumber: sub.storeInfo.number,
                storeName: sub.storeInfo.name,
                city: sub.storeInfo.address.city,
                street: sub.storeInfo.address.street,
                country: sub.storeInfo.country || '',
                comments: sub.comments.length,
                products: sub.products,
                orderNotice: sub.orderNotice
              };
            })
          )
        );
      }

      if (!areFiltersEstablished) {
        const _references = [];
        const _partners = [];
        const _workers = [];
        const _storeNumbers = [];
        const _storeNames = [];
        const _classifications = [];
        const _types = [];
        const _regions = [];
        const _cities = [];
        const _streets = [];
        //Create filters laels
        for (let sub of fetchedSubs) {
          _references.push({ label: sub.reference });
          _partners.push({ label: sub.partnerInfo.name });
          _workers.push({ label: sub.workerInfo.username });
          _storeNumbers.push({ label: sub.storeInfo.number });
          _storeNames.push({ label: sub.storeInfo?.name });
          _classifications.push({ label: sub.storeInfo.classification });
          _types.push({ label: sub.storeInfo.type });
          _regions.push({ label: sub.storeInfo?.address.region });
          _cities.push({ label: sub.storeInfo?.address.city });
          _streets.push({ label: sub.storeInfo?.address.street });
        }
        //set filters options
        setReferences(createSetFromArray(_references));
        setPartners(createSetFromArray(_partners));
        setWorkers(createSetFromArray(_workers));
        setStoreNumbers(createSetFromArray(_storeNumbers));
        setStoreNames(createSetFromArray(_storeNames));
        setClassifications(createSetFromArray(_classifications));
        setTypes(createSetFromArray(_types));
        setRegions(createSetFromArray(_regions));
        setCities(createSetFromArray(_cities));
        setStreets(createSetFromArray(_streets));
        setAreFiltersEstablished(true); //to avoid re-establishing filters with filtered subs
      }
    })();
  }, [isShortQuery, refreshSubs]);

  const updateSubmissions = async () => {
    setSelectedSubmissions([]);
    if (notAllFiltersNull()) {
      setRefreshFilterSubs(Math.random() * 99999);
    } else {
      setRefreshSubs(Math.random() * 99999);
    }
  };

  const deleteSubmissionHandler = async () => {
    const selectedSubmissionsIds = [...selectedSubmissions];
    let batch = db.batch();
    selectedSubmissionsIds.forEach(docId => {
      const docRef = db.collection(RETURN_COLLECTION).doc(docId);
      batch.delete(docRef);
    });
    // const resBatch = await batch.commit();
    await batch.commit();
    setDeleteDialogOpen(false);
    await updateSubmissions();
    enqueueSnackbar(t('notifications.submissionsDeleted'), {variant: 'success'})
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={12}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <div className={classes.filters}>
                <FilterSection
                  cities={cities}
                  classifications={classifications}
                  handleApplyFilter={handleApplyFilter}
                  handleClearFilter={handleClearFilter}
                  partners={partners}
                  references={references}
                  regions={regions}
                  storeNames={storeNames}
                  storeNumbers={storeNumbers}
                  streets={streets}
                  types={types}
                  userInfo={{ userRole, username }}
                  workers={workers}
                />
              </div>
            </div>
            <Card className={classes.exportContainer}>
              {hasSelectedItems && userRole === 'admin' && (
                <BasicFab
                  handleClick={() => setDeleteDialogOpen(true)}
                  label={t('button.deleteSubmissions')}
                  remove
                />
              )}
              <ExportCSVButton
                // name="Export"
                csvData={
                  filteredSubmissions.length !== 0
                    ? filteredSubmissions
                    : submissions
                }
                fileName={RETURN_COLLECTION}
                name={t('button.export')}
                selectedItems={selectedSubmissions}
                skipCol={['id']}
              />
              <ExportCSVButton
                // name={"Export za narudžbe"}
                csvData={exportSubmissions}
                fileName={'returns-with-products'}
                name={t('button.exportWithProduct')}
                selectedItems={selectedSubmissions}
                skipCol={['id', 'comments']}
              />
            </Card>
            {isShortQuery && (
              <Card
                className={classes.exportContainer}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Typography variant="h5">{t('filter.msgDays')}</Typography>
                <Button
                  color="secondary"
                  onClick={() => {
                    setLoading(true);
                    setIsShortQuery(false);
                  }}
                  style={{ marginLeft: '16px' }}
                  variant="outlined"
                >
                  {t('button.clearFilter')}
                </Button>
              </Card>
            )}
            <div className={classes.tableContainer}>
              <BasicTable
                data={
                  filteredSubmissions.length !== 0
                    ? filteredSubmissions
                    : submissions
                }
                headers={tableHeaders}
                history={history}
                isReturnsList
                isSubmissionsList
                loading={loading}
                onRowClick={handleRowClick}
                selectedItems={selectedSubmissions}
                setSelectedItems={setSelectedSubmissions}
                skipCol={['classification', 'type']}
                styles={classes.table}
                withLink
                withPagination
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <BasicDialog
        count={selectedSubmissions.length}
        handleClose={() => setDeleteDialogOpen(false)}
        handleConfirm={() => deleteSubmissionHandler()}
        open={deleteDialogOpen}
        title={t('dialog.deleteSubmissions')}
      />
    </div>
  );
};

ReturnsList.propTypes = {
  history: PropTypes.object
};

export default withRouter(ReturnsList);
