import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Typography
} from '@material-ui/core';
import LangSelector from '../../../../common/LanguageDetector';

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Preferences = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form>
        <CardHeader subheader="Manage your preferences" title="Preferences" />
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <Typography gutterBottom variant="h6">
                Language
              </Typography>
              <LangSelector />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {/*         <CardActions>
          <Button
            color="primary"
            variant="outlined"
          >
            Save
          </Button>
        </CardActions> */}
      </form>
    </Card>
  );
};

Preferences.propTypes = {
  className: PropTypes.string
};

export default Preferences;
