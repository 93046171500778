/* eslint-disable react/prop-types */
import React from 'react';
import { Typography, FormControlLabel, Checkbox, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(3),
    textTransform: 'uppercase'
  },
  checkBox: {
    display: 'block'
  }
}));

const Questions = props => {
  const { questions, handleCheck } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const checkHandler = (value, question) => {
    handleCheck(value, question);
  };


  if(questions.length === 0) return null

  return (
    <>
      <Typography className={classes.title} variant="h6">
        {t('addSubmission.additionalQuestions')}
      </Typography>

      <Divider className={classes.divider} />

      {questions.map(question => (
        <FormControlLabel
          className={classes.checkBox}
          control={
            <Checkbox
              color="primary"
              value={question.checked}
              checked={question.checked}
              onChange={(e, v) => checkHandler(v, question)}
            />
          }
          label={question.label}
          key={question.id}
        />
      ))}
    </>
  );
};

export default Questions;
