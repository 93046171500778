import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  Button: {
    margin: theme.spacing(2, 1, 2, 0),
    padding: theme.spacing(0.5),
    width: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  spinner: {
    color: theme.palette.white
  }
}));

const BasicButtonWithSpinner = ({
  label,
  isLoading,
  styles,
  ...rest
}) => {

  const classes = useStyles();

  return <Button
    {...rest}
    className={clsx(classes.Button, styles)}
    color="primary"
    fullWidth
    size="large"
    type="submit"
    variant="contained"
  >
    {isLoading ? <CircularProgress
      className={classes.spinner}
      size={24}
    /> : label}
  </Button>
};

export default BasicButtonWithSpinner;
