/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, TextField, Box, Typography } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Fragment } from 'react';
import padWithZero from '../helpers/padWithZero';

const useStyles = makeStyles(() => ({
  table: {},
  root: {
    margin: 0,
    padding: 0,
    '&:focus': {
      outline: 'none',
      border: 'none'
    }
  },
  input: {
    width: 50,
    fontSize: 12
  },
  lotInput: {
    width: '100%',
    fontSize: 12
  },
  inner: {},
  qtyWrapper: {
    padding: '2px',
    borderRadius: '5px',
    backgroundColor: '#F0F0F0',
    flexGrow: 1,
    textAlign: 'center'
  },
  actionClsGrid: {
    display: 'flex',
    justifyContent: ' center',
    alignItems: 'center'
  },
  floodTxt: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '3px solid #e2e2e2',
    border: '1px solid #e2e2e2',
    margin: '5px 0',
    padding: '2px 4px'
  },
  contentWrapper: {
    flexBasis: '85%',
    flexGrow: 1,
    alignItems: 'center',
    display: 'grid',
    gridAutoRows: 'min-content',
    '& >div': {
      paddingRight: '8px'
    }
  },
  actionWrapper: {
    flexBasis: '15%',
    maxWidth: '120px',
    minWidth: '95px',
    justifyContent: 'center'
  },
  sub1ResTxt: {
    fontSize: 'max(12px, min(2vw, 16px))',
    lineHeight: 'unset'
  }
}));

export default function SimpleTable({
  data,
  increment,
  decrement,
  setValue,
  toggleEdit,
  readOnly,
  hasLot,
  setLot
}) {
  const classes = useStyles();

  if (data.length === 0) return null;
  let table = null;

  if (readOnly) {
    table = (
      <Box style={{ overflowX: 'hidden' }}>
        {data.map(row => (
          <Box className={classes.rowWrapper} key={row.name}>
            <div className={classes.contentWrapper}>
              <Box marginY="4px">
                <Typography
                  className={classes.sub1ResTxt}
                  style={{ fontWeight: 600 }}
                  variant="subtitle1">
                  {row.name}
                </Typography>
                <Typography
                  className={classes.sub1ResTxt}
                  style={{ marginTop: 8 }}
                  variant="subtitle1">
                  {row.barcode}
                </Typography>
              </Box>
              <Box
                display="flex"
                fontSize={12}
                justifyContent="space-between"
                marginTop={'-4px'}>
                <Typography variant="subtitle2">
                  <span style={{ fontWeight: 800 }}> {row.package}</span> {'  '}
                  <span>{`${row.unit}`.toUpperCase()}</span>
                </Typography>
              </Box>
            </div>
            <Box className={classes.actionWrapper} display="flex">
              <Box className={classes.qtyWrapper}>
                <Box
                  component="span"
                  display={!row.edit && 'inline-flex'}
                  onClick={() => toggleEdit(row.name)}>
                  {padWithZero(row.quantity)}
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  } else {
    table = (
      <Fragment>
        <Box style={{ overflowX: 'hidden' }}>
          {data.map(row => (
            <Box className={classes.rowWrapper} key={row.name}>
              <div className={classes.contentWrapper}>
                <Box marginY="4px">
                  <Typography
                    className={classes.sub1ResTxt}
                    style={{ fontWeight: 600 }}
                    variant="subtitle1">
                    {row.name}
                  </Typography>
                  <Typography
                    className={classes.sub1ResTxt}
                    style={{ marginTop: 8 }}
                    variant="subtitle1">
                    {row.barcode}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  fontSize={12}
                  alignItems="center"
                  marginTop={'-4px'}
                  marginBottom={'4px'}>
                  <Typography variant="subtitle2" style={{ marginRight: 42 }}>
                    <span style={{ fontWeight: 800 }}> {row.package}</span>{' '}
                    {'  '}
                    <span>{`${row.unit}`.toUpperCase()}</span>
                  </Typography>
                  {hasLot && (
                    <Fragment>
                      <Typography
                        variant="subtitle2"
                        style={{ marginRight: 10 }}>
                        <span style={{ fontWeight: 800 }}>LOT</span>
                      </Typography>
                      <TextField
                        autoFocus
                        classes={{ root: classes.root }}
                        InputProps={{ className: classes.lotInput }}
                        onChange={e => setLot(e, row.name)}
                        size="small"
                        type="text"
                        name="lot"
                        value={row?.lot}
                        variant="outlined"
                      />
                    </Fragment>
                  )}
                </Box>
              </div>
              <Box className={classes.actionWrapper} display="flex">
                <IconButton
                  aria-label="dec"
                  className={classes.margin}
                  onClick={() => decrement(row.name)}
                  size="small">
                  <ArrowDownwardIcon fontSize="inherit" />
                </IconButton>
                <Box className={classes.qtyWrapper}>
                  <Box
                    component="span"
                    display={!row.edit ? 'inline-flex' : 'none'}
                    onClick={() => toggleEdit(row.name)}>
                    {!row.edit ? padWithZero(row.quantity) : null}
                  </Box>

                  {row.edit && (
                    <TextField
                      autoFocus
                      classes={{ root: classes.root }}
                      InputProps={{ className: classes.input }}
                      onBlur={() => toggleEdit(row.name)}
                      onChange={e => setValue(e, row.name)}
                      size="small"
                      type="text"
                      name="quantity"
                      value={row.quantity}
                      variant="outlined"
                    />
                  )}
                </Box>
                <IconButton
                  aria-label="inc"
                  className={classes.margin}
                  color="primary"
                  onClick={() => increment(row.name)}
                  size="small">
                  <ArrowUpwardIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Fragment>
    );
  }

  return (
    <PerfectScrollbar>
      <div className={classes.inner}>{table}</div>
    </PerfectScrollbar>
  );
}
