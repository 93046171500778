/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack'

import BasicFab from '../../../../../components/BasicFab';
import BasicDialog from '../../../../../components/BasicDialog';
import AddProduct from '../../../../Products/AddProduct';
import ImportCSVButton from '../../../../../helpers/ImportTableExcel';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const ProductsToolbar = ({
  loading,
  className,
  refresh,
  isWorker,
  isPartner,
  selectedProducts,
  deleteProducts,
  importHandler,
  editProduct,
  setEdit,
  ...rest
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const hasSelectedItems = selectedProducts.length !== 0;

  useEffect(() => {
    if (editProduct) {
      setOpen(true);
    }
  }, [editProduct]);

  const closeModalHandler = () => {
    setOpen(false);
    setEdit(false);
  };

  const deleteProductsHandler = () => {
    deleteProducts().then(() => {
      refresh();
      setDeleteDialogOpen(false);
      enqueueSnackbar(t('notifications.productsDeleted'), {variant: 'success'})
    });
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        {!isWorker && !isPartner && (
          <ImportCSVButton
            importHandler={importHandler}
            loading={loading}
            onSuccess={() =>
              enqueueSnackbar(t('notifications.productsAdded'), {variant: 'success'})
            }
          />
        )}
        <span className={classes.spacer} />
        {hasSelectedItems && (
          <BasicFab
            handleClick={() => setDeleteDialogOpen(true)}
            label={t('button.deleteProducts')}
            remove
          />
        )}
        <BasicFab
          handleClick={() => setOpen(true)}
          label={t('button.addProduct')}
        />
      </div>
      <AddProduct
        handleClose={closeModalHandler}
        isEdit={editProduct}
        modalOpen={open}
        onSuccess={() =>
          enqueueSnackbar(t('notifications.productsAdded'), {variant: 'success'})
        }
        refresh={refresh}
      />
      <BasicDialog
        count={selectedProducts.length}
        handleClose={() => setDeleteDialogOpen(false)}
        handleConfirm={() => deleteProductsHandler()}
        open={deleteDialogOpen}
        title={t('dialog.deleteProducts')}
      />
    </div>
  );
};

ProductsToolbar.propTypes = {
  className: PropTypes.string
};

export default ProductsToolbar;
