// const firebase = require('firebase');
// // Required for side-effects
// require('firebase/firestore');
// require('firebase/functions');
// require('firebase/firebase-functions');
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';
const firebaseConfig = {
  // apiKey: process.env.REACT_APP_API_KEY,
  // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_DATABASE_URL,
  // projectId: process.env.REACT_APP_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_APP_ID,
  // measurementId: process.env.REACT_APP_MEASUREMENT_ID
  apiKey: 'AIzaSyCOaAKbPEU19RbDSEGN5X0Bx-Vzz8FF7BM',
  authDomain: 'papillon-trade-app.firebaseapp.com',
  databaseURL: 'https://papillon-trade-app.firebaseio.com',
  projectId: 'papillon-trade-app',
  storageBucket: 'papillon-trade-app.appspot.com',
  messagingSenderId: '865111087584',
  appId: '1:865111087584:web:68555d49efa7d0b245b385',
  measurementId: 'G-ZVVLEXJCHZ'
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const firestore = firebase.firestore;
export const storage = firebase.storage();
export const auth = firebase.auth();
export const authProvider = firebase.auth.EmailAuthProvider;
export const Timestamp = firebase.firestore.Timestamp;
export const functions = firebase.functions;
export default firebase;
