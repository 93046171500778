import React from 'react'
import { Switch, FormGroup, FormControlLabel, FormControl } from '@material-ui/core'

const BasicSwitch = ({ label, isChecked, handleCheck }) => (
    <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
            <FormControlLabel
                control={<Switch color="primary" checked={isChecked} onChange={handleCheck} />}
                label={label}
                labelPlacement="start"
            />
        </FormGroup>
    </FormControl>
)

export default BasicSwitch