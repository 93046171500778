import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'


const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650,
    },
    root: {
        margin: 0,
        padding: 0,
        '&:focus': {
            outline: 'none',
            border: 'none'
        }
    },
    input: {
        width: 50,
        fontSize: 12
    },
    inner: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: 300
        }
    }
}))


export default function SimpleTable({ data }) {
    const classes = useStyles()

    if (data.length === 0) return null

    return (

        < PerfectScrollbar >
            <div className={classes.inner}>
                <Table className={classes.table} aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Partner</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={Math.random()}>

                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>

                                <TableCell>
                                    {row.label}
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </PerfectScrollbar >
    );
}
