/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BasicModal from '../../../components/BasicModal';
import BasicTextField from '../../../components/BasicTextField';
import AutoCompleteInput from '../../../components/AutoCompleteInput';
import BasicSelect from '../../../components/BasicSelect';
import Alert from '../../../components/BasicAlert';
import BasicButtonWithSpinner from '../../../components/BasicButtonWithSpinner';
import validationSchemas from '../../../common/validationSchemas';
import { Timestamp, db } from '../../../firebase';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  form: {
    padding: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3),
    textTransform: 'uppercase'
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const AddHORECAST = ({ onSuccess, refresh, modalOpen, closeModal, isEdit }) => {
  const [partners, setPartners] = useState([]);
  const [partner, setPartner] = useState(null);
  const [classification, setClassification] = useState('store');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const userInfo = useSelector(state => ({
    ...state.auth.user,
    userRole: state.auth.user.roles[0]
  }));
  const userId = useSelector(state => state.auth.userId);
  const [isValidFields, setIsValidFields] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const inputLabel = useRef(null);
  const isPapillon = userInfo.userRole === 'worker' && userInfo.isPapillon;
  const isPikrijeka = userInfo.userRole === 'worker' && userInfo.isPikrijeka;

  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    errors,
    formState,
  } = useForm({
    defaultValues: {
      storeNumber: '',
      storeName: '',
      country: '',
      region: '',
      city: '',
      street: '',
      type: '',

      oib: '',
      gln: '',
      code: '',
      glnKupca: ''
    },
    mode: 'onChange',
    validationSchema: validationSchemas.horecastSchema
  });

  useEffect(() => {
    (async () => {
      if (userInfo.userRole === 'worker') {
        setPartner({
          id: userInfo.partnerInfo.id,
          name: userInfo.partnerInfo.name
        });
      }
      if (isPapillon) {
        const partnersIds = userInfo.partnerInfo.map(el => el.id);

        const getDoc = await db.collection('partners').get();

        const getPartners = await getDoc.docs
          .filter(obj => partnersIds.includes(obj.id))
          .map(doc => ({ id: doc.id, label: doc.data().name }));
        setPartners(getPartners);
      }
      if (isPikrijeka) {
        const getDoc = await db
          .collection('partners')
          .where('isPikrijeka', '==', true)
          .get();

        const getPartners = await getDoc.docs.map(doc => ({
          id: doc.id,
          label: doc.data().name
        }));

        setPartners(getPartners);
      } else if (userInfo.userRole === 'partner') {
        setPartner({ id: userId, name: userInfo.name });
      } else if (userInfo.userRole === 'admin') {
        db.collection('partners')
          .get()
          .then(function (querySnapshot) {
            const partners = querySnapshot.docs.map(doc => ({
              id: doc.id,
              label: doc.data().name
            }));
            setPartners(partners);
          })
          .catch(function (error) {
            console.log('Error getting partners list: ', error);
          });
      }
    })();
  }, []);

  useEffect(() => {
    if (isEdit) {
      setTimeout(async () => {
        const getData = await (
          await db
            .collection('horecasts')
            .doc(isEdit.id)
            .get()
        ).data();

        const customData = {
          ...isEdit,
          classification: getData.classification,
          country: getData.country,
          storeName: getData.name,
          storeNumber: getData.number,
          partnerInfo: getData.partnerInfo,
          type: getData.type,
          ...getData.address
        };
        updateFields(isEdit, customData);
        hasValidate();
      });
    } else {
      // reset();
      hasValidate();
    }
    // return () => {};
  }, [isEdit]);
  useEffect(() => {
    hasValidate();
  }, [formState]);

  const updateFields = (data, getData) => {
    if (userInfo.userRole === 'admin' || isPapillon) {
      setPartner(getData.partnerInfo);
    }
    setClassification(getData.classification);
    setValue('storeNumber', getData.storeNumber, true);
    setValue('storeName', getData.storeName, true);
    setValue('country', getData.country, true);
    setValue('region', getData.region, true);
    setValue('city', getData.city, true);
    setValue('street', getData.street, true);
    setValue('type', getData.type, true);

    setValue('oib', getData.oib);
    setValue('gln', getData.gln);
    setValue('code', getData.code);
    setValue('glnKupca', getData.glnKupca||'');
  };
  const onCreateHORECAST = (data, e) => {
    const HORECASTData = {
      reference: `${classification}-${parseInt(Math.random() * 10000)}`,
      classification,
      type: data.type,
      partnerInfo: partner,
      number: data.storeNumber,
      name: data.storeName,
      country: data.country,
      address: {
        region: data.region,
        city: data.city,
        street: data.street
      },
      created: Timestamp.fromDate(new Date()),
      oib: data.oib || '',
      gln: data.gln || '',
      code: data.code || '',
      glnKupca: data.glnKupca || ''
    };
    setLoading(true);
    db.collection('horecasts')
      .add(HORECASTData)
      .then(() => {
        setLoading(false);
        setErrorMessage(null);
        e.target.reset();
        refresh();
        closeModal();
        onSuccess();
      })
      .catch(err => {
        setLoading(false);
        setErrorMessage(t('notifications.servererror'));
        console.log(err);
      });
  };
  const onUpdateHORECAST = (data, e) => {
    if (!isEdit) {
      return null;
    }
    const { id } = isEdit;
    const HORECASTData = {
      classification,
      type: data.type,
      partnerInfo: partner,
      number: data.storeNumber,
      name: data.storeName,
      country: data.country,
      address: {
        region: data.region,
        city: data.city,
        street: data.street
      },
      oib: data.oib || '',
      gln: data.gln || '',
      code: data.code || '',
      glnKupca: data.glnKupca || ''
    };
    setLoading(true);
    db.collection('horecasts')
      .doc(id)
      .update(HORECASTData)
      .then(() => {
        setLoading(false);
        setErrorMessage(null);
        e.target.reset();
        refresh();
        closeModal();
        onSuccess();
      })
      .catch(err => {
        setLoading(false);
        setErrorMessage(t('notifications.servererror'));
        console.log(err);
      });
  };
  const onPartnerSelected = async (event, partner) => {
    if (partner) setPartner({ id: partner.id, name: partner.label });
  };
  const hasValidate = async () => {
    const values = getValues();
    const keys = [
      'storeName',
      'storeNumber',
      'country',
      'region',
      'city',
      'street',
      'type'
    ];
    const fieldsCheck = keys.every(key => values[key].length > 0);
    let checkSelectedFields = true;
    if (userInfo.userRole === 'admin' || isPapillon) {
      checkSelectedFields = partner && classification.length > 0;
    }
    const check = true && fieldsCheck && checkSelectedFields;
    setIsValidFields(!check);
    return;
  };

  return (
    <BasicModal
      actions={[]}
      modalOpen={modalOpen}
      onCloseModal={() => {
        closeModal();
        setPartner([]);
      }}
      subtitle={t('addStoreForm.title')}
      title={t('addStoreForm.title')}
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit(isEdit ? onUpdateHORECAST : onCreateHORECAST)}
      >
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {(userInfo.userRole === 'admin' || isPapillon || isPikrijeka) && (
          <AutoCompleteInput
            label={t('addStoreForm.partner')}
            margin="dense"
            name="partner"
            onOptionSelected={onPartnerSelected}
            suggestions={partners}
            value={partner}
          />
        )}
        <Controller
          as={
            <BasicTextField
              error={errors.storeNumber}
              label={t('addStoreForm.number')}
              name="storeNumber"
              register={register}
              required
              type="text"
            />
          }
          control={control}
          name="storeNumber"
        />

        {/*  ADDITIONAL FIELDS OPTIONAL */}
        <Controller
          as={
            <BasicTextField
              error={errors.oib}
              label={t('addStoreForm.oib')}
              name="oib"
              register={register}
              type="text"
            />
          }
          control={control}
          name="oib"
        />
        <Controller
          as={
            <BasicTextField
              error={errors.gln}
              label={t('addStoreForm.gln')}
              name="gln"
              register={register}
              type="text"
            />
          }
          control={control}
          name="gln"
        />
        <Controller
          as={
            <BasicTextField
              error={errors.code}
              label={t('addStoreForm.code')}
              name="code"
              register={register}
              type="text"
            />
          }
          control={control}
          name="code"
        />
        <Controller
          as={
            <BasicTextField
              error={errors.glnKupca}
              label={t('addStoreForm.glnKupca')}
              name="glnKupca"
              register={register}
              type="text"
            />
          }
          control={control}
          name="glnKupca"
        />
        <Controller
          as={
            <BasicTextField
              error={errors.storeName}
              label={t('addStoreForm.name')}
              name="storeName"
              register={register}
              required
              type="text"
            />
          }
          control={control}
          name="storeName"
        />

        <Controller
          as={
            <BasicTextField
              error={errors.country}
              label={t('addStoreForm.country')}
              name="country"
              register={register}
              required
              type="text"
            />
          }
          control={control}
          name="country"
        />

        <Controller
          as={
            <BasicTextField
              error={errors.region}
              label={t('addStoreForm.region')}
              name="region"
              register={register}
              required
              type="text"
            />
          }
          control={control}
          name="region"
        />

        <Controller
          as={
            <BasicTextField
              error={errors.city}
              label={t('addStoreForm.city')}
              name="city"
              register={register}
              required
              type="text"
            />
          }
          control={control}
          name="city"
        />

        <Controller
          as={
            <BasicTextField
              error={errors.street}
              label={t('addStoreForm.street')}
              name="street"
              register={register}
              required
              type="text"
            />
          }
          control={control}
          name="street"
        />

        <BasicSelect
          inputRef={inputLabel}
          label={t('addStoreForm.classification')}
          name="classification"
          options={['store', 'horeca']}
          setValue={setClassification}
          value={classification}
        />
        <Controller
          as={
            <BasicTextField
              error={errors.type}
              label={t('addStoreForm.type')}
              name="type"
              register={register}
              required
              type="text"
            />
          }
          control={control}
          name="type"
        />
        <div className={classes.buttonWrapper}>
          <BasicButtonWithSpinner
            disabled={isValidFields || loading}
            isLoading={loading}
            label={isEdit ? t('button.update') : `Create ${classification}`}
          />
        </div>
      </form>
    </BasicModal>
  );
};

AddHORECAST.propTypes = {
  history: PropTypes.object
};

export default withRouter(AddHORECAST);
