import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Card } from '@material-ui/core';
import { ProductsToolbar } from './components';
import BasicTable from '../../../components/BasicTable';
import SearchInput from '../../../components/SearchInput';
import ExportCSVButton from '../../../helpers/ExportTableExcel';
import { db, Timestamp } from '../../../firebase';
import { deleteDocuments } from '../../../common/firebaseActions';

import { searchFilter } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  exportContainer: {
    padding: '12px',
    marginTop: '14px',
    marginBottom: '14px',
    display: 'flex',
    justifyContent: 'flex-start'
  }
}));

const ProductList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const tableHeaders = [
    t('tableHeaders.name'),
    t('tableHeaders.package'),
    t('tableHeaders.unit'),
    t('tableHeaders.barcode'),
    t('tableHeaders.partner')
  ];

  const [searchTxt, setSearchTxt] = useState('');
  const [dataArr, setDataArr] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [importSuccess, setImportSuccess] = useState();
  const { userId, user } = useSelector(state => state.auth);
  const userRole = useSelector(state => state.auth.user.roles[0]);
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, [importSuccess]);

  useEffect(() => {
    const results = searchFilter(dataArr, searchTxt, 'ALL', false);
    setProducts(results);
  }, [dataArr, searchTxt]);

  const fetchProducts = async () => {
    try {
      let fetchedProducts;
      setLoading(true);
      if (userRole === 'admin') {
        fetchedProducts = await (
          await db
            .collection('products')
            .orderBy('name', 'asc')
            .get()
        ).docs.map(doc => ({ id: doc.id, ...doc.data() }));
      } else {
        fetchedProducts = await (
          await db
            .collection('products')
            .where('partnerInfo.id', '==', userId)
            .orderBy('name', 'asc')
            .get()
        ).docs.map(doc => ({ id: doc.id, ...doc.data() }));
      }
      const formattedProducts = fetchedProducts.map(product => ({
        id: product.id,
        name: product.name,
        package: product.package,
        unit: product.unit,
        barcode: product.barcode,
        partner: product.partnerInfo?.name || '',
        partnerId: product.partnerInfo?.id || ''
      }));
      // setProducts(formattedProducts)
      setDataArr(formattedProducts);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const deleteProductsHandler = () => {
    return deleteDocuments('products', selectedProducts);
  };

  const updateProducts = () => {
    setSelectedProducts([]);
    fetchProducts();
  };

  const importProductsHandler = async rows => {
    if (!rows) {
      return;
    }
    setLoading(true);
    // const colKeys = rows[0];
    const rowsData = rows.slice(1).map(row => {
      // variables names. that should be on Excel file
      // 0)productId 1)name 2)package 3)unit 4)barcode 5)partnerInfo{id,name} alias partnerId/partnerName
      // created field use time
      return {
        productId: row[0],
        name: row[1],
        package: row[2],
        unit: row[3],
        barcode: row[4],
        partnerInfo: {
          id: row[5],
          name: row[6]
        },
        created: Timestamp.now()
      };
    });
    let batch = db.batch();
    rowsData.forEach(data => {
      const docRef = db.collection('products').doc();
      batch.set(docRef, data);
    });
    const resBatch = await batch.commit();
    setImportSuccess(Math.random() * 1323);
    setLoading(false);
  };
  const handleRowClick = async data => {
    if (user.roles.includes('admin') || user.roles.includes('partner')) {
      setEdit(data);
    }
  };
  return (
    <div className={classes.root}>
      <ProductsToolbar
        csvData={products}
        deleteProducts={deleteProductsHandler}
        editProduct={isEdit}
        // for Export btn
        fileName={'products'}
        importHandler={importProductsHandler}
        isPartner={userRole.includes('partner')}
        isWorker={userRole.includes('worker')}
        loading={loading}
        refresh={updateProducts}
        selectedProducts={selectedProducts}
        setEdit={setEdit}
      />
      <SearchInput
        onChange={e => setSearchTxt(e.target.value)}
        type="search"
      />
      <div className={classes.content}>
        <BasicTable
          data={products}
          headers={tableHeaders}
          loading={loading}
          onRowClick={handleRowClick}
          selectedItems={selectedProducts}
          setSelectedItems={setSelectedProducts}
          skipCol={['partnerId']}
          withPagination
        />
      </div>
      {!userRole.includes('worker') && !userRole.includes('partner') && (
        <Card className={classes.exportContainer}>
          <ExportCSVButton
            csvData={products}
            fileName={'products'}
            name="Export"
            selectedItems={selectedProducts}
          />
        </Card>
      )}
    </div>
  );
};

export default ProductList;
