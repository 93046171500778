import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

import { TotalUsers, TotalProfit } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers
            title="total partners"
            total={100}
            rate={15}
            icon={<PeopleIcon />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers
            title="total workers"
            total={15000}
            rate={20}
            icon={<PersonIcon />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalProfit />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
