import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Card } from '@material-ui/core';
import StoresToolbar from './components/StoresToolbar';
import BasicTable from '../../../components/BasicTable';
import ExportCSVButton from '../../../helpers/ExportTableExcel';
import SearchInput from '../../../components/SearchInput';

import { db, Timestamp } from '../../../firebase';
// import { deleteDocuments } from '../../../common/firebaseActions';
import { v4 as uuidv4 } from 'uuid';
import { searchFilter } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  exportContainer: {
    padding: '12px',
    marginTop: '14px',
    marginBottom: '14px',
    display: 'flex',
    justifyContent: 'flex-start'
  }
}));

const HORECASTList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  let [tableHeaders, setTableHeaders] = useState([
    t('tableHeaders.partner'),
    t('tableHeaders.number'),
    t('tableHeaders.name'),
    t('tableHeaders.classification'),
    t('tableHeaders.type'),
    t('tableHeaders.country'),
    t('tableHeaders.region'),
    t('tableHeaders.city'),
    t('tableHeaders.street')
  ]);

  const [searchTxt, setSearchTxt] = useState('');
  const [dataArr, setDataArr] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [importSuccess, setImportSuccess] = useState();
  const userInfo = useSelector(state => ({
    ...state.auth.user,
    userRole: state.auth.user.roles[0]
  }));
  const userId = useSelector(state => state.auth.userId);
  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    fetchStores();
  }, [importSuccess]);

  useEffect(() => {
    const results = searchFilter(dataArr, searchTxt, 'ALL', false);
    setStores(results);
  }, [dataArr, searchTxt]);

  const fetchStores = async () => {
    const isWorker =
      userInfo.userRole === 'worker' &&
      !userInfo.isPapillon &&
      !userInfo.isPikrijeka;
    const isSpecialWorker =
      userInfo.userRole === 'worker' &&
      (userInfo.isPapillon || userInfo.isPikrijeka);
    const isAdmin = userInfo.userRole === 'admin';
    const isPartner = userInfo.userRole === 'partner';

    try {
      setLoading(true);
      let fetchedStores;
      if (isWorker) {
        setTableHeaders(tableHeaders.filter(el => el !== 'Partner'));

        fetchedStores = await (
          await db
            .collection('horecasts')
            .where('partnerInfo.id', '==', userInfo.partnerInfo.id)
            .orderBy('name', 'asc')
            .get()
        ).docs;
      }
      if (isSpecialWorker) {
        const partnerIds = userInfo.partnerInfo.map(part => part.id);

        fetchedStores = await (
          await db
            .collection('horecasts')
            .orderBy('name', 'asc')
            .get()
        ).docs.filter(doc => {
          const partnerId = doc.data().partnerInfo.id;
          return partnerIds.includes(partnerId);
        });
      } else if (isPartner) {
        setTableHeaders(tableHeaders.filter(el => el !== 'Partner'));
        fetchedStores = await (
          await db
            .collection('horecasts')
            .where('partnerInfo.id', '==', userId)
            .orderBy('name', 'asc')
            .get()
        ).docs;
      } else if (isAdmin) {
        fetchedStores = await (
          await db
            .collection('horecasts')
            .orderBy('name', 'asc')
            .get()
        ).docs;
      }

      if (isWorker || isPartner) {
        fetchedStores = fetchedStores.map(doc => ({
          id: doc.id,
          number: doc.data().number,
          name: doc.data().name,
          classification: doc.data().classification,
          type: doc.data().type,
          country: doc.data().country,
          region: doc.data().address.region,
          city: doc.data().address.city,
          street: doc.data().address.street,
          oib: doc.data().oib || '',
          gln: doc.data().gln || '',
          code: doc.data().code || '',
          glnKupca: doc.data().glnKupca || ''
        }));
      } else {
        fetchedStores = fetchedStores.map(doc => ({
          id: doc.id,
          partner: doc.data().partnerInfo.name,
          partnerId: doc.data().partnerInfo.id,
          number: doc.data().number,
          name: doc.data().name,
          classification: doc.data().classification,
          type: doc.data().type,
          country: doc.data().country,
          region: doc.data().address.region,
          city: doc.data().address.city,
          street: doc.data().address.street,
          oib: doc.data().oib || '',
          gln: doc.data().gln || '',
          code: doc.data().code || '',
          glnKupca: doc.data().glnKupca || ''
        }));
      }
      // setStores(fetchedStores);
      // FOR SEARCHING TXT
      setDataArr(fetchedStores);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const deleteStoresHandler = async () => {
    const selectedStoresIds = [...selectedStores];
    let batch = db.batch();
    selectedStoresIds.forEach(docId => {
      const docRef = db.collection('horecasts').doc(docId);
      batch.delete(docRef);
    });
    // const resBatch = await batch.commit();
    return batch.commit();
  };

  const updateStores = () => {
    setSelectedStores([]);
    fetchStores();
  };

  const importStoresHandler = async rows => {
    if (!rows) {
      return false;
    }
    const colKeys = rows[0];
    if (
      !(
        colKeys[0].includes('partner') &&
        colKeys[1].includes('partnerId') &&
        colKeys[2].includes('number') &&
        colKeys[3].includes('name') &&
        colKeys[4].includes('classification') &&
        colKeys[5].includes('type') &&
        colKeys[6].includes('country') &&
        colKeys[7].includes('region') &&
        colKeys[8].includes('city') &&
        colKeys[9].includes('street')
      )
    ) {
      return false;
    }
    setLoading(true);
    const rowsData = rows.slice(1).map(row => {
      /* variables names. that should be on Excel file
            | 0,1)partnerInfo{id,name} alias partnerId/partnerName
            | 2)number 3)name 4)classification 5)type 6)country 
            | 7,8,9)address{city,region,street} alias adCity,adRegion,adStreet
            | created field use time
            |
      */
      /**
       * COLUMN NAME SHOULD BE THESE ON EXCEL FILE
       * 0,1)partnerInfo{id,name} alias partnerId/partnerName
       * 2)number 3)name 4)classification 5)type 6)country
       * 7,8,9)address{city,region,street} alias adCity,adRegion,adStreet
       * 10) oib
       * 11) gln
       * 12) code
       */
      return {
        reference: `${row[4]}-${uuidv4().split('-')[0]}`,
        partnerInfo: {
          id: row[1], // parnterId
          name: row[0] // partnerName
        },
        number: row[2],
        name: row[3],
        classification: row[4],
        type: row[5],
        country: row[6],
        address: {
          region: row[7],
          city: row[8],
          street: row[9]
        },
        created: Timestamp.now(),
        // ADDITIONAL FIELDS
        oib: row[10] || '',
        gln: row[11] || '',
        code: row[12] || '',
        glnKupca: row[13] || ''
      };
    });
    let batch = db.batch();
    rowsData.forEach(data => {
      const docRef = db.collection('horecasts').doc();
      batch.set(docRef, data);
    });
    await batch.commit();
    setImportSuccess(Math.random() * 1323);
    setLoading(false);
    return true;
  };

  const handleRowClick = async data => {
    if (
      userInfo.roles.includes('admin') ||
      userInfo.roles.includes('partner')
    ) {
      setEdit(data);
    }
  };
  return (
    <div className={classes.root}>
      <StoresToolbar
        deleteStores={deleteStoresHandler}
        editStore={isEdit}
        importHandler={importStoresHandler}
        isPartner={userInfo.userRole === 'partner'}
        isWorker={userInfo.userRole === 'worker'}
        loading={loading}
        refresh={updateStores}
        selectedStores={selectedStores}
        setEdit={setEdit}
      />
      <SearchInput onChange={e => setSearchTxt(e.target.value)} type="search" />
      <div className={classes.content}>
        <BasicTable
          data={stores}
          headers={tableHeaders}
          loading={loading}
          onRowClick={handleRowClick}
          selectedItems={selectedStores}
          setSelectedItems={setSelectedStores}
          skipCol={['partnerId', 'oib', 'gln', 'code', 'glnKupca']}
          withPagination
        />
      </div>

      {userInfo.userRole !== 'worker' && userInfo.userRole !== 'partner' && (
        <Card className={classes.exportContainer}>
          <ExportCSVButton
            csvData={stores}
            fileName={'stores'}
            name="Export"
            selectedItems={selectedStores}
          />
        </Card>
      )}
    </div>
  );
};

export default HORECASTList;
