import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography, Card, CardContent } from '@material-ui/core';
import { formattedDate, jsDate } from '../helpers/date';
import randomNumber from '../helpers/randomNumber';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1, 0),
    backgroundColor: '#fafafa',
    width: '100%',
    display: 'flex'
  },
  title: {
    fontSize: 14
  }
}));

const CommentsList = ({ comments }) => {
  const classes = useStyles();

  return comments
    .sort((a, b) => jsDate(b.created) - jsDate(a.created))
    .map(comment => {
      return (
        <Card className={classes.root} variant="outlined" key={randomNumber()}>
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              align="left"
              gutterBottom>
              {`${comment.author.username} | ${formattedDate(comment.created)}`}
            </Typography>
            <Typography variant="body1" component="p" align="left">
              {comment.body}
            </Typography>
          </CardContent>
        </Card>
      );
    });
};

export default CommentsList;
