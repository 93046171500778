import * as yup from 'yup';

//Yup schemas
const validationSchemas = {
  signInSchema: yup.object().shape({
    email: yup
      .string()
      .required('Required')
      .email('Invalid email'),
    password: yup
      .string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
  }),
  partnerSchema: yup.object().shape({
    partnerName: yup
      .string()
      .required('Required')
      .min(2, 'Too Short!'),
    partnerUsername: yup
      .string()
      .required('Required')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    email: yup
      .string()
      .email('Invalid email')
      .required('Required'),
    password: yup
      .string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  }),
  partnerUpdateSchema: yup.object().shape({
    partnerName: yup
      .string()
      .required('Required')
      .min(2, 'Too Short!'),
    partnerUsername: yup
      .string()
      .required('Required')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    email: yup
      .string()
      .email('Invalid email')
      .required('Required'),
    password: yup.string(),
    // .min(6, 'Password must be at least 6 characters'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  }),
  workerSchema: yup.object().shape({
    workerName: yup
      .string()
      .required('Required')
      .min(2, 'Too Short!'),
    workerUsername: yup
      .string()
      .required('Required')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    email: yup
      .string()
      .email('Invalid email')
      .required('Required'),
    password: yup
      .string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  }),
  workerUpdateSchema: yup.object().shape({
    workerName: yup
      .string()
      .required('Required')
      .min(2, 'Too Short!'),
    workerUsername: yup
      .string()
      .required('Required')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
    email: yup
      .string()
      .email('Invalid email')
      .required('Required'),
    password: yup.string(),
    // .min(6, 'Password must be at least 6 characters'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  }),
  submissionSchema: () =>
    yup.object().shape({
      store: yup.string().required('required'),
      partner: yup.string(),
      product: yup.string() //.oneOf([products], 'Please select a product')
    }),
  horecastSchema: yup.object().shape({
    storeNumber: yup.string().required('Required'),
    storeName: yup.string().required('Required'),
    country: yup.string().required('Required'),
    region: yup.string().required('Required'),
    city: yup.string().required('Required'),
    street: yup.string().required('Required'),
    type: yup.string().required('Required'),
    oib: yup.string(),
    gln: yup.string(),
    code: yup.string(),
    glnKupca: yup.string()
    /*     classification: yup
          .string()
          .required('Required'),
        type: yup
          .string()
          .required('Required'), */
  }),
  productSchema: yup.object().shape({
    productName: yup.string().required('Required'),
    productId: yup.string().required('Required'),
    productBarcode: yup.string().required('Required'),
    package: yup.string().required('Required')
  }),
  resetPasswordSchema: yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  }),
  updatePasswordSchema: yup.object().shape({
    currentPassword: yup
      .string()
      .required('Current Password is required')
      .min(6, 'Password must be at least 6 characters'),
    password: yup
      .string()
      .required('New Password is required')
      .min(6, 'New Password must be at least 6 characters'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  }),
  addQuestionSchema: yup.object().shape({
    question: yup
      .string()
      .required('Required')
      .min(10, 'Too short')
  })
};

export default validationSchemas;
