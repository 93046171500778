import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import QuestionsToolbar from './components/AssortmentToolbar';
import BasicTable from '../../../components/BasicTable';
import { db } from '../../../firebase';
import { deleteDocuments } from '../../../common/firebaseActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const AssortmentList = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const tableHeaders = [
    t('tableHeaders.partner'),
    t('tableHeaders.classification'),
    t('tableHeaders.assortment')
  ];

  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const { userId } = useSelector(state => state.auth);
  const userInfo = useSelector(state => state.auth.user);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const ASSORTMENT_COLLECTION = 'assortments'

  const fetchQuestions = async () => {
    try {
      let fetchedQuestions;

      setLoading(true);

      if (userInfo.roles[0] === 'admin') {
        fetchedQuestions = await (await db.collection(ASSORTMENT_COLLECTION).get()).docs;
      } else if (userInfo.roles[0] === 'partner') {
        fetchedQuestions = await (
          await db.collection(ASSORTMENT_COLLECTION)
            .where('partnerInfo.id', '==', userId)
            .get()).docs;
      }

      const formattedQuestions = fetchedQuestions.map(doc => ({
        id: doc.id,
        partner: doc.data().partnerInfo.name,
        classification: doc.data().classification,
        assortment: doc.data().assortment
      }));

      setQuestions(formattedQuestions);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('Something wen wrong!', err);
    }
  };

  const deleteQuestionsHandler = () => {
    return deleteDocuments(ASSORTMENT_COLLECTION, selectedQuestions);
  };

  const updateQuestions = () => {
    setSelectedQuestions([]);
    fetchQuestions();
  };

  return (
    <div className={classes.root}>
      <QuestionsToolbar
        deleteQuestions={deleteQuestionsHandler}
        refresh={updateQuestions}
        selectedQuestions={selectedQuestions}
      />
      <div className={classes.content}>
        <BasicTable
          data={questions}
          headers={tableHeaders}
          loading={loading}
          selectedItems={selectedQuestions}
          setSelectedItems={setSelectedQuestions}
          small
          withPagination
        />
      </div>
    </div>
  );
};

export default AssortmentList;
