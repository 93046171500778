import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Fab } from '@material-ui/core';

import { ExcelRenderer } from 'react-excel-renderer';
import CircularProgress from '@material-ui/core/CircularProgress';

// eslint-disable-next-line react/prop-types
const ImportTableExcel = ({ importHandler, loading, onSuccess }) => {
  const fileHandler = event => {
    const {
      target: { files }
    } = event;
    if (!files || !files[0]) {
      return false;
    }
    const fileList = files[0];

    let fileObj = fileList;
    if (!fileObj) {
      return false;
    }

    if (
      fileObj.type === 'application/vnd.ms-excel' ||
      fileObj.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      //just pass the fileObj as parameter
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          console.log(err);
        } else {
          if (resp.rows.length === 0) {
            console.log(' there is no row-ExcelRenderer');
            return false;
          } else {
            importHandler([
              ...resp.rows.filter(arrOfRowData =>
                arrOfRowData.some(str => str.length > 0)
              )
            ]);
            onSuccess();
            return;
          }
        }
      });
      return false;
    } else {
      return false;
    }
  };

  return (
    <div style={{ margin: '12px' }}>
      <label htmlFor={loading ? '' : 'import-excel'}>
        <input
          disabled={loading}
          id="import-excel"
          multiple={false}
          name="import-excel"
          onClick={event => {
            event.target.value = null;
          }}
          onInput={fileHandler}
          style={{ display: 'none' }}
          type="file"
        />
        <Fab
          aria-label="add"
          color="secondary"
          component="span"
          size="small"
          variant="extended">
          {loading ? (
            <CircularProgress color="primary" size={25} />
          ) : (
            <AddIcon />
          )}
          Import Data
        </Fab>
      </label>
    </div>
  );
};
export default ImportTableExcel;
