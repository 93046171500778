/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import { 
  makeStyles,
  InputAdornment,
  IconButton,
  OutlinedInput,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  ListItemIcon,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import styled from 'styled-components'

import Alert from '../../../components/BasicAlert';
import BasicTextField from '../../../components/BasicTextField';
import BasicSelect from '../../../components/BasicSelect';
import AutocompleteInput from '../../../components/AutoCompleteInput';
import BasicButtonWithSpinner from '../../../components/BasicButtonWithSpinner';
import validationSchemas from '../../../common/validationSchemas';
import BasicModal from '../../../components/BasicModal';
import { db } from '../../../firebase';
import randomNumber from 'helpers/randomNumber';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    backgroundColor: theme.palette.white,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  }
}));

const AddQuestion = ({ handleClose, modalOpen, onSuccess, refresh }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [questionOption, setQuestionOption] = useState('')
  const [questionOptions, setQuestionOptions] = useState([])

  const [classification, setClassification] = useState('Store');
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const { userId } = useSelector(state => state.auth);
  const userInfo = useSelector(state => state.auth.user);

  const inputLabel = useRef(null);

  const { register, handleSubmit, errors, formState } = useForm({
    validationSchema: validationSchemas.addQuestionSchema,
    mode: 'onChange'
  });

  useEffect(() => {
    if (userInfo.roles[0] === 'admin') {
      (async () => {
        const fetchedPartners = await (
          await db.collection('partners').get()
        ).docs.map(partner => ({ id: partner.id, label: partner.data().name }));
        setPartners(fetchedPartners);
      })();
    } else if (userInfo.roles[0] === 'partner') {
      setSelectedPartner({ id: userId, name: userInfo.name });
    }
  }, []);

  const partnerSelectedHandler = (e, value) => {
    if(value){
      setSelectedPartner({ id: value.id, name: value.label });
    }
  };

  const addQuestionHandler = async data => {
    const questionData = {
      question: data.question,
      classification: classification.toLowerCase(),
      partnerInfo: selectedPartner
    };
    setErrorMessage(null);
    setLoading(true);
    try {
      await db.collection('questions').add(questionData);
      setLoading(false);
      handleClose();
      refresh();
      onSuccess();
    } catch (err) {
      console.log(err);
      setLoading(false);
      setErrorMessage(t('notifications.servererror'));
    }
  };

  const addQuestionOptionHandler = () => {
    if(questionOption !== '') {
      const qo = [...questionOptions, questionOption]

      setQuestionOption('')
      setQuestionOptions(qo)
    }
  }

  return (
    <BasicModal
      actions={[]}
      loading={loading}
      modalOpen={modalOpen}
      onCloseModal={handleClose}
      subtitle={t('addQuestionForm.subtitle')}
      title={t('addQuestionForm.title')}
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit(addQuestionHandler)}
      >
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {userInfo.roles[0] === 'admin' && (
          <AutocompleteInput
            className={classes.textField}
            errorMessage={errors?.partner?.message}
            errors={errors.partner}
            label={t('addQuestionForm.partner')}
            margin="dense"
            name="partner"
            onOptionSelected={partnerSelectedHandler}
            register={register}
            suggestions={partners}
          />
        )}

        <BasicSelect
          inputRef={inputLabel}
          label={t('addQuestionForm.classification')}
          name="classification"
          options={['Store', 'HORECA']}
          setValue={setClassification}
          value={classification}
        />

        <BasicTextField
          className={classes.input}
          error={errors.question}
          fullWidth
          label={t('addQuestionForm.question')}
          margin="dense"
          name="question"
          register={register}
          type="text"
          variant="outlined"
        />

        <OutlinedInput
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={addQuestionOptionHandler}
              >
                <AddIcon color="primary" />
              </IconButton>
            </InputAdornment>
          }
          fullWidth
          inputProps={{label: t('addQuestionForm.options')}}
          margin="dense"
          onChange={({target}) => setQuestionOption(target.value)}
          placeholder={t('addQuestionForm.options')}
          style={{marginTop: '12px', marginBottom: '12px'}}
          type="text"
          value={questionOption}
        />
        <Divider/>
        <Typography>{t('addQuestionForm.options')}</Typography>

        <List>
          {questionOptions.map(option => <ListItem key={randomNumber()}>
            <ListItemIcon>
              <ArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary={option} />
          </ListItem>)}
        </List>

        <ButtonContainer>
          <BasicButtonWithSpinner
            disabled={!formState.isValid}
            isLoading={loading}
            label={t('button.addQuestion')}
          />
        </ButtonContainer>
      </form>
    </BasicModal>
  );
};

export default AddQuestion;
