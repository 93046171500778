import axios from 'axios';
import React from 'react';
const Tester = () => {
  const send = () => {
    axios
      .post(
        'https://us-central1-papillon-trade-app.cloudfunctions.net/onSendXML',
        {
          text: 'aliiiii'
        }
      )
      .then(result => {
        console.log('result: ', result);
      })
      .catch(error => {
        // Getting the Error details.
        const code = error.code;
        console.log('Error: ', error.message);
        console.log('err-code: ', code);
      });
  };

  return (
    <div>
      <p>Tester</p>
      <button onClick={send}>send</button>
    </div>
  );
};

export default Tester;
