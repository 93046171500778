                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
//AUTH
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

//FILTERS
export const APPLY_FILTER = 'APPLY_FILTER'
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const SET_FILTERED_DATA = 'SET_FILTERED_DATA'

export const APPLY_RETURNS_FILTER = 'APPLY_RETURNS_FILTER'
export const CLEAR_RETURNS_FILTER = 'CLEAR_RETURNS_FILTER'
export const SET_RETURNS_FILTERED_DATA = 'SET_RETURNS_FILTERED_DATA'


//PARTNERS
//create
export const CREATE_PARTNER_START = 'CREATE_PARTNER_START';
export const CREATE_PARTNER_SUCCESS = 'CREATE_PARTNER_SUCCESS';
export const CREATE_PARTNER_FAIL = 'CREATE_PARTNER_FAIL';
//fetch
export const FETCH_PARTNERS_START = 'FETCH_PARTNERS_START';
export const FETCH_PARTNERS_SUCCESS = 'FETCH_PARTNERS_SUCCESS';
export const FETCH_PARTNERS_FAIL = 'FETCH_PARTNERS_FAIL';


//WORKERS
//create
export const CREATE_WORKER_START = 'CREATE_WORKER_START';
export const CREATE_WORKER_SUCCESS = 'CREATE_WORKER_SUCCESS';
export const CREATE_WORKER_FAIL = 'CREATE_WORKER_FAIL';
//fetch
export const FETCH_WORKERS_START = 'FETCH_WORKERS_START';
export const FETCH_WORKERS_SUCCESS = 'FETCH_WORKERS_SUCCESS';
export const FETCH_WORKERS_FAIL = 'FETCH_WORKERS_FAIL';


//STORES & HORECA
//create
export const CREATE_STORE_START = 'CREATE_STORE_START';
export const CREATE_STORE_SUCCESS = 'CREATE_STORE_SUCCESS';
export const CREATE_STORE_FAIL = 'CREATE_STORE_FAIL';
//fetch
export const FETCH_STORES_START = 'FETCH_STORES_START';
export const FETCH_STORES_SUCCESS = 'FETCH_STORES_SUCCESS';
export const FETCH_STORES_FAIL = 'FETCH_STORES_FAIL';


//PRODUCTS
//create
export const CREATE_PRODUCT_START = 'CREATE_PRODUCT_START';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL';
//fetch
export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAIL = 'FETCH_PRODUCTS_FAIL';


//SUBMISSIONS
//create
export const CREATE_SUBMISSION_START = 'CREATE_SUBMISSION_START';
export const CREATE_SUBMISSION_SUCCESS = 'CREATE_SUBMISSION_SUCCESS';
export const CREATE_SUBMISSION_FAIL = 'CREATE_SUBMISSION_FAIL';
//fetch
export const FETCH_SUBMISSIONS_START = 'FETCH_SUBMISSIONS_START';
export const FETCH_SUBMISSIONS_SUCCESS = 'FETCH_SUBMISSIONS_SUCCESS';
export const FETCH_SUBMISSIONS_FAIL = 'FETCH_SUBMISSIONS_FAIL';

// table page
export const SET_TABLE_PAGE = 'SET_TABLE_PAGE'



