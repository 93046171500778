import React from 'react';
import { Link } from 'react-router-dom'
import './NotFound.css'

const NotFound = () => {

  return (
    <div className="content">
      <h1>404</h1>
      <p>Oops! Something is wrong.</p>
      <Link to="/" className="button">Go back in initial page, is better.</Link>
    </div>
  );
};

export default NotFound;
