import React from 'react';
import { TextField, CircularProgress, Typography } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { makeStyles } from '@material-ui/core';

import authInstance from '../../../axiosInstance';
import BasicModal from '../../../components/BasicModal';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 0,
    display: 'inline'
  },
  helper: {
    color: theme.palette.primary.dark,
    display: 'inline',
    cursor: 'pointer'
  }
}));

export default function FormDialog({ label }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [loading, setloading] = React.useState(false);
  const [isSent, setIsSent] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    setloading(true);
    authInstance('sendOobCode')
      .post('', { email, requestType: 'PASSWORD_RESET' })
      .then(response => {
        setIsSent(true);
        setloading(false);
      })
      .catch(error => {
        setEmail('');
        setloading(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  let modalContent = (
    <TextField
      autoFocus
      margin="dense"
      label="Email Address"
      type="email"
      variant="outlined"
      fullWidth
      value={email}
      onChange={e => setEmail(e.target.value)}
    />
  );

  if (loading) modalContent = <CircularProgress />;
  if (!loading && isSent)
    modalContent = (
      <>
        <MailOutlineIcon color="primary" fontSize="large" />
        <Typography color="textSecondary" gutterBottom>
          Reset password link was sent, please check your inbox.
        </Typography>
      </>
    );

  return (
    <span className={classes.root}>
      <span onClick={handleClickOpen} className={classes.helper}>
        {label}
      </span>
      <BasicModal
        title="Forgot Password"
        subtitle={
          !loading &&
          !isSent &&
          'Please enter your email address here to get a reset link.'
        }
        onCloseModal={handleClose}
        modalOpen={open}
        actions={[{ label: 'Send', handler: handleSubmit, color: 'primary' }]}>
        {modalContent}
      </BasicModal>
    </span>
  );
}
