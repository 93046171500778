import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import { Paper, TextField, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import ImportCSVButton from '../../helpers/ImportTableExcel';
import Loader from '../../components/Loader';
import PlanModal from './PlanModal';
import { db } from '../../firebase';
import { fetchWorkers, fetchStores, fetchSchedule } from './fetchers';
import { saveRoutes } from './savers';
import { getMinMaxTimeSlot, getAllDatesInMonth } from '../../helpers/date';
import ExportCSVButton from '../../helpers/ExportCSV';

import 'react-big-calendar/lib/css/react-big-calendar.css';

moment.locale('hr');
const localizer = momentLocalizer(moment);

const views = [Views.MONTH, Views.WORK_WEEK, Views.AGENDA];
const minTime = new Date();
minTime.setMinutes(0);
minTime.setHours(8);

const maxTime = new Date();
maxTime.setMinutes(0);
maxTime.setHours(18);

const BigCalendar = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [open, toggleModal] = useState(false);

  const [workers, setWorkers] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedWorker, setWorker] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [computedMonths, setComputedMonths] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const { userId } = useSelector(state => state.auth);
  const userRole = useSelector(state => state.auth.user.roles[0]);
  const username = useSelector(state => state.auth.user.username);
  const userInfo = useSelector(state => ({
    ...state.auth.user,
    userRole: state.auth.user.roles[0]
  }));

  const getTitleFromStoreId = storeId => {
    const store = stores.find(store => store.id === storeId);
    if (!store) return storeId;

    const title = `${store.number}-${store.name}-${store.city}-${store.street}`;
    return title;
  };

  const workersRef = () => {
    if (userRole === 'admin') {
      // fetch all workers for admin
      return db.collection('workers');
    } else if (userRole === 'partner' && username === 'pikrijeka') {
      // fetch workers with prop isPikrijeka for pikrijeka partner
      return db.collection('workers').where('isPikrijeka', '==', true);
    } else if (userRole === 'partner') {
      // fetch workers with partner id
      return db.collection('workers').where('partnerInfo.id', '==', userId);
    }
  };

  const showSuccessMessage = () => {
    enqueueSnackbar(t('notifications.routePlanAdded'), {
      variant: 'success'
    });
  };

  const importHandler = rows => {
    const data = rows.slice(1);
    saveRoutes(data, db, setLoading, showSuccessMessage);
  };

  const createPlanHandler = plan => {
    const pp = {};

    plan.forEach(item => {
      const i = item;
      if (pp[item.day]) {
        i.order = pp[item.day].length + 1;
        pp[item.day].push(i);
      } else {
        i.order = 1;
        pp[item.day] = [i];
      }
    });

    const flattenPP = Object.values(pp);
    const inputArray = [];

    flattenPP.forEach(arr =>
      arr.forEach(item => {
        inputArray.push([
          item.store.id,
          selectedWorker.id,
          item.day,
          item.order,
          item.repeat[0]
        ]);
      })
    );

    saveRoutes(inputArray, db, setLoading, showSuccessMessage);
  };

  const selectWorkerHandler = workerInfo => {
    setWorker(workerInfo);
    if (!workerInfo) return;

    fetchSchedule(workerInfo.id, db, setSchedule);
  };

  const createEvents = (monthDate = new Date()) => {
    const allEventsOfMonth = [];
    const visitPerDay = {};

    if (schedule) {
      schedule.plan.forEach(({ day }) => {
        visitPerDay[day] = visitPerDay[day] ? visitPerDay[day] + 1 : 1;
      });

      schedule.plan.forEach(({ day, storeId, repeat, order }) => {
        const datesOfMonth = getAllDatesInMonth(day, monthDate);

        datesOfMonth.forEach((date, idx) => {
          if (repeat[idx] === 1) {
            const [mind, maxd] = getMinMaxTimeSlot(date, order);
            const dayEvent = {
              start: mind,
              end: maxd,
              title: getTitleFromStoreId(storeId)
            };

            allEventsOfMonth.push(dayEvent);
          }
        });
      });
      return allEventsOfMonth;
    } else {
      return [];
    }
  };

  const onNavigateHandler = date => {
    const entry = `${date.getFullYear()}${date.getMonth()}`;

    if (!computedMonths.includes(entry)) {
      const newEvents = createEvents(date);
      setEvents([...events, ...newEvents]);

      if (newEvents.length !== 0) {
        const newCm = [...computedMonths, entry];
        setComputedMonths(newCm);
      }
    }
  };

  useEffect(() => {
    fetchWorkers(username, setLoading, setWorkers, workersRef);
    fetchStores(userInfo, userId, setLoading, setStores, db);

    if (userRole === 'worker') {
      fetchSchedule(userId, db, setSchedule);
    }
  }, []);

  useEffect(() => {
    onNavigateHandler(new Date());
  }, [schedule]);

  const getScheduleRows = () => {
    const rows = schedule.plan.map(plan => {
      return {
        Naziv: plan.storeId,
        PUTNIK: schedule.workerId,
        DAN: plan.day,
        order: plan.order,
        'TJ 1': plan.repeat[0],
        'TJ 2': plan.repeat[1],
        'TJ 3': plan.repeat[2],
        'TJ 4': plan.repeat[3]
      };
    });
    return rows;
  };

  if (loading) return <Loader />;

  const notWorker = userRole !== 'worker';

  return (
    <div>
      <PlanModal
        stores={stores}
        createPlan={createPlanHandler}
        open={open}
        handleClose={() => toggleModal(false)}
      />
      {notWorker && (
        <Paper className="calendar-toolbar">
          <Autocomplete
            getOptionLabel={option => option.name}
            id="workers"
            onChange={(_, value) => selectWorkerHandler(value)}
            options={workers}
            renderInput={params => (
              <TextField
                {...params}
                label="Select Worker"
                size="small"
                variant="outlined"
              />
            )}
            style={{ width: 300 }}
          />
          <div className="buttons-wrapper">
            <ImportCSVButton
              importHandler={importHandler}
              loading={false}
              onSuccess={() =>
                enqueueSnackbar(t('notifications.routePlanImported'), {
                  variant: 'success'
                })
              }
            />
            {selectedWorker && schedule && (
              <>
                <ExportCSVButton
                  csvData={getScheduleRows()}
                  fileName={`route_plan_${selectedWorker.name.replace(
                    ' ',
                    '_'
                  )}`}
                  name="Export"
                  selectedItems={[]}
                />
              </>
            )}
          </div>
        </Paper>
      )}
      <Paper className="calendar-wrapper">
        {((selectedWorker && schedule) || !notWorker) && (
          <Calendar
            defaultView={Views.WORK_WEEK}
            endAccessor="end"
            events={events}
            formats={{ timeGutterFormat: d => `${d.getHours() + 1}` }}
            localizer={localizer}
            onNavigate={onNavigateHandler}
            onSelectSlot={() => console.log('onSelectSlot')}
            selectable
            startAccessor="start"
            step={60}
            timeslots={1}
            views={views}
          />
        )}
        {!selectedWorker && notWorker && (
          <h3>Select a worker from the list to see calendar</h3>
        )}
        {selectedWorker && !schedule && (
          <div className="flex-between">
            <h3>This worker does not have a schedule</h3>
            <Button
              variant="contained"
              color="primary"
              onClick={() => toggleModal(true)}>
              Create
            </Button>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default BigCalendar;
