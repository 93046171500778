import React from 'react';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  addButton: {
    backgroundColor: theme.palette.primary.main,
    marginLeft: 5,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  label: {
    display: 'inline',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
}));

const BasicFab = ({ label, handleClick, remove }) => {
  const classes = useStyles();
  return (
    <Fab
      className={remove ? classes.deleteButton : classes.addButton}
      variant="extended"
      size="small"
      onClick={handleClick}>
      <span className={classes.label}>{label}</span>{' '}
      {remove ? <DeleteIcon /> : <AddIcon />}
    </Fab>
  );
};
export default BasicFab;
