import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
import validationSchemas from '../../../common/validationSchemas';
import authInstance from '../../../axiosInstance';
import * as actionTypes from '../../../store/actions/actionTypes';
// import { checkAuthTimeout } from 'store/actions';
import Alert from '../../../components/BasicAlert';
import BasicButtonWithSpinner from '../../../components/BasicButtonWithSpinner';
import ForgotPassword from '../ForgotPassword';
import { db } from '../../../firebase';
import landingImage from '../../../assets/images/papillon_trade.png';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${landingImage}),linear-gradient(180deg,#00897b 0%,#0C71C3 100%)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
    display: 'none'
  },
  quoteText: {
    color: theme.palette.grey,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  }
}));

const SignIn = props => {
  const { history } = props;

  const isLoading = useSelector(state => state.auth.loading);
  const authErrorMessage = useSelector(state => state.auth.error);
  const isLoggedIn = useSelector(state => state.auth.token !== null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/submissions');
    }
  }, []);

  const handleBack = () => {
    history.goBack();
  };

  const { register, handleSubmit, errors, formState } = useForm({
    validationSchema: validationSchemas.signInSchema,
    mode: 'onChange'
  });

  const setupUserParams = (authData, userData) => {
    const expirationDate = new Date(
      new Date().getTime() + authData.expiresIn * 1000
    );
    localStorage.setItem('token', authData.idToken);
    localStorage.setItem('expirationDate', expirationDate);
    localStorage.setItem('userId', authData.localId);
    localStorage.setItem(
      'user',
      JSON.stringify({ ...userData, email: authData.email })
    );
    dispatch({
      type: actionTypes.AUTH_SUCCESS,
      idToken: authData.idToken,
      userId: authData.localId,
      user: { ...userData, email: authData.email }
    });
    // dispatch(checkAuthTimeout(authData.expiresIn));
  };

  const onSignIn = authData => {
    const userAuthData = {
      ...authData,
      returnSecureToken: true
    };

    dispatch({ type: actionTypes.AUTH_START });
    authInstance('signInWithPassword')
      .post('', userAuthData)
      .then(async response => {
        try {
          let userData = await db
            .collection('users')
            .doc(response.data.localId)
            .get();
          if (!userData.exists) {
            userData = await db
              .collection('partners')
              .doc(response.data.localId)
              .get();
            if (!userData.exists) {
              userData = await db
                .collection('workers')
                .doc(response.data.localId)
                .get();
            }
          }
          setupUserParams(response.data, userData.data());
          history.push('/submissions');
        } catch (err) {
          dispatch({
            type: actionTypes.AUTH_FAIL,
            error: err.response.data.error
          });
        }
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type: actionTypes.AUTH_FAIL,
          error: { message: 'Username_or_Password_invalid!' }
        });
      });
  };
  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                Papillon Trade - Your merchant traveler
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1">
                  Papillon Trade d.o.o. is a specialist travel business company,
                  and the services it offers are for businesses and its branded
                  products that are in stores.
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={6} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                autoComplete="new-era-form"
                onSubmit={handleSubmit(onSignIn)}>
                <Typography className={classes.title} variant="h2">
                  {t('signin.title')}
                </Typography>
                <Typography color="textSecondary" align="left" gutterBottom>
                  {t('signin.subtitle')}
                </Typography>
                {authErrorMessage && (
                  <Alert severity="error">
                    {authErrorMessage.message.replace(/_/g, ' ')}
                  </Alert>
                )}
                <TextField
                  className={classes.textField}
                  label={t('signin.email')}
                  type="text"
                  name="email"
                  fullWidth
                  variant="outlined"
                  size="small"
                  autoComplete="new-pt"
                  error={!!errors.email}
                  inputRef={register}
                  helperText={errors.email && errors.email.message}
                />
                <TextField
                  className={classes.textField}
                  label={t('signin.password')}
                  type="password"
                  name="password"
                  fullWidth
                  variant="outlined"
                  size="small"
                  error={!!errors.password}
                  inputRef={register}
                  helperText={errors.password && errors.password.message}
                />
                <div className={classes.buttonWrapper}>
                  <BasicButtonWithSpinner
                    disabled={!formState.isValid}
                    label={t('signin.title')}
                    isLoading={isLoading}
                  />
                </div>
                <Typography color="textSecondary" variant="body1">
                  {t('signin.forgot')} (
                  <ForgotPassword label={t('signin.reset')} />)
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
