/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import {
  Typography,
  Divider,
  Grid,
  Card,
  CardContent,
  Box,
  GridList,
  GridListTile,
  IconButton,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'

import { formattedDate, fbDate } from '../../../helpers/date';
import { db, firestore } from '../../../firebase';
import CommentInput from '../../../components/CommentInput';
import CommentsList from '../../../components/CommentsList';
import Carousel from '../../../components/Carousel';
import Table from '../../../components/Table';

const notificationUrl =
  'https://us-central1-papillon-trade-app.cloudfunctions.net/sendgrid/nodemailer/notification';

const useStyles = makeStyles(theme => ({
  box: {
    margin: theme.spacing(3)
  },
  title: {
    marginTop: theme.spacing(1),
    textTransform: 'uppercase',
    width: '100%',
    display: 'block'
  },
  divider: {
    backgroundColor: theme.palette.divider,
    marginTop: theme.spacing(1),
    width: '100%'
  },
  card: {
    display: 'flex'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flex: '1 0 auto'
  },
  value: {
    margin: theme.spacing(0.5, 0, 0, 1)
  },
  gridList: {
    padding: theme.spacing(1)
  },
  gridListImageWrapper: {
    padding: theme.spacing(0.5),
    '&:hover': {
      border: '1px solid #3F51B5',
      cursor: 'pointer'
    }
  },
  commentsSection: {
    backgroundColor: theme.palette.white,
    width: '100%',
    padding: theme.spacing(0, 2)
  }
}));

const TitleValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
`
const Title = styled(Typography)`
margin-right: 8px;
text-transform: capitalize;
`
const Value = styled(Typography)`
  font-weight: bold;
`

const Submission = ({ history, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), { defaultMatches: true });
  const [submission, setSubmission] = useState(null);
  const [comment, setComment] = useState('');
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const { userId } = useSelector(state => state.auth);
  const { username, avatar, roles } = useSelector(state => state.auth.user);
  const [isNotFound, setIsNotFound] = useState(false);
  const [imageActiveIndex, setImageActiveIndex] = useState(0);
  const {
    match: { params: { id: paramId } }
  } = rest;

  useEffect(() => {
    (async () => {
      if (!paramId) {
        return;
      }

      const ref = paramId;

      const fetchedSubmission = await (
        await db.collection('submissions').where('reference', '==', ref).get()
      ).docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setIsNotFound(fetchedSubmission.length === 0);
      setSubmission(fetchedSubmission[0]);
    })();
  }, [paramId]);

  const handleSubmitComment = async () => {
    setloading(true);
    const commentData = {
      created: fbDate(new Date()),
      body: comment,
      author: { id: userId, username, avatar }
    };
    //update locally
    const comments = submission.comments;
    comments.push(commentData);
    setSubmission({ ...submission, comments });

    //Send email with notif
    const destEmail = async () => {
      if (roles[0] === 'worker') {
        const partnerData = await (
          await db
            .collection('partners')
            .doc(submission.partnerInfo.id)
            .get()
        ).data();
        let partnerEmailList = [submission.partnerInfo.email];
        if (partnerData.otherEmails) {
          partnerEmailList = [...partnerEmailList, ...partnerData.otherEmails];
        }
        return partnerEmailList;
      } else if (roles[0] === 'partner') {
        return submission.workerInfo.email;
      }
    };
    const destName = () => {
      if (roles[0] === 'worker') {
        return submission.partnerInfo.name;
      } else if (roles[0] === 'partner') {
        return submission.workerInfo.name;
      }
    };

    const lang = localStorage.getItem('i18nextLng');
    let queryLang = lang ? `?lang=${lang}` : '';

    const destinationEmails = await destEmail();
    
    axios
      .post(`${notificationUrl}${queryLang}`, {
        to: destinationEmails,
        comment: comment,
        author: username,
        dest: destName(),
        link: `https://app.papillon-trade.hr/submissions/${submission.reference}`
      })
      .then(resp => console.log(resp))
      .catch(err => console.log(err));

    //update on backend
    const subToUpdate = db.collection('submissions').doc(submission.id);
    subToUpdate
      .update({
        comments: firestore.FieldValue.arrayUnion(commentData)
      })
      .then(() => {
        setComment('');
        setloading(false);
      })
      .catch(err => {
        console.log(err);
        setloading(false);
      });
  };

  const handleBack = () => {
    history.goBack();
  };

  const imgSrcReducer = arr => {
    if (arr) return arr.map(src => ({ src }));
    else return [];
  };

  const onImageViewHandler = index => {
    setOpen(true);
    setImageActiveIndex(index);
  };

  if (!paramId || isNotFound) {
    return <Redirect to="/not-found" />;
  }

  let content = null;

  if (submission) {
    content = (
      <Box
        className={classes.box}
        spacing={3}
      >
        {open && <Carousel
          handleClose={() => setOpen(false)}
          images={imgSrcReducer(submission.images)}
          title={submission.reference}
        />}
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              className={classes.title}
              variant="h4"
            >
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
              {t('submission.title')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography
              className={classes.title}
              variant="h6"
            >
              {t('submission.details')} <b>({submission.reference})</b>
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            item
            lg={2}
            sm={6}
            xs={12}
          >
            <Card className={classes.card}>
              <div className={classes.details}>
                <CardContent className={classes.cardContent}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                  >
                    {t('submission.created')}:
                  </Typography>
                  <Typography
                    className={classes.value}
                    component="h6"
                    variant="h6"
                  >
                    {formattedDate(submission.created)}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid
            item
            lg={4}
            sm={6}
            xs={12}
          >
            <Card className={classes.card}>
              <div className={classes.details}>
                <CardContent className={classes.cardContent}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                  >
                    {t('submission.store')}:
                  </Typography>
                  <Typography
                    className={classes.value}
                    component="h6"
                    variant="h6"
                  >
                    {submission.storeInfo.number} - {submission.storeInfo.name}{' '}
                    - {submission.storeInfo.type} -{' '}
                    {submission.storeInfo.address.city} -{' '}
                    {submission.storeInfo.address.street}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xs={12}
          >
            <Card className={classes.card}>
              <div className={classes.details}>
                <CardContent className={classes.cardContent}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                  >
                    {t('submission.partner')}:
                  </Typography>
                  <Typography
                    className={classes.value}
                    component="h6"
                    variant="h6"
                  >
                    {submission.partnerInfo.name}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xs={12}
          >
            <Card className={classes.card}>
              <div className={classes.details}>
                <CardContent className={classes.cardContent}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                  >
                    {t('submission.worker')}:
                  </Typography>
                  <Typography
                    className={classes.value}
                    component="h6"
                    variant="h6"
                  >
                    {submission.workerInfo.username}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Table
              data={submission.products}
              readOnly
            />
          </Grid>

          <Grid
            item
            lg={6}
            sm={6}
            xs={12}
          >
            <Card className={classes.card}>
              <div className={classes.details}>
                <CardContent className={classes.cardContent}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                  >
                    {t('submission.questions')}:
                  </Typography>
                  {submission.questions.map(q => {
                    return (
                      <Typography
                        component="h5"
                        key={q.id}
                        variant="h5"
                      >
                        {q.checked ? (
                          <CheckIcon style={{ color: '#00e676' }} />
                        ) : (
                          <CloseIcon style={{ color: '#ff5722' }} />
                        )}
                        {q.label}
                      </Typography>
                    );
                  })}
                </CardContent>
              </div>
            </Card>
          </Grid>

          {submission.assortments &&
          <Grid
            item
            lg={6}
            sm={6}
            xs={12}
          >
            <Card className={classes.card}>
              <div className={classes.details}>
                <CardContent className={classes.cardContent}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                  >
                    {t('menu.assortments')}:
                  </Typography>
                  {submission.assortments && submission.assortments.map(({label, answer}) => {
                    return (<TitleValue key={label}>
                      <Title variant="subtitle2">{label}:</Title>
                      <Value variant="subtitle2">{answer}</Value>
                    </TitleValue>);
                  })}
                </CardContent>
              </div>
            </Card>
          </Grid>
          }

          <Grid
            item
            lg={6}
            sm={6}
            xs={12}
          >
            <Card className={classes.card}>
              <div className={classes.details}>
                <CardContent className={classes.cardContent}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                  >
                    {t('submission.description')}:
                  </Typography>
                  <Typography
                    className={classes.value}
                    component="h5"
                    variant="h5"
                  >
                    {submission.description !== ''
                      ? submission.description
                      : t('submission.nodescription')}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Typography
              className={classes.title}
              variant="h6"
            >
              {t('submission.images')}:
            </Typography>
          </Grid>
          <Divider className={classes.divider} />

          <GridList
            cellHeight={160}
            className={classes.gridList}
            cols={isDesktop ? 3 : 2}
          >
            {submission.images.map((file, index) => (
              <GridListTile
                className={classes.gridListImageWrapper}
                cols={1}
                key={Math.random()}
              >
                <img
                  alt="submission_attachment"
                  onClick={() => onImageViewHandler(index)}
                  src={file}
                />
              </GridListTile>
            ))}
          </GridList>

          <Grid
            item
            xs={12}
          >
            <Typography
              className={classes.title}
              variant="h6"
            >
              {t('submission.comments')}:
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
          <Box className={classes.commentsSection}>
            <CommentInput
              handleChange={setComment}
              handleSubmit={handleSubmitComment}
              loading={loading}
              value={comment}
            />
            <Divider variant="middle" />
            <CommentsList comments={submission.comments} />
          </Box>
        </Grid>
      </Box>
    );
  }

  return content;
};

export default withRouter(Submission);
