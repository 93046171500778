import { Timestamp } from '../firebase';
import moment from 'moment';

const slots = [
  [0, 1],
  [1, 2],
  [2, 3],
  [3, 4],
  [4, 5],
  [5, 6],
  [6, 7],
  [7, 8],
  [8, 9],
  [9, 10],
  [10, 11],
  [11, 12],
  [12, 13],
  [13, 14],
  [14, 15],
  [15, 16],
  [16, 17],
  [17, 18],
  [18, 19],
  [19, 20]
];

export const formattedDate = date => {
  const getDate = moment(date.toDate())
    .format('DD-MM-YYYY')
    .toString();
  const getTime = moment(date.toDate())
    .format('HH:mm')
    .toString()
    .replace(' ', '');
  return `${getDate} ${getTime}`;
};

export const formattedDateWithMoment = date => {
  const getDate = moment(date.toDate())
    .format('DD-MM-YYYY')
    .toString();
  const getTime = moment(date.toDate())
    .format('HH:mm')
    .toString()
    .replace(' ', '');
  return `${getDate} ${getTime}`;
};
export const fbDate = date => Timestamp.fromDate(date);

export const jsDate = date => new Date(date.toDate());

export const getMinMaxTimeSlot = (date, order) => {
  const mind = new Date(date);
  const maxd = new Date(date);
  const eventOrder = parseInt(order);
  const slot = slots[eventOrder - 1];

  mind.setHours(slot[0]);
  mind.setMinutes(0);
  mind.setSeconds(0);

  maxd.setHours(slot[1]);
  maxd.setMinutes(0);
  maxd.setSeconds(0);

  return [mind, maxd];
};

export const getAllDatesInMonth = (day, dateOfMonth) => {
  const dates = [];
  const d = new Date(dateOfMonth);
  d.setDate(1);

  // Get the first Monday in the month
  while (d.getDay() !== day) {
    d.setDate(d.getDate() + 1);
  }

  // Get all the other Mondays in the month
  while (d.getMonth() === dateOfMonth.getMonth()) {
    dates.push(new Date(d.getTime()));
    d.setDate(d.getDate() + 7);
  }
  return dates;
};
