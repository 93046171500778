import React from 'react';
import { useSelector } from 'react-redux';

import Calendar from '../../components/Calendar';

export default function RoutePlanner() {
  const userRole = useSelector(state => state.auth.user.roles[0]);
  const notWorker = userRole !== 'worker';

  return <Calendar notWorker={notWorker} />;
}
