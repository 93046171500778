import * as actionTypes from './actionTypes';
import { browserHistory } from '../../App';
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, userId, user) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
    user: user
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('userId');
  localStorage.removeItem('user');
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
      browserHistory.push("/sign-in");
    }, expirationTime * 1000);
  };
};

export const setAuthRedirectPath = path => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(logout());
      if(browserHistory.location.pathname === '/')
      {
        browserHistory.push("/sign-in");
      }
    } else {
     
      const userId = localStorage.getItem('userId');
      const user = JSON.parse(localStorage.getItem('user'))
      dispatch(authSuccess(token, userId, user));
     
      // const expirationDate = new Date(localStorage.getItem('expirationDate'));
      // if (expirationDate <= new Date()) {
      //   dispatch(logout());
      //   browserHistory.push("/sign-in");
      // } else {
      //   const userId = localStorage.getItem('userId');
      //   const user = JSON.parse(localStorage.getItem('user'))
      //   dispatch(authSuccess(token, userId, user));
      //   dispatch(
      //     checkAuthTimeout(
      //       (expirationDate.getTime() - new Date().getTime()) / 1000
      //     )
      //   );
      // }

    }
  };
};
