/* eslint-disable react/prop-types */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Box
} from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import PerfectScrollbar from 'react-perfect-scrollbar'


const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  root: {
    margin: 0,
    padding: 0,
    '&:focus': {
      outline: 'none',
      border: 'none'
    }
  },
  input: {
    width: 50,
    fontSize: 12
  },
  inner: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300
    }
  }
}))

export default function SimpleTable({ data, increment, decrement, setValue, toggleEdit, readOnly, hasLot }) {
  const classes = useStyles()

  if (data.length === 0) return null
  let table = null
  if (readOnly) {
    table = <Table
      aria-label="simple table"
      className={classes.table}
      size="small"
    >
      <TableHead>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Barcode</TableCell>
          <TableCell>Package</TableCell>
          <TableCell>Unit</TableCell>
          {hasLot && <TableCell>Lot</TableCell>}
          <TableCell>Quantity</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={row.name}>

            <TableCell
              component="th"
              scope="row"
            >
              {index + 1}
            </TableCell>

            <TableCell
              component="th"
              scope="row"
            >
              {row.name}
            </TableCell>

            <TableCell
              component="th"
              scope="row"
            >
              {row.barcode}
            </TableCell>

            <TableCell
              component="th"
              scope="row"
            >
              {row.package}
            </TableCell>

            <TableCell
              component="th"
              scope="row"
            >
              {row.unit}
            </TableCell>
            {hasLot&& (<TableCell
              component="th"
              scope="row"
            >
              {row?.lot}
            </TableCell>)}

            <TableCell>
              <Box component="span">
                {row.quantity}
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  }

  else {
    table = <Table
      aria-label="simple table"
      className={classes.table}
      size="small"
    >
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Barcode</TableCell>
          <TableCell>Package</TableCell>
          <TableCell>Unit</TableCell>
          {hasLot && <TableCell>Lot</TableCell>}
          <TableCell>Quantity</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(row => (
          <TableRow key={row.name}>

            <TableCell
              component="th"
              scope="row"
            >
              {row.name}
            </TableCell>

            <TableCell>
              {row.barcode}
            </TableCell>

            <TableCell>
              {row.package}
            </TableCell>

            <TableCell>
              {row.unit}
            </TableCell>
            {hasLot && ( 
            <TableCell>
              {row?.lot}
            </TableCell>)}

            <TableCell>
              <Box
                component="span"
                onClick={() => toggleEdit(row.name)}
              >{
                  !row.edit ? row.quantity : null}
              </Box>

              {row.edit &&
                                <TextField
                                  autoFocus
                                  classes={{ root: classes.root }}
                                  InputProps={{ className: classes.input }}
                                  onBlur={() => toggleEdit(row.name)}
                                  onChange={(e) => setValue(e, row.name)}
                                  size="small"
                                  type="text"
                                  value={row.quantity}
                                  variant="outlined"
                                />}
            </TableCell>

            <TableCell align="right">
              <IconButton
                aria-label="dec"
                className={classes.margin}
                onClick={() => decrement(row.name)}
                size="small"
              >
                <ArrowDownwardIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                aria-label="inc"
                className={classes.margin}
                color="primary"
                onClick={() => increment(row.name)}
                size="small"
              >
                <ArrowUpwardIcon fontSize="inherit" />
              </IconButton>
            </TableCell>

          </TableRow>
        ))}
      </TableBody>
    </Table>
  }

  return < PerfectScrollbar >
    <div className={classes.inner}>
      {table}
    </div>
  </PerfectScrollbar >
}
