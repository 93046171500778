import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Card } from '@material-ui/core';
import BasicTable from '../../components/BasicTable';
import axios from 'axios';
import moment from 'moment';

const getDeliveredEmailsEndpoint =
  'https://us-central1-papillon-trade-app.cloudfunctions.net/sendgrid/nodemailer/mailgun_email_events';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  exportContainer: {
    padding: '12px',
    marginTop: '14px',
    marginBottom: '14px',
    display: 'flex',
    justifyContent: 'flex-start'
  }
}));

const EmailList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const tableHeaders = [
    t('tableHeaders.date'),
    t('tableHeaders.event'),
    t('tableHeaders.subject'),
    t('tableHeaders.recipient'),
    t('tableHeaders.deliveryStatusMessage')
  ];

  const [loading, setLoading] = useState(false);
  const [emailList, setEmailList] = useState([]);

  const userRole = useSelector(state => state.auth.user.roles[0]);

  useEffect(() => {
    fetchEmails();
  }, []);

  const fetchEmails = async () => {
    try {
      let fetchedList = [];
      if (userRole === 'admin') {
        setLoading(true);
        const res = await axios.post(getDeliveredEmailsEndpoint);
        const { items } = res.data?.data;
        fetchedList = items.map(_o => ({
          ..._o,
          timestamp: (
            <span>
              {
                moment
                  .unix(_o.timestamp)
                  .format('MM/DD/YYYY LT')
                  .split(' ')[0]
              }
              <br />
              {
                moment
                  .unix(_o.timestamp)
                  .format('MM/DD/YYYY LT')
                  .split(' ')[1]
              }{' '}
              {
                moment
                  .unix(_o.timestamp)
                  .format('MM/DD/YYYY LT')
                  .split(' ')[2]
              }
            </span>
          )
        }));
      }
      setEmailList(fetchedList);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <BasicTable
          data={emailList}
          headers={tableHeaders}
          loading={loading}
          selectedItems={[]}
          setSelectedItems={[]}
          // withPagination
          // onRowClick={handleRowClick}
        />
      </div>
      {!userRole.includes('worker') && !userRole.includes('partner') && (
        <Card className={classes.exportContainer}>
          {/* <ExportCSVButton
            name="Export"
            csvData={products}
            fileName={'products'}
            selectedItems={selectedProducts}
          /> */}
        </Card>
      )}
    </div>
  );
};

export default EmailList;
