import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  makeStyles,
  Toolbar,
  Hidden,
  IconButton,
  Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import papillonTradeLogo from '../../../../assets/images/papillon_trade_logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main
  },
  flexGrow: {
    flexGrow: 1
  },
  menuItem: {
    color: '#fff'
  },
  logo: {
    maxHeight: 64,
    maxWidth: 100,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 5,
    paddingLeft: 5
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, isAuth, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/submissions">
          <img className={classes.logo} alt="Logo" src={papillonTradeLogo} />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          {isAuth && (
            <RouterLink to="/settings">
              <Button className={classes.menuItem}>Profile</Button>
            </RouterLink>
          )}
          {!isAuth && (
            <RouterLink to="/sign-in">
              <Button className={classes.menuItem}>Sign In</Button>
            </RouterLink>
          )}
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
