/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

import AutocompleteInput from '../AutoCompleteInput';

const useStyles = makeStyles(theme => ({
  root: {},
  grid: {
    backgroundColor: 'red',
    margin: theme.spacing(1, 0)
  },
  clearButton: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.white
  }
}));

const AccountDetails = props => {
  const {
    references,
    partners,
    storeNumbers,
    storeNames,
    classifications,
    workers,
    regions,
    cities,
    types,
    streets,
    className,
    handleApplyFilter,
    handleClearFilter,
    userInfo,
    ...rest
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const { userRole, username } = userInfo;
  const shouldFilterByPartner = userRole === 'admin' || (userRole === 'partner' && (username === 'papillon' || username === 'pikrijeka'))

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    reference: null,
    partner: null,
    worker: null,
    storeNumber: null,
    storeName: null,
    classification: null,
    type: null,
    region: null,
    city: null,
    street: null
  });

  const setDate = (date, which) => {
    if (which === 'start') {
      setStartDate(date || null);
      setFilters({ ...filters, startDate: date ? new Date(date) : null });
    } else if (which === 'end') {
      setEndDate(date || null);
      setFilters({ ...filters, endDate: date ? new Date(date) : null });
    }
  };

  const setValueHandler = (event, value, field) => {
    const updatedFilters = { ...filters };
    if (value && value.label) {
      updatedFilters[field] = value.label || null;
      setFilters(updatedFilters);
    } else if (value === null) {
      updatedFilters[field] = null;
      setFilters(updatedFilters);
    }
  };

  const clearFilterHandler = () => {
    handleClearFilter();
    setFilters({
      startDate: null,
      endDate: null,
      reference: null,
      partner: null,
      worker: null,
      storeNumber: null,
      storeName: null,
      classification: null,
      type: null,
      region: null,
      city: null,
      street: null
    });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form autoComplete="new-form-pt">
        <CardHeader
          subheader={t('filters.subtitle')}
          title={t('filters.title')}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
            >
              <DatePicker
                autoOk
                clearable
                format="DD/MM/YYYY"
                fullWidth
                // in
                inputVariant="outlined"
                label={t('filters.sdate')}
                margin="dense"
                maxDate={new Date()}
                onChange={date => setDate(date, 'start')}
                value={filters.startDate}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
            >
              <DatePicker
                autoOk
                clearable
                format="DD/MM/YYYY"
                fullWidth
                inputVariant="outlined"
                label={t('filters.edate')}
                margin="dense"
                maxDate={new Date()}
                onChange={date => setDate(date, 'end')}
                value={filters.endDate}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
            >
              <AutocompleteInput
                // onChange={(event)=>{console.log("event",event)}}
                label={t('filters.reference')}
                margin="dense"
                // inputValue={filters.reference ? filters.reference : ''}
                name="byReference"
                onOptionSelected={(a, b) => setValueHandler(a, b, 'reference')}
                suggestions={references}
              />
            </Grid>
            {shouldFilterByPartner && (
              <Grid
                item
                md={3}
                sm={6}
                xs={12}
              >
                <AutocompleteInput
                  label={t('filters.partner')}
                  margin="dense"
                  // inputValue={filters.partner ? filters.partner : ''}
                  name="byPartner"
                  onOptionSelected={(a, b) => setValueHandler(a, b, 'partner')}
                  suggestions={partners}
                />
              </Grid>
            )}
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
            >
              <AutocompleteInput
                label={t('filters.snumber')}
                margin="dense"
                // inputValue={filters.storeNumber ? filters.storeNumber : ''}
                name="byStoreNumber"
                onOptionSelected={(a, b) =>
                  setValueHandler(a, b, 'storeNumber')
                }
                suggestions={storeNumbers}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
            >
              <AutocompleteInput
                label={t('filters.sname')}
                margin="dense"
                // inputValue={filters.storeName ? filters.storeName : ''}
                name="byStoreName"
                onOptionSelected={(a, b) => setValueHandler(a, b, 'storeName')}
                suggestions={storeNames}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
            >
              <AutocompleteInput
                label={t('filters.classification')}
                margin="dense"
                // inputValue={filters.classification ? filters.classification : ''}
                name="byClassification"
                onOptionSelected={(a, b) =>
                  setValueHandler(a, b, 'classification')
                }
                suggestions={classifications}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
            >
              <AutocompleteInput
                label={t('filters.stype')}
                margin="dense"
                // inputValue={filters.type ? filters.type : ''}
                name="byType"
                onOptionSelected={(a, b) => setValueHandler(a, b, 'type')}
                suggestions={types}
              />
            </Grid>
            {userRole !== 'worker' && (
              <Grid
                item
                md={3}
                sm={6}
                xs={12}
              >
                <AutocompleteInput
                  label={t('filters.worker')}
                  margin="dense"
                  // inputValue={filters.worker ? filters.worker : ''}
                  name="byWorker"
                  onOptionSelected={(a, b) => setValueHandler(a, b, 'worker')}
                  suggestions={workers}
                />
              </Grid>
            )}
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
            >
              <AutocompleteInput
                label={t('filters.region')}
                margin="dense"
                // inputValue={filters.region ? filters.region : ''}
                name="byRegion"
                onOptionSelected={(a, b) => setValueHandler(a, b, 'region')}
                suggestions={regions}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
            >
              <AutocompleteInput
                label={t('filters.city')}
                margin="dense"
                // inputValue={filters.city ? filters.city : ''}
                name="byCity"
                onOptionSelected={(a, b) => setValueHandler(a, b, 'city')}
                suggestions={cities}
              />
            </Grid>
            <Grid
              item
              md={3}
              sm={6}
              xs={12}
            >
              <AutocompleteInput
                label={t('filters.street')}
                margin="dense"
                // inputValue={filters.street ? filters.street : ''}

                name="byStreet"
                onOptionSelected={(a, b) => setValueHandler(a, b, 'street')}
                suggestions={streets}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="secondary"
            onClick={() => handleApplyFilter(filters)}
            variant="contained"
          >
            {t('button.applyFilter')}
          </Button>
          <Button
            className={classes.clearButton}
            onClick={clearFilterHandler}
            variant="contained"
          >
            {t('button.clearFilter')}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
