import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import {
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress
} from '@material-ui/core';
import Alert from '../../../components/BasicAlert';

import validationSchemas from '../../../common/validationSchemas';
import authInstance from '../../../axiosInstance';
import parseQS from '../../../helpers/queryStringUtils';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.grey,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 60,
    // paddingBottom: 125,
    // flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  submitButton: {
    margin: theme.spacing(2, 0)
  }
}));

const ResetPassword = props => {
  const { location } = props;
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const classes = useStyles();

  const { register, handleSubmit, errors, formState } = useForm({
    validationSchema: validationSchemas.resetPasswordSchema,
    mode: 'onChange'
  });

  const onResetPassword = ({ password }) => {
    const oobCode = parseQS(location.search).oobCode;
    setLoading(true);
    authInstance('resetPassword')
      .post('', { oobCode, newPassword: password })
      .then(() => {
        setLoading(false);
        setSuccessMessage('Your password was changed successfully!');
      })
      .catch(err => {
        setLoading(false);
        setErrorMessage(err.response.data.error.message.replace(/_/g, ' '));
      });
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <div className={classes.person}></div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSubmit(onResetPassword)}>
                <Typography className={classes.title} variant="h2">
                  Reset Password
                </Typography>
                <Typography color="textSecondary" gutterBottom>
                  Enter yuor new password
                </Typography>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                {successMessage && (
                  <Alert severity="success">{successMessage}</Alert>
                )}
                <TextField
                  className={classes.textField}
                  label="Password"
                  type="password"
                  name="password"
                  fullWidth
                  variant="outlined"
                  error={!!errors.password}
                  inputRef={register}
                  helperText={errors.password && errors.password.message}
                />
                <TextField
                  className={classes.textField}
                  label="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  fullWidth
                  variant="outlined"
                  error={!!errors.confirmPassword}
                  inputRef={register}
                  helperText={
                    errors.confirmPassword && errors.confirmPassword.message
                  }
                />
                <Button
                  className={classes.submitButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained">
                  {loading ? <CircularProgress color="secondary" /> : 'Reset'}
                </Button>
              </form>
              <Link to="/sign-in">Sign In</Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object
};

export default withRouter(ResetPassword);
