/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TextField, Button, Box, CircularProgress } from '@material-ui/core';
import AddCommentIcon from '@material-ui/icons/AddComment';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  comment: {
    margin: theme.spacing(1, 0),
    display: 'block',
    position: 'relative',
    width: '100%'
  },
  addButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: 2,
    margin: 5
  },
  overlay: {
    background: '#888',
    height: '100%',
    width: '100%',
    opacity: 0.5,
    top: 0,
    left: 0,
    position: 'absolute',
    padding: 0,
    transition: 'opacity 2s',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center'
  },
  show: {
    display: 'flex'
  }
}));

const CommentInput = ({ value, handleChange, handleSubmit, loading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const show = loading ? classes.show : '';

  return (
    <Box className={classes.comment}>
      <TextField
        value={value}
        onChange={e => handleChange(e.target.value)}
        placeholder={t('submission.addcomment')}
        label="Comment"
        multiline
        rows="2"
        variant="outlined"
        fullWidth
      />
      <Button
        onClick={handleSubmit}
        variant="outlined"
        size="small"
        color="primary"
        className={classes.addButton}>
        <AddCommentIcon />
      </Button>
      <Box className={clsx(classes.overlay, show)}>
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default CommentInput;
