import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  // Dashboard as DashboardView,
  AddSubmission as AddSubmissionView,
  SubmissionsList as SubmissionsView,
  Submission as SubmissionView,
  PartnersList as PartnersListView,
  WorkersList as WorkersListView,
  ProductList as ProductListView,
  HORECASTList as HORECASTListView,
  QuestionsList as QuestionsListView,
  Settings as SettingsView,
  SignIn as SignInView,
  ResetPassword as ResetPasswordView,
  Logout as LogoutView,
  NotFound as NotFoundView,
  EmailsList as EmailsListView,
  TestingPage,
  ReturnsList,
  AddReturn,
  Return,
  AssortmentList,
  RoutePlanner
} from './views';

const routesList = [
  /*   {
      id: 1,
      path: '/dashboard',
      component: DashboardView,
      layout: MainLayout
    }, */
  {
    id: 2,
    path: '/add-submission',
    component: AddSubmissionView,
    layout: MainLayout
  },
  {
    id: 3,
    path: '/submissions',
    component: SubmissionsView,
    layout: MainLayout
  },
  {
    id: 4,
    path: '/submissions/:id',
    component: SubmissionView,
    layout: MainLayout
  },
  {
    id: 5,
    path: '/partners',
    component: PartnersListView,
    layout: MainLayout
  },
  {
    id: 6,
    path: '/workers',
    component: WorkersListView,
    layout: MainLayout
  },
  {
    id: 7,
    path: '/questions',
    component: QuestionsListView,
    layout: MainLayout
  },
  {
    id: 18,
    path: '/assortments',
    component: AssortmentList,
    layout: MainLayout
  },
  {
    id: 8,
    path: '/products',
    component: ProductListView,
    layout: MainLayout
  },
  {
    id: 9,
    path: '/stores',
    component: HORECASTListView,
    layout: MainLayout
  },
  {
    id: 10,
    path: '/settings',
    component: SettingsView,
    layout: MainLayout
  },
  {
    id: 11,
    path: '/sign-in',
    component: SignInView,
    layout: MinimalLayout
  },
  {
    id: 12,
    path: '/reset-password',
    component: ResetPasswordView,
    layout: MinimalLayout
  },
  {
    id: 13,
    path: '/test-route',
    component: TestingPage,
    layout: MinimalLayout
  },
  {
    id: 14,
    path: '/emails',
    component: EmailsListView,
    layout: MainLayout
  },
  {
    id: 15,
    path: '/returns',
    component: ReturnsList,
    layout: MainLayout
  },
  {
    id: 16,
    path: '/add-return',
    component: AddReturn,
    layout: MainLayout
  },
  {
    id: 17,
    path: '/returns/:id',
    component: Return,
    layout: MainLayout
  },
  {
    id: 18,
    path: '/route-planner',
    component: RoutePlanner,
    layout: MainLayout
  }
];

const Routes = () => {
  const userRole = useSelector(state => {
    if (state.auth.user) return state.auth.user.roles[0];
    return 'public';
  });

  const isLoggedIn = useSelector(state => state.auth.token !== null);
  let derivedRoutes = [];

  switch (userRole) {
    case 'admin':
      derivedRoutes = routesList.filter(
        route => route.id !== 2 && route.id !== 16 && route.id !== 12
      );
      break;

    case 'partner':
      derivedRoutes = routesList.filter(
        route =>
          route.id !== 2 && route.id !== 16 && route.id !== 5 && route.id !== 14
      );
      break;

    case 'worker':
      derivedRoutes = routesList.filter(route => {
        return (
          route.id !== 5 &&
          route.id !== 6 &&
          route.id !== 7 &&
          route.id !== 8 &&
          route.id !== 12 &&
          route.id !== 14
        );
      });
      break;

    case 'public':
      derivedRoutes = routesList.filter(
        route => route.id === 11 || route.id === 12
      );
      break;

    default:
      break;
  }

  // debugger;
  return (
    <Switch>
      {derivedRoutes.map(route => {
        return (
          <RouteWithLayout
            component={route.component}
            exact
            key={route.id}
            layout={route.layout}
            path={route.path}
          />
        );
      })}
      <Route component={LogoutView} exact path="/logout" />
      <Route exact path="/">
        <Redirect to={isLoggedIn ? '/submissions' : '/sign-in'} />
      </Route>
      <Route component={NotFoundView} exact path="not-found" />
      {isLoggedIn && <Route component={NotFoundView} />}
    </Switch>
  );
};

export default Routes;
