/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack'

import BasicFab from '../../../../components/BasicFab';
import AddWorker from '../../AddWorker';
import BasicDialog from '../../../../components/BasicDialog';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const WorkersToolbar = ({
  className,
  refresh,
  selectedWorkers,
  deleteWorkers,
  editWorker,
  setEdit,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const userRole = useSelector(state => state.auth.user.roles[0]);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const hasSelectedItems = selectedWorkers.length !== 0;

  useEffect(() => {
    if (editWorker) {
      // console.log("editPartner data",editPartner)
      setOpen(true);
    } else {
      // console.log("editPartner removed",editPartner)
    }
    return () => {};
  }, [editWorker]);

  const handleModalClose = () => {
    setOpen(false);
    setEdit(false);
  };

  const handleModalSuccessClose = () => {
    setOpen(false);
    if (editWorker) {
      enqueueSnackbar(t('Updated!'), {variant: 'success'})
      setEdit(false);
    } else {
      enqueueSnackbar(t('notifications.partnerAdded'), {variant: 'success'})
    }
    refresh();
  };

  const deleteWorkersHandler = () => {
    deleteWorkers().then(() => {
      refresh();
      setDeleteDialogOpen(false);
      enqueueSnackbar(t('notifications.partnerDeleted'), {variant: 'success'})
    });
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {userRole === 'admin' && (
        <div className={classes.row}>
          <span className={classes.spacer} />
          {hasSelectedItems && (
            <BasicFab
              handleClick={() => setDeleteDialogOpen(true)}
              label="Delete Items"
              remove
            />
          )}
          <BasicFab
            handleClick={() => setOpen(true)}
            label={t('button.addWorker')}
          />
        </div>
      )}

      <AddWorker
        handleClose={handleModalClose}
        isEdit={editWorker}
        modalOpen={open}
        onSuccess={handleModalSuccessClose}
      />

      <BasicDialog
        count={selectedWorkers.length}
        handleClose={() => setDeleteDialogOpen(false)}
        handleConfirm={() => deleteWorkersHandler()}
        open={deleteDialogOpen}
        title={t('dialog.deleteWorkers')}
      />
    </div>
  );
};

WorkersToolbar.propTypes = {
  className: PropTypes.string
};

export default WorkersToolbar;
