import { db, firestore } from '../../firebase'

export const deleteDocuments = (collection, ids) => {
    return db.collection(collection)
        .where(firestore.FieldPath.documentId(), 'in', ids).get()
        .then(function (querySnapshot) {
            // Once we get the results, begin a batch
            var batch = db.batch()

            querySnapshot.forEach(function (doc) {
                // For each doc, add a delete operation to the batch
                batch.delete(doc.ref)
            });

            // Commit the batch
            return batch.commit()
        })
}