/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from '@material-ui/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import styled from 'styled-components';

const ExportButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    &.MuiButton-root {
      width: 100%;
      margin-bottom: 8px;
    }
  }
`;

const ExportCSVButton = ({
  csvData,
  fileName,
  name,
  selectedItems,
  skipCol
}) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const skipColKeys = skipCol ? skipCol : [];
  const isIncludedColKey = key => {
    return !skipColKeys.includes(key);
  };
  const exportToCSV = () => {
    const cusCsvData = csvData
      .filter(
        obj => selectedItems.length === 0 || selectedItems.includes(obj.id)
      )
      .map(obj => {
        return Object.keys(obj).reduce((object, key) => {
          if (isIncludedColKey(key)) {
            object[key] = obj[key];
          }
          return object;
        }, {});
      });

    const ws = XLSX.utils.json_to_sheet(cusCsvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const date = moment()
      .format('L')
      .toString();
    const time = moment()
      .format('LT')
      .toString()
      .replace(':', '-')
      .replace(' ', '');
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `${fileName}_${date}_${time}_ex${fileExtension}`);
  };

  return (
    <ExportButton
      color="secondary"
      onClick={exportToCSV}
      size="small"
      style={{ marginLeft: '14px', marginRight: '14px' }}
      variant="contained">
      {name}
    </ExportButton>
  );
};
export default ExportCSVButton;
