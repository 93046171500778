/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack'

import AddQuestion from '../../AddQuestion'
import BasicFab from '../../../../components/BasicFab'
import BasicDialog from '../../../../components/BasicDialog'

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const QuestionsToolbar = props => {
  const {
    className,
    refresh,
    selectedQuestions,
    deleteQuestions,
    ...rest
  } = props;

  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  
  const hasSelectedItems = selectedQuestions.length !== 0;

  const deleteQuestionsHandler = () => {
    deleteQuestions().then(() => {
      refresh();
      setDeleteDialogOpen(false);
      enqueueSnackbar(t('notifications.questionAdded'), {variant: 'success'})
    });
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        {hasSelectedItems && (
          <BasicFab
            handleClick={() => setDeleteDialogOpen(true)}
            label="Delete Items"
            remove
          />
        )}
        <BasicFab
          handleClick={() => setOpen(true)}
          label={t('button.addQuestion')}
        />
        <AddQuestion
          handleClose={() => setOpen(false)}
          modalOpen={open}
          onSuccess={() =>
            enqueueSnackbar(t('notifications.questionAdded'), {variant: 'success'})
          }
          refresh={refresh}
        />
        <BasicDialog
          count={selectedQuestions.length}
          handleClose={() => setDeleteDialogOpen(false)}
          handleConfirm={() => deleteQuestionsHandler()}
          open={deleteDialogOpen}
          title={t('button.deleteQuestions')}
        />
      </div>
    </div>
  );
};

QuestionsToolbar.propTypes = {
  className: PropTypes.string
};

export default QuestionsToolbar;
