import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSelector.css';

const lang = localStorage.getItem('i18nextLng');

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
  };

  return (
    <div
      className="lang-selector"
      onChange={changeLanguage}
    >
      {/* English */}
      <input
        defaultChecked={lang === 'en'}
        id="en"
        name="language"
        type="radio"
        value="en"
      />
      <label
        className="drinkcard-cc en"
        htmlFor="en"
      />
      {/* Croatian */}
      <input
        defaultChecked={lang === 'hr'}
        id="hr"
        name="language"
        type="radio"
        value="hr"
      />
      <label
        className="drinkcard-cc hr"
        htmlFor="hr"
      />
    </div>
  );
};

export default LanguageSelector;
