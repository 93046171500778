import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { db } from '../../../firebase';
import WorkersToolbar from './components/WorkersToolbar';
import BasicTable from '../../../components/BasicTable';
import { deleteDocuments } from '../../../common/firebaseActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const WorkersList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const tableHeaders = [
    t('tableHeaders.name'),
    t('tableHeaders.username'),
    t('tableHeaders.partner'),
    t('tableHeaders.email')
  ];

  const [workers, setWorkers] = useState([]);
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const { userId } = useSelector(state => state.auth);
  const userRole = useSelector(state => state.auth.user.roles[0]);
  const username = useSelector(state => state.auth.user.username);

  useEffect(() => {
    fetchWorkers();
  }, []);

  const workersRef = () => {

    if (userRole === 'admin') {
      // fetch all workers for admin
      return db.collection('workers');

    } else if (userRole === 'partner' && username === 'pikrijeka') {
      // fetch workers with prop isPikrijeka for pikrijeka partner
      return db.collection('workers').where('isPikrijeka', '==', true);

    } else if (userRole === 'partner') {
      // fetch workers with partner id
      return db.collection('workers').where('partnerInfo.id', '==', userId);
    }
  };

  const fetchWorkers = async () => {
    const specialPartner = username === 'pikrijeka' ? 'Pikrijeka' : 'Papillon'
    try {
      setLoading(true);
      const fetchedWorkers = await (await workersRef().get()).docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        username: doc.data().username,
        avatar: doc.data().avatar,
        partner: doc.data().partnerInfo?.name || specialPartner,
        email: doc.data().email || ''
      }));

      setWorkers(fetchedWorkers);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleDeleteWorkers = () => deleteDocuments('workers', selectedWorkers);

  const updateWorkers = () => {
    setSelectedWorkers([]);
    fetchWorkers();
  };
  const handleRowClick = data => {
    if (userRole === 'admin') {
      setEdit(data);
    }
  };
  return (
    <div className={classes.root}>
      <WorkersToolbar
        refresh={updateWorkers}
        selectedWorkers={selectedWorkers}
        deleteWorkers={handleDeleteWorkers}
        editWorker={isEdit}
        setEdit={setEdit}
      />
      <div className={classes.content}>
        <BasicTable
          headers={tableHeaders}
          data={workers}
          selectedItems={selectedWorkers}
          setSelectedItems={setSelectedWorkers}
          loading={loading}
          withPagination
          withAvatar
          small
          onRowClick={handleRowClick}
        />
      </div>
    </div>
  );
};

export default WorkersList;
