/* eslint-disable react/prop-types */
import React from 'react'
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Title = styled(Typography)`
    text-transform: uppercase;
`

const Container = styled.div`
    padding-top: 8px;
`

const AssortmentItem = styled(ListItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({theme}) => theme.breakpoints.down('md')}{
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  }
`
const AssortmentLabel = styled(ListItemText)`
  ${({theme}) => theme.breakpoints.down('md')}{
    width: 100%;
  }
`
const AssortmentOptions = styled(FormControl)`
  ${({theme}) => theme.breakpoints.down('md')}{
    align-self: center;
    width: 100%;
  }
`

export default function Assortments({assortments, handleSelect}) {
  const {t} = useTranslation()

  const selectHandler = (ev, id) => {
    const updatedAssortments = assortments.map(asrt => {
      if(asrt.id === id) return {...asrt, answer: ev.target.value}
      return asrt
    })
    
    handleSelect(updatedAssortments)
  }

  if(assortments.length === 0) return null

  return (
    <Container>
      <Title variant="h6">{t('menu.assortments')}</Title>
      <Divider />
      <div>
        <List>
          {assortments.map(({id, label, options, answer}) => {
            return <AssortmentItem key={id}>
              <AssortmentLabel primary={label} />
              <AssortmentOptions
                margin="dense"
                variant="outlined"
              >
                <InputLabel id="demo-simple-select-outlined-label">{t('addAssortmetnForm.selectOption')}</InputLabel>
                <Select
                  id="demo-simple-select-outlined"
                  label={t('addAssortmetnForm.selectOption')}
                  labelId="demo-simple-select-outlined-label"
                  onChange={(ev) => selectHandler(ev, id)}
                  style={{minWidth: 250}}
                  value={answer}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {options.map(option => 
                    <MenuItem
                      key={option}
                      value={option}
                    >
                      {option}
                    </MenuItem>)}
                </Select>
              </AssortmentOptions>
            </AssortmentItem>
          })}
        </List>
      </div>
    </Container>
  )
}
