/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableHead,
  TableRow, TablePagination, useMediaQuery,
  Paper, Checkbox, Avatar, Typography,
  LinearProgress, TextField, makeStyles, IconButton,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CommentIcon from '@material-ui/icons/Comment';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Cards from '../Cards'

import randomNumber from '../../helpers/randomNumber';
import { getInitials } from '../../helpers';
import { setTablePage } from '../../store/reducers/submissions';

const useStyles = makeStyles(theme => ({
  tableRow: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  input: {
    padding: 0,
    margin: 0,
    width: 100
  },
  inner: {
    minWidth: 1050
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.secondary.light,
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  tinyCell: {
    fontSize: '14px',
  },
  slimIcon: {
    padding: 0,
    margin: 0,
  }
}));

const CheckAll = styled(Paper)`
  padding: 5px 10px;
  margin-bottom: 8px;
`;

const CheckAllTitle = styled(Typography)`
  margin-left: 8px;
  display: inline;
`;


const BasicTable = props => {

  const classes = useStyles();
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  const submissions = useSelector(state => state.submissions)
  const dispatch = useDispatch()

  const { t } = useTranslation();

  const {
    headers,
    data,
    selectedItems,
    setSelectedItems,
    onRowClick = () => { },
    loading,
    withPagination,
    withAvatar,
    editable,
    handleQuantityChange,
    styles,
    small,
    skipCol,
    isSubmissionsList,
    isReturnsList,
  } = props;

  const skipColKeys = skipCol ? skipCol : [];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const tableHeaders = !headers[0].key ? headers : headers.filter(({ key }) => {
    return key !== 'reference' && key !== 'storen' && key !== 'street' && key !== 'partner'
  }).map(item => {
    if (item.key === 'comments') return <IconButton className={classes.slimIcon}><CommentIcon /></IconButton>
    return item.label
  })

  const handleSelectAll = event => {
    const checked = event.target.checked;

    let selectedItems;

    if (checked) {
      selectedItems = data.map(item => item.id);
    } else {
      selectedItems = [];
    }

    setSelectedItems(selectedItems);
  };

  const handleSelectOne = (_, id) => {

    const selectedIndex = selectedItems.indexOf(id);
    let newselectedItems = [];

    if (selectedIndex === -1) {
      newselectedItems = newselectedItems.concat(selectedItems, id);
    } else if (selectedIndex === 0) {
      newselectedItems = newselectedItems.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newselectedItems = newselectedItems.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newselectedItems = newselectedItems.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }

    setSelectedItems(newselectedItems);
  };

  // set table page
  useEffect(() => {
    const {page} = submissions;

    if(isSubmissionsList && !isReturnsList && page.submissions !== 0){
      handleChangePage(null, page.submissions)
    }
    
    else if(isSubmissionsList && isReturnsList && page.returns !== 0){
      handleChangePage(null, page.returns)
    }
  }, [])

  const handleChangePage = (_, page) => {
    if(isSubmissionsList && !isReturnsList) {
      dispatch(setTablePage({submissions: page}))
    } else{
      dispatch(setTablePage({returns: page}))
    }

    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const shorten = str => {
    if (str.length > 10) {
      return (
        str
          .split('')
          .slice(0, 7)
          .join('') + '...'
      );
    }
    return str;
  };

  const canShorten = key => {
    return (
      key !== 'email' &&
      key !== 'reference' &&
      key !== 'type' &&
      key !== 'question' &&
      key !== 'date' &&
      key !== 'worker' &&
      key !== 'assortment'
    );
  };

  const isReference = key => key === 'reference';

  let tableBody;
  let display = null;

  if (loading) display = <LinearProgress />;
  else if (!loading && data.length === 0) {
    display = <Typography>Add new items to see them here!</Typography>;
  }

  const isIncludedColKey = key => !skipColKeys.includes(key)

  if (withPagination) {
    tableBody = (
      <TableBody>
        {data
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(dataRow => {
            const columnsOrder = [0, 1, 4, 5, 2, 3, 6, 7, 8]
            const rowKeys = withAvatar
              ? Object.keys(dataRow).filter(
                item => item !== 'name' && item !== 'avatar' && item !== 'id'
              )
              : Object.keys(dataRow).filter(
                item => item !== 'id' && isIncludedColKey(item)
              );
            const filteredDataItems = !isSubmissionsList ? rowKeys : rowKeys.map((item, i) => rowKeys[columnsOrder[i]])

            return (
              <TableRow
                classes={{ root: classes.tableRow }}
                hover
                key={Math.random() * 9999 * Math.random()}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedItems.indexOf(dataRow.id) !== -1}
                    color="primary"
                    onChange={event => handleSelectOne(event, dataRow.id)}
                    value="true"
                  />
                </TableCell>
                {withAvatar && (
                  <TableCell
                    onClick={
                      isReference ? () => onRowClick(dataRow) : () => null
                    }
                  >
                    <div className={classes.nameContainer}>
                      <Avatar
                        className={classes.avatar}
                        src={dataRow.avatar}
                      >
                        {getInitials(dataRow.name)}
                      </Avatar>
                      <Typography variant="body1">{dataRow.name}</Typography>
                    </div>
                  </TableCell>
                )}

                {filteredDataItems.map((key, index) => {
                  if (isSubmissionsList && (key === 'reference' || key === 'storeName'
                    || key === 'street' || key === 'partner')) {
                    return null;
                  }

                  let cellValue = '';

                  if (isSubmissionsList) {
                    if (key === 'date') {
                      cellValue = <><span className={classes.link}>{dataRow['reference']}</span> <br />{dataRow['date']}</>
                    }
                    else if (key === 'storeNumber') {
                      cellValue = <><b>{dataRow['storeNumber']}</b> <br />{dataRow['storeName']}</>
                    }
                    else if (key === 'city') {
                      cellValue = <><b>{dataRow['city']}</b> <br />{dataRow['street']}</>
                    }
                    else if (key === 'worker') {
                      cellValue = <>{dataRow['worker']} <br /><b>{dataRow['partner']}</b></>
                    }
                    else {
                      cellValue = dataRow[key]
                    }
                  }
                  else {
                    cellValue = canShorten(key) ? shorten(dataRow[key]) : dataRow[key]
                  }
                  return (
                    <TableCell
                      className={isReference(key) ? classes.link : classes.tinyCell}
                      component={index === 0 ? 'th' : ''}
                      key={Math.random() * 1000}
                      onClick={isReference ? () => onRowClick(dataRow) : () => null}
                      scope={index === 0 ? 'row' : ''}
                    >
                      {cellValue}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}

        {emptyRows > 0 && (
          <TableRow style={{ height: 49 * emptyRows }}>
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
    );
  } else {
    tableBody = (
      <TableBody>
        {data.map(dataRow => {
          return (
            <TableRow key={Math.random() * 9999 * Math.random()}>
              {Object.keys(dataRow)
                .filter(item => item !== 'id' && isIncludedColKey(item))
                .map((key, index) => {
                  if (key === 'quantity' && editable)
                    return (
                      <TableCell
                        component={index === 0 ? 'th' : ''}
                        key={randomNumber()}
                        scope={index === 0 ? 'row' : ''}
                      >
                        <TextField
                          className={classes.input}
                          onChange={e =>
                            handleQuantityChange(e.target.value, index)
                          }
                          size="small"
                          type="number"
                          variant="outlined"
                        />
                      </TableCell>
                    );
                  return (
                    <TableCell
                      component={index === 0 ? 'th' : ''}
                      key={randomNumber()}
                      scope={index === 0 ? 'row' : ''}
                    >
                      {dataRow[key]}
                    </TableCell>
                  );
                })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  if (data.length) {
    if((!isXs && isSubmissionsList) || !isSubmissionsList) {

      display = (
        <Paper>
          <PerfectScrollbar>
            <div className={classes.inner}>

              <Table
                className={styles}
                size={small ? 'small' : 'medium'}
              >
                <TableHead style={{ textTransform: 'uppercase' }}>
                  <TableRow>
                    {withPagination && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedItems.length === data.length}
                          color="primary"
                          indeterminate={
                            selectedItems.length > 0 &&
                            selectedItems.length < data.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                    )}
                    {tableHeaders.map(header => (
                      <TableCell key={Math.random() * 9999 * Math.random()}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {tableBody}
              </Table>

              {withPagination && (
                <TablePagination
                  component="div"
                  count={data.length}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                />
              )}
            </div>
          </PerfectScrollbar>
        </Paper>
      );
    }
    else if(isXs && isSubmissionsList) {
      const submissionsSlice = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      display = (<>
        <CheckAll>
          <Checkbox
            checked={selectedItems.length === data.length}
            color="primary"
            indeterminate={
              selectedItems.length > 0 &&
        selectedItems.length < data.length
            }
            onChange={handleSelectAll}
          />
          <CheckAllTitle>{t('cards.selectAll')}</CheckAllTitle>
        </CheckAll>
        <Cards
          eer
          handleSelectOne={handleSelectOne}
          loading={loading}
          onCardClick={onRowClick}
          selectedItems={selectedItems}
          submissions={submissionsSlice}
        />
        <Paper>
          <TablePagination
            component="div"
            count={data.length}
            labelRowsPerPage=""
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </Paper>
      </>
      )
    }
  }

  return <div>{display}</div>;
};

BasicTable.defaultProps = {
  withPagination: false
};

export default BasicTable;
