const { include } = require('underscore');

const isExistCaseSensitive = (str, searchTxt) => str.includes(searchTxt);
const isExist = (str, searchTxt) =>
  str.toLowerCase().includes(searchTxt.toLowerCase());

const searchFilter = (
  data,
  searchTxt,
  includedKeys,
  isCaseSensitive = true
) => {
  let arr = data ? data : [];
  if (typeof includedKeys === 'string') {
    arr = arr.filter(item => {
      return Object.values(item).some(str => {
        const res = isCaseSensitive
          ? isExistCaseSensitive(`${str}`, `${searchTxt}`)
          : isExist(`${str}`, `${searchTxt}`);

        return res;
      });
    });
  } else {
    arr = arr.filter(item =>
      includedKeys.some(key => {
        const res = isCaseSensitive
          ? isExistCaseSensitive(`${item[key]}`, `${searchTxt}`)
          : isExist(`${item[key]}`, `${searchTxt}`);

        return res;
      })
    );
  }
  return arr;
};

export default searchFilter;
