import * as actionTypes from '../actions/actionTypes';
import updateObject from '../../helpers/updateObject';

const initialState = {
  filters: null,
  filteredSubmissions: [],
  // returns
  returnFilters: null,
  filteredReturns: [],
};

const setFilters = (state, action) => {
  return updateObject(state, {
    filters: action.filters
  });
};

const setReturnFilters = (state, action) => {
  return updateObject(state, {
    returnFilters: action.filters
  });
};

const setFilteredData = (state, action) => {
  return updateObject(state, {
    filteredSubmissions: action.isMoreType
      ? [...state.filteredSubmissions, ...action.filteredData]
      : action.filteredData
  });
};

const setReturnFilteredData = (state, action) => {
  return updateObject(state, {
    filteredReturns: action.isMoreType
      ? [...state.filteredReturns, ...action.filteredData]
      : action.filteredData
  });
};

const clearFilters = (state) => {
  return updateObject(state, {
    returnFilters: {},
    filteredReturns: [],
  });
};

const clearReturnFilters = (state) => {
  return updateObject(state, {
    filters: null,
    filteredSubmissions: []
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPLY_FILTER:
      return setFilters(state, action);

    case actionTypes.CLEAR_FILTER:
      return clearFilters(state, action);

    case actionTypes.SET_FILTERED_DATA:
      return setFilteredData(state, action);
    
    case actionTypes.APPLY_RETURNS_FILTER:
      return setReturnFilters(state, action);

    case actionTypes.CLEAR_RETURNS_FILTER:
      return clearReturnFilters(state, action);

    case actionTypes.SET_RETURNS_FILTERED_DATA:
      return setReturnFilteredData(state, action);
      
    default:
      return state;
  }
};

export default reducer;
