/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack'

import BasicFab from '../../../../components/BasicFab';
import BasicDialog from '../../../../components/BasicDialog';
import AddHORECAST from '../../AddHORECAST';

import ImportCSVButton from '../../../../helpers/ImportTableExcel';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const StoresToolbar = ({
  className,
  refresh,
  selectedStores,
  deleteStores,
  isPartner,
  isWorker,
  importHandler,
  loading,
  setEdit,
  editStore,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const hasSelectedItems = selectedStores.length !== 0;

  useEffect(() => {
    if (editStore) {
      setOpen(true);
    }
  }, [editStore]);

  const closeModalHandler = () => {
    setOpen(false);
    setEdit(false);
  };

  const deleteWorkersHandler = () => {
    deleteStores().then(() => {
      refresh();
      setDeleteDialogOpen(false);

      enqueueSnackbar(t('notifications.storesDeleted'), {variant: 'success'})
    });
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        {!isPartner && !isWorker && (
          <ImportCSVButton
            importHandler={importHandler}
            loading={loading}
            onError={() =>
              enqueueSnackbar('There is something missing', {variant: 'success'})
            }
            onSuccess={() =>
              enqueueSnackbar(t('notifications.storesAdded'), {variant: 'success'})
            }
          />
        )}
        <span className={classes.spacer} />
        {hasSelectedItems && !isWorker && (
          <BasicFab
            handleClick={() => setDeleteDialogOpen(true)}
            label={t('button.deleteStores')}
            remove
          />
        )}
        <BasicFab
          handleClick={() => setOpen(true)}
          label={t('button.addStore')}
        />
      </div>
      
      <AddHORECAST
        closeModal={closeModalHandler}
        isEdit={editStore}
        modalOpen={open}
        onSuccess={() =>
          enqueueSnackbar(t('notifications.storesAdded'), {variant: 'success'})
        }
        refresh={refresh}
      />

      <BasicDialog
        count={selectedStores.length}
        handleClose={() => setDeleteDialogOpen(false)}
        handleConfirm={() => deleteWorkersHandler()}
        open={deleteDialogOpen}
        title={t('dialog.deleteStores')}
      />
    </div>
  );
};

StoresToolbar.propTypes = {
  className: PropTypes.string
};

export default StoresToolbar;
