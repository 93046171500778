import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  CircularProgress
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import BasicTextField from '../../../../components/BasicTextField';
import validationSchemas from '../../../../common/validationSchemas';
import { auth } from '../../../../firebase';
import Alert from '../../../../components/BasicAlert';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  // const idToken = useSelector(state => state.auth.token)
  const email = useSelector(state => state.auth.user.email);
  const [laoding, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { register, handleSubmit, errors, formState } = useForm({
    validationSchema: validationSchemas.updatePasswordSchema,
    mode: 'onChange'
  });

  const onUpdatePassword = ({ currentPassword, password }, e) => {
    setLoading(true);
    auth
      .signInWithEmailAndPassword(email, currentPassword)
      .then(function() {
        auth.currentUser
          .updatePassword(password)
          .then(function() {
            setLoading(false);
            setErrorMessage(null);
            setSuccessMessage(t('notifications.passwordChanged'));
            e.target.reset();
          })
          .catch(function(error) {
            setLoading(false);
            setSuccessMessage(null);
            setErrorMessage(error.message);
            e.target.reset();
          });
      })
      .catch(function(err) {
        setLoading(false);
        setSuccessMessage(null);
        setErrorMessage(err.message);
        e.target.reset();
      });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form onSubmit={handleSubmit(onUpdatePassword)}>
        <CardHeader
          subheader={t('settings.subtitle')}
          title={t('settings.title')}
        />
        <Divider />
        <CardContent>
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <BasicTextField
            label={t('settings.password')}
            name="currentPassword"
            type="password"
            error={errors.currentPassword}
            register={register}
          />
          <BasicTextField
            label={t('settings.newPassword')}
            name="password"
            type="password"
            error={errors.password}
            register={register}
          />
          <BasicTextField
            label={t('settings.cnewPassword')}
            name="confirmPassword"
            type="password"
            error={errors.confirmPassword}
            register={register}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            disabled={!formState.isValid}
            type="submit">
            {laoding ? <CircularProgress /> : t('button.update')}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
