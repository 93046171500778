/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import {
  Button,
  GridList,
  GridListTile,
  TextField,
  Typography,
  Divider,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import validationSchemas from '../../../common/validationSchemas';
import AutocompleteInput from '../../../components/AutoCompleteInput';
import GridLayout from '../../../components/GridLayout';
import Questions from '../../../components/Questions';
import imageProcessor from '../../../helpers/fileUtils';
import Alert from '../../../components/BasicAlert';
import BasicButtonWithSpinner from '../../../components/BasicButtonWithSpinner';
import TableGrid from '../../../components/TableGrid';
import Assortments from 'components/Assortments';

import { storage, db, Timestamp } from '../../../firebase';

import '../../../App.css';

const partnerEmailEndpoint =
  'https://us-central1-papillon-trade-app.cloudfunctions.net/sendgrid/nodemailer/order/partner';

const workerEmailEndpoint =
  'https://us-central1-papillon-trade-app.cloudfunctions.net/sendgrid/nodemailer/order/worker';

const referenceEndpoint =
  'https://us-central1-papillon-trade-app.cloudfunctions.net/sendgrid/reference';


const useStyles = makeStyles(theme => ({
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 20,
    flexBasis: 700,
    width: '100%',
    backgroundColor: theme.palette.white,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3),
    textTransform: 'uppercase'
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  input: {
    display: 'none'
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  },
  divider: {
    backgroundColor: theme.palette.divider,
    marginTop: 15
  },
  gridList: {
    padding: theme.spacing(2)
  },
  loaderContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 10,
    marginBottom: 10
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  }
}));

const AddSubmission = ({ history }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  //user info
  const {
    username,
    avatar,
    email,
    partnerInfo,
    isPapillon,
    isPikrijeka
  } = useSelector(state => state.auth.user);
  const workerId = useSelector(state => state.auth.userId);

  //stores
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  //partners
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  //products
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  // selected product
  const [lastValue, setLastValue] = useState(1);
  //images
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [processedImagesList, setProcessedImagesList] = useState([]);
  const [, /* imageUrls */ setImageUrls] = useState([]);
  //questions
  const [questions, setQuestions] = useState([]);

  // assortments
  const [assortments, setAssortments] = useState([])
  // const [assortmentAnswers, setAssortmentAnswers] = useState([])

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  //react hook form config
  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onBlur',
    validationSchema: validationSchemas.submissionSchema({
      stores,
      partners,
      products
    })
  });

  // wether user can submit the form
  const allAssortmentsAnswered = assortments.reduce((acc, asrt) => Boolean(asrt.answer) && acc, true)
  const canSubmit = !loading && formState.isValid && allAssortmentsAnswered

  useEffect(() => {
    (async () => {
      let fetchedStores;

      if (isPapillon || isPikrijeka) {
        const partnersIds = partnerInfo.map(el => el.id);

        const fetchedPartners = await (
          await db.collection('partners').get()
        ).docs
          .filter(obj => partnersIds.includes(obj.id))
          .map(partner => ({
            id: partner.id,
            label: partner.data().name,
            email: partner.data().email,
            isPikrijeka: partner.data().isPikrijeka,
            otherEmails: partner.data().otherEmails
          }));

        setPartners(fetchedPartners);
      } else {
        setSelectedPartner({ ...partnerInfo, label: partnerInfo.name });
        fetchedStores = await (
          await db
            .collection('horecasts')
            .where('partnerInfo.id', '==', partnerInfo.id)
            .get()
        ).docs;
        fetchedStores = fetchedStores.map(store => ({
          code: store.data().number,
          country: store.data().country,
          value: store.data().reference,
          label: store.data().name,
          address: store.data().address,
          classification: store.data().classification,
          type: store.data().type,
          
          oib: store.data().oib || '',
          number: store.data().number || '',
          gln: store.data().gln || '',
          glnKupca: store.data().glnKupca || '',

          id: store.id,

        }));
        setStores(fetchedStores);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedPartner !== null) {
        //fetch products for selected partner
        const fetchedProducts = await (
          await db
            .collection('products')
            .where('partnerInfo.id', '==', selectedPartner.id)
            .get()
        ).docs.map(product => ({
          label: product.data().name,
          productId: product.data().productId,
          barcode: product.data().barcode,
          package: product.data().package,
          unit: product.data().unit,
          id: product.id,
          quantity: 1
        }));
        setProducts(fetchedProducts);

        //fetch stores
        const fetchedStores = await (
          await db
            .collection('horecasts')
            .where('partnerInfo.id', '==', selectedPartner.id)
            .get()
        ).docs.map(store => ({
          code: store.data().number,
          country: store.data().country,
          value: store.data().reference,
          label: store.data().name,
          address: store.data().address,
          classification: store.data().classification,
          type: store.data().type,

          oib: store.data().oib || '',
          number: store.data().number || '',
          gln: store.data().gln || '',
          glnKupca: store.data().glnKupca || '',

          id: store.id
        }));
        setStores(fetchedStores);
      }
    })();
  }, [selectedPartner]);

  useEffect(() => {
    (async () => {
      if (selectedStore !== null) {
        //fetch questions for selected partner depending on store classification
        const fetchedQuestions = await (
          await db
            .collection('questions')
            .where('partnerInfo.id', '==', selectedPartner.id)
            .where('classification', '==', selectedStore.classification)
            .get()
        ).docs.map(question => ({
          label: question.data().question,
          id: question.id,
          checked: false
        }));
        setQuestions(fetchedQuestions);
      }
    })();
  }, [selectedStore]);

  useEffect(() => {
    (async () => {
      if (selectedStore !== null) {
        //fetch assortment for selected partner depending on store classification
        const fetchedAssortments = await (
          await db
            .collection('assortments')
            .where('partnerInfo.id', '==', selectedPartner.id)
            .where('classification', '==', selectedStore.classification)
            .get()
        ).docs.map(assort => ({
          label: assort.data().assortment,
          id: assort.id,
          options: assort.data().options,
          answer: ''
        }));

        setAssortments(fetchedAssortments);
      }
    })();
  }, [selectedStore]);

  const putStorageItem = async (item, index) => {
    try {
      const snapshot = await storage
        .ref('submissions')
        .child(`submission_${index}_${new Date().getTime()}.jpeg`)
        .put(item);
      const imageUrl = await snapshot.ref.getDownloadURL();
      return imageUrl;
    } catch (error) {
      console.log('failed to upload 1 file', error);
    }
  };

  const productSelectedHandler = (e, selectedProductsByHandler, reason) => {
    const formatSelectedProducts = [...selectedProducts];
    if (reason === 'select-option') {
      const product =
        selectedProductsByHandler[selectedProductsByHandler.length - 1];

      formatSelectedProducts.push({
        ...product,
        name: product.label,
        quantity: product.quantity,
        edit: false
      });
      setSelectedProducts(formatSelectedProducts);
    } else if (reason === 'remove-option') {
      const filteredProdcuts = selectedProductsByHandler.map((p) => {
        return formatSelectedProducts.find(
          o => o['productId'] === p['productId']
        );
      });
      setSelectedProducts(filteredProdcuts);
    } else if (reason === 'clear') {
      setSelectedProducts([]);
    }
  };

  const storeSelectedHandler = (e, value) => {
    setSelectedStore(value);
  };
  const partnerSelectedHandler = (e, value) => {
    setSelectedPartner(value);
  };

  const handleFileChange = async files => {
    setLoading(true);
    const processedImages = await imageProcessor(files);
    setProcessedImagesList(processedImages);
    setSelectedFiles(
      processedImages.map(imageBlob => URL.createObjectURL(imageBlob))
    );
    setLoading(false);
  };

  const questionCheckedHandler = (value, question) => {
    const toUpdateIndex = questions.findIndex(q => q.id === question.id);
    const updatedQuestion = { ...questions[toUpdateIndex], checked: value };
    const updatedQuestions = [
      ...questions.slice(0, toUpdateIndex),
      updatedQuestion,
      ...questions.slice(toUpdateIndex + 1)
    ];
    setQuestions(updatedQuestions);
  };

  const onCreateSubmission = async data => {
    Object.values(data).some(str => str.length < 1);
    try {
      setLoading(true);
      const uploadedImages = await Promise.all(
        processedImagesList.map((item, index) => putStorageItem(item, index))
      );
      setImageUrls(uploadedImages);
      const submissionRef = await db.collection('submissions').doc();

      //get next submission value
      const resp = await axios.get(referenceEndpoint);

      const submissionReference =
        selectedStore.classification === 'store' ? `RETAIL-${resp.data.reference}` : `HORECA-${resp.data.reference}`;
      const submissionData = {
        workerInfo: {
          username,
          avatar,
          email,
          id: workerId,
          isPapillon,
          isPikrijeka: isPikrijeka || false
        },
        reference: submissionReference,
        orderNotice: data.orderNotice,
        storeInfo: {
          id: selectedStore.id,
          name: selectedStore.label,
          number: selectedStore.code,
          address: selectedStore.address,
          country: selectedStore.country,

          oib: selectedStore.oib || '',
          number: selectedStore.number || '',
          gln: selectedStore.gln || '',
          glnKupca: selectedStore.glnKupca || '',

          classification: selectedStore.classification,
          type: selectedStore.type
        },
        partnerInfo: {
          id: selectedPartner.id,
          name: selectedPartner.label,
          email: selectedPartner.email,
          isPikrijeka: selectedPartner.isPikrijeka || false
        },
        products: selectedProducts,
        images: uploadedImages,
        description: data.description,
        questions: questions,
        assortments: assortments.map(({label, answer}) => ({label, answer})),
        created: Timestamp.fromDate(new Date()),
        comments: []
      };

      //Send order email if products not empty
      if (selectedProducts.length !== 0) {
        const partnerData = await (await db.collection('partners').doc(selectedPartner.id).get()).data();

        let partnerEmailList = [selectedPartner.email];
        if (partnerData.otherEmails) {
          partnerEmailList = [...partnerEmailList, ...partnerData.otherEmails];
        }
        const lang = localStorage.getItem('i18nextLng');
        let queryLang = '';
        if (lang) {
          queryLang = `?lang=${lang}`;
        }

        // email to partner
        axios.post(`${partnerEmailEndpoint}${queryLang}`, {
          to: partnerEmailList,
          order: {
            reference: submissionReference,
            worker: username,
            orderNotice: data.orderNotice,
            partner: selectedPartner.label,
            address1: `${selectedStore.code} - ${selectedStore.label}`,
            address2: `${selectedStore.address.region} ${selectedStore.address.city} ${selectedStore.address.street}`,
            link: `https://app.papillon-trade.hr/submissions/${submissionReference}`,
            details: selectedProducts.map(product => ({
              ...product,
              name: product.label,
              code: product.productId
            }))
          }
        })

        // email to worker
        axios.post(`${workerEmailEndpoint}${queryLang}`, {
          to: email,
          order: {
            reference: submissionReference,
            worker: username,
            orderNotice: data.orderNotice,
            partner: selectedPartner.label,
            address1: `${selectedStore.code} - ${selectedStore.label}`,
            address2: `${selectedStore.address.region} ${selectedStore.address.city} ${selectedStore.address.street}`,
            link: `https://app.papillon-trade.hr/submissions/${submissionReference}`,
            details: selectedProducts.map(product => ({
              ...product,
              name: product.label,
              code: product.productId
            }))
          }
        })

      }

      setErrorMessage(null);
      await submissionRef.set(submissionData);

      setLoading(false);
      history.push('/submissions');
    } catch (error) {
      console.log('ERROR: ', error);
    }
  };

  const increment = name => {
    const newRows = selectedProducts.map(row => {
      if (row.name === name) row.quantity = row.quantity + 1;
      return row;
    });
    setSelectedProducts(newRows);
  };

  const decrement = name => {
    const newRows = selectedProducts.map(row => {
      if (row.name === name && row.quantity > 1) {
        row.quantity = row.quantity - 1;
      }
      return row;
    });
    setSelectedProducts(newRows);
  };

  const toggleEdit = name => {
    const newRows = selectedProducts.map(row => {
      if (row.name === name) {
        if (row.edit) {
          row.quantity = lastValue;
        }
        row.edit = !row.edit;
      }
      return row;
    });
    setSelectedProducts(newRows);
  };

  const setQuantity = (e, name) => {
    const value = e.target.value;
    const isValidValue = /^\d+$/.test(value) || value === '';
    const newRows = selectedProducts.map(row => {
      if (row.name === name && isValidValue) {
        if (value !== '') {
          row.quantity = parseInt(value);
          if (parseInt(value) !== 0) setLastValue(parseInt(value));
        } else {
          row.quantity = value;
        }
      }
      return row;
    });
    setSelectedProducts(newRows);
  }; 
  return (
    <GridLayout>
      <form
        autoComplete="off"
        className={classes.form}
        onSubmit={handleSubmit(onCreateSubmission)}
      >
        <Typography
          className={classes.title}
          variant="h5"
        >
          {t('addSubmission.title')}
        </Typography>

        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Divider className={classes.divider} />

        {(isPapillon || isPikrijeka) && (
          <AutocompleteInput
            className={classes.textField}
            errorMessage={errors.partner ? errors.partner.message : null}
            errors={errors.partner}
            label={t('addSubmission.cpartner')}
            margin="dense"
            name="partner"
            onOptionSelected={partnerSelectedHandler}
            register={register}
            suggestions={partners}
          />
        )}

        <AutocompleteInput
          className={classes.textField}
          errorMessage={errors.store?.message}
          errors={errors.store}
          label={t('addSubmission.cstore')}
          margin="dense"
          name="store"
          onOptionSelected={storeSelectedHandler}
          register={register}
          suggestions={stores}
        />
        <AutocompleteInput
          className={classes.textField}
          errorMessage={errors?.product?.message}
          errors={errors.product}
          filterSelectedOptions
          isMultiple
          label={t('addSubmission.cproduct')}
          margin="dense"
          name="product"
          onOptionSelected={productSelectedHandler}
          register={register}
          selectedSuggestions={selectedProducts}
          suggestions={products}
          validationKey="productId"
        />

        <TableGrid
          data={selectedProducts}
          decrement={decrement}
          increment={increment}
          setValue={setQuantity}
          toggleEdit={toggleEdit}
        />
        <TextField
          className={classes.textField}
          fullWidth
          inputRef={register}
          label={t('addSubmission.orderNotice')}
          multiline
          name="orderNotice"
          rows={3}
          type="text"
          variant="outlined"
        />
        <div className={classes.textField}>
          <input
            accept="image/*"
            className={classes.input}
            id="icon-button-file"
            multiple
            onChange={e => handleFileChange(e.target.files)}
            type="file"
          />
          <label htmlFor="icon-button-file">
            <Button
              className={classes.button}
              color="primary"
              component="span"
              fullWidth
              startIcon={<PhotoCamera />}
              variant="contained"
            >
              {t('addSubmission.cimages')}
            </Button>
          </label>
        </div>
        {loading && (
          <div className={classes.loaderContainer}>
            <LinearProgress color="primary" />
          </div>
        )}
        <GridList
          cellHeight={160}
          className={classes.gridList}
          cols={4}
        >
          {selectedFiles.map(file => (
            <GridListTile
              cols={1}
              key={Math.random()}
            >
              <img
                alt="submission_attachment"
                src={file}
              />
            </GridListTile>
          ))}
        </GridList>
        <TextField
          className={classes.textField}
          fullWidth
          inputRef={register}
          label={t('addSubmission.description')}
          multiline
          name="description"
          rows={5}
          variant="outlined"
        />

        <Questions
          handleCheck={questionCheckedHandler}
          questions={questions}
        />
        {!selectedPartner && (
          <Typography
            align="left"
            variant="body2"
          >
            Select a partner to show questions
          </Typography>
        )}

        <Assortments
          assortments={assortments}
          handleSelect={setAssortments}
        />

        <div className={classes.buttonWrapper}>
          {!loading ? (
            <BasicButtonWithSpinner
              className={classes.signInButton}
              disabled={!canSubmit}
              isLoading={loading}
              label={t('button.addSubmission')}
              type="submit"
            />
          ) : (
            <CircularProgress
              className={classes.spinner}
              size={24}
            />
          )}
        </div>
      </form>
    </GridLayout>
  );
};

AddSubmission.propTypes = {
  history: PropTypes.object
};

export default withRouter(AddSubmission);
