import React, { Component } from 'react'
import BasicModal from '../../components/BasicModal'
import {Typography} from '@material-ui/core'

class ErrorCatcher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            open: false
        }
    }
    static getDerivedStateFromError(error) {
        return { hasError: true, open: true }
    }

    componentDidCatch(error, errorInfo) {
        console.log(error.toString())
        console.log(errorInfo)
    }

    render() {
        const { hasError, open } = this.state
        const { children } = this.props

        if (hasError) {
            return <BasicModal
                title="Error"
                onCloseModal={() => this.setState({open: false})}
                modalOpen={open}
                actions={[]}>
                <Typography variant="subtitle1">The website encountered an unexpected error. Please try again later.</Typography>
            </BasicModal>
        }
        return children
    }
}

export default ErrorCatcher