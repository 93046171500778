import pica from "pica/dist/pica"

const resizer = pica();


const resizeImage = image => {
    const newDimensions = calculateAspectRatioFit(
        image.naturalWidth,
        image.naturalHeight,
        1024,
        768
    );
    const offScreenCanvas = document.createElement("canvas");
    offScreenCanvas.width = newDimensions.width;
    offScreenCanvas.height = newDimensions.height;

    return resizer.resize(image, offScreenCanvas, {
        quality: 3, // this is the max (recommended)
        alpha: true, // use alpha channel
        unsharpAmount: 20,
        unsharpRadius: 0.6,
        unsharpThreshold: 2,
        transferable: true
    });
};

const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return { width: srcWidth * ratio, height: srcHeight * ratio };
};

const asyncImage = (src, ...constructorProps) => {
    return new Promise((resolve, reject) => {
        let img = new Image(...constructorProps);
        img.onload = () => resolve(img);
        img.setAttribute("crossOrigin", "");
        img.onerror = reject;
        img.src = src;
    });
};

const imageProcessor = async files => {
    const processedImages = [];
    const imagePromises = [].map.call(files, (file, index) => {
        return asyncImage(window.URL.createObjectURL(file));
    });

    const imageProcessingImages = await Promise.all(imagePromises).then(
        images => {
            return images.map((image, index) => resizeImage(image).then(c => c));
        }
    );

    return await Promise.all(imageProcessingImages).then(canvases => {

        const promiseBlobs = canvases.map((canvas, index) => {
            return resizer.toBlob(canvas, "image/jpeg");
        });

        return Promise.all(promiseBlobs).then(imageBlobs => {
            imageBlobs.forEach((imageBlob, index) => {
                processedImages.push(imageBlob);
            });
            return processedImages
        });
    });
};

export default imageProcessor