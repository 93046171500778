export const fetchSchedule = async (workerId, db, setSchedule) => {
  const fetchedSchedule = await (
    await db
      .collection('schedules')
      .where('workerId', '==', workerId)
      .orderBy('createdAt', 'desc')
      .get()
  ).docs.map(doc => ({
    id: doc.id,
    workerId: doc.data().workerId,
    createdAt: doc.data().createdAt,
    plan: doc.data().plan
  }));

  if (fetchedSchedule.length !== 0) {
    setSchedule(fetchedSchedule[0]);
  }
};

export const fetchWorkers = async (
  username,
  setLoading,
  setWorkers,
  workersRef
) => {
  const specialPartner = username === 'pikrijeka' ? 'Pikrijeka' : 'Papillon';
  try {
    setLoading(true);
    const fetchedWorkers = await (await workersRef().get()).docs.map(doc => ({
      id: doc.id,
      name: doc.data().name,
      username: doc.data().username,
      avatar: doc.data().avatar,
      partner: doc.data().partnerInfo?.name || specialPartner,
      email: doc.data().email || ''
    }));

    setWorkers(fetchedWorkers);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    console.log(err);
  }
};

export const fetchStores = async (
  userInfo,
  userId,
  setLoading,
  setStores,
  db
) => {
  const isWorker =
    userInfo.userRole === 'worker' &&
    !userInfo.isPapillon &&
    !userInfo.isPikrijeka;
  const isSpecialWorker =
    userInfo.userRole === 'worker' &&
    (userInfo.isPapillon || userInfo.isPikrijeka);
  const isAdmin = userInfo.userRole === 'admin';
  const isPartner = userInfo.userRole === 'partner';

  try {
    setLoading(true);
    let fetchedStores;
    if (isWorker) {
      fetchedStores = await (
        await db
          .collection('horecasts')
          .where('partnerInfo.id', '==', userInfo.partnerInfo.id)
          .orderBy('name', 'asc')
          .get()
      ).docs;
    }
    if (isSpecialWorker) {
      const partnerIds = userInfo.partnerInfo.map(part => part.id);

      fetchedStores = await (
        await db
          .collection('horecasts')
          .orderBy('name', 'asc')
          .get()
      ).docs.filter(doc => {
        const partnerId = doc.data().partnerInfo.id;
        return partnerIds.includes(partnerId);
      });
    } else if (isPartner) {
      fetchedStores = await (
        await db
          .collection('horecasts')
          .where('partnerInfo.id', '==', userId)
          .orderBy('name', 'asc')
          .get()
      ).docs;
    } else if (isAdmin) {
      fetchedStores = await (
        await db
          .collection('horecasts')
          .orderBy('name', 'asc')
          .get()
      ).docs;
    }

    if (isWorker || isPartner) {
      fetchedStores = fetchedStores.map(doc => ({
        id: doc.id,
        number: doc.data().number,
        name: doc.data().name,
        classification: doc.data().classification,
        type: doc.data().type,
        country: doc.data().country,
        region: doc.data().address.region,
        city: doc.data().address.city,
        street: doc.data().address.street,
        oib: doc.data().oib || '',
        gln: doc.data().gln || '',
        code: doc.data().code || '',
        glnKupca: doc.data().glnKupca || ''
      }));
    } else {
      fetchedStores = fetchedStores.map(doc => ({
        id: doc.id,
        partner: doc.data().partnerInfo.name,
        partnerId: doc.data().partnerInfo.id,
        number: doc.data().number,
        name: doc.data().name,
        classification: doc.data().classification,
        type: doc.data().type,
        country: doc.data().country,
        region: doc.data().address.region,
        city: doc.data().address.city,
        street: doc.data().address.street,
        oib: doc.data().oib || '',
        gln: doc.data().gln || '',
        code: doc.data().code || '',
        glnKupca: doc.data().glnKupca || ''
      }));
    }

    setStores(fetchedStores);
    setLoading(false);
  } catch (err) {
    setLoading(false);
    console.log(err);
  }
};

export const fetchSubmissions = async (workerId, db) => {
  const fetchedSubs = await (
    await db
      .collection('submissions')
      .where('workerInfo.id', '==', workerId)
      .orderBy('created', 'desc')
      .get()
  ).docs.map(doc => ({
    id: doc.id,
    workerInfo: doc.data().workerInfo,
    storeInfo: doc.data().storeInfo,
    reference: doc.data().reference,
    created: doc.data().created.toDate()
  }));
  console.log('fetchedSubs', fetchedSubs);
  return fetchedSubs;
};
