import React from 'react';
import PropTypes from 'prop-types';
import { TextField, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEqual as LodashIsEqual } from 'lodash';
const useStyles = makeStyles(() => ({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
}));

const AutoCompleteInput = props => {
  const {
    suggestions = [],
    selectedSuggestions = [],
    label,
    name,
    errors,
    errorMessage,
    register,
    className,
    onOptionSelected,
    isMultiple,
    margin,
    loading,
    loadingText,
    icon,
    defualtValue,
    validationKey = 'formattedLabel',
    ...rest
  } = props;
  const classes = useStyles();
  let acSuggestions;
  if (name === 'store') {
    acSuggestions = suggestions.map(option => {
      const formattedLabel = `${option.code}-${option.label}-${option.address.city}-${option.address.region}-(${option.address.street}) (${option.classification})`;
      return {
        ...option,
        formattedLabel
      };
    });
  } else if (name === 'product') {
    const selectedIds = selectedSuggestions.map(el => el.id);
    if (suggestions.length === selectedSuggestions.length) {
      acSuggestions = [];
    } else {
      // acSuggestions = suggestions.filter(el => !selectedIds.includes(el.id));
      acSuggestions = [...suggestions];
      acSuggestions = acSuggestions.map(option => {
        const formattedLabel = `${option.productId}-${option.label}-${option.barcode}-${option.package}-(${option.unit})`;
        return {
          ...option,
          formattedLabel
        };
      });
    }
  } else {
    acSuggestions = suggestions.map(option => ({
      ...option,
      formattedLabel: option.label
    }));
  }

  const getLabelFromOption = (option = '') => {
    let label =  option.formattedLabel || option.label || option.name

    if(!label) label = ''

    return label.toString();
  }

  return (
    <Autocomplete
      {...rest}
      options={acSuggestions}
      multiple={isMultiple}
      onChange={onOptionSelected}
      getOptionLabel={getLabelFromOption}
      renderOption={option => option.formattedLabel}
      loading={loading}
      loadingText={loadingText}
      popupIcon={icon}
      disableCloseOnSelect={isMultiple}
      classes={{
        option: classes.option
      }}
      filterSelectedOptions={true}
      getOptionSelected={(o, v) => LodashIsEqual(o, v)}
      renderInput={params => (
        <TextField
          {...params}
          className={className}
          label={label}
          name={name}
          variant="outlined"
          margin={margin || 'normal'}
          fullWidth
          inputRef={register}
          error={!!errors}
          helperText={errors && errorMessage}
          autoComplete="new-password"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
            form: {
              autocomplete: 'new-password'
            }
          }}
        />
      )}
    />
  );
};

AutoCompleteInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default AutoCompleteInput;
