import { SET_TABLE_PAGE } from '../actions/actionTypes';

const initialState = {
  submissions: [],
  error: null,
  loading: false,
  page: {
    submissions: 0,
    returns: 0,
  }
};

export const setTablePage = (page) => {
  return {type: SET_TABLE_PAGE, payload: {page}}
}

const reducer = (state = initialState, action) => {
  const {payload} = action
  
  switch (action.type) {
    case SET_TABLE_PAGE:
      return {...state, page: {...state.page, ...payload.page}};
    default:
      return state;
  }
};

export default reducer;